import React from 'react';
import { RootState, useAppSelector } from '../../store.model';
import { getMixedCurrentEventsFromId } from '../selectors/events';
import ControlUnitAPI from '../api/ControlUnitAPI';
import { Base64 } from '../../../util/Base64';
import { CU_POLLING_INTERVAL } from '../../CUActions/api/CUActions.constants';

/**
 * Get and fetch control unit from specified device
 * @param encodedDeviceId
 * @returns [currentAlarms, loading, error]
 * 
 * @usage
´´´ts
const [currentAlarms, loading, error] = useCurrentEventsControlUnitById("UUUID_DEVICE","1003", onError)
´´´
 */
export function useCurrentEventsControlUnitById(
  deviceId: string,
  controlUnitId: string
) {
  const mixedControlUnitCurrentEvents = useAppSelector((state: RootState) =>
    getMixedCurrentEventsFromId(state, {
      encodedDeviceId: Base64.encode(deviceId),
      deviceId: deviceId,
      controlUnitId,
    })
  );
  const ControlUnitResponse =
    ControlUnitAPI.useGetCurrentEventsByCommanderIDQuery(deviceId, {
      skip: !deviceId,
      pollingInterval: CU_POLLING_INTERVAL,
    });

  return {
    ...ControlUnitResponse,
    data: mixedControlUnitCurrentEvents,
  } as const;
}
