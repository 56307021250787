import React, { useState } from 'react';
import { Alert, CircularProgress, Container, Grid } from '@mui/material';
import SummaryPanel from './Panels/SummaryPanel';
import useAllMerchandisers from '../../../redux/composableDevice/hook/useAllMerchandisers';
import { useParseErrorMessage } from '../../../../../handlingErrors';
import MerchandiseOverview from './MerchandiseOverview/MerchandiseOverview';
import AlarmsOverview from './AlarmsOverview/AlarmsOverview';
import { useParams } from 'react-router-dom';
import AppearanceSelect from '../../../theme/components/AppearanceSelect/AppearanceSelect';

function OperatorDashboard() {
  const params = useParams();
  const [gridDisplay, setGridDisplay] = useState(true);
  const { merchandisers, error, isLoading } = useAllMerchandisers({
    groupId: params.groupId,
  });
  const parseErrorMessage = useParseErrorMessage();

  return (
    <Container maxWidth={'xl'} sx={{ backgroundColor: 'background.container' }}>
      <Grid container spacing={1}>
        {error && (
          <Grid item xs={12}>
            <Alert severity="error">{parseErrorMessage(error)}</Alert>
          </Grid>
        )}
        {/* TODO: change for skeleton of components, or the components support loading, but i'm not sure at the moment */}
        {isLoading && (
          <Grid item xs={12}>
            <CircularProgress />
          </Grid>
        )}
        <Grid item xs={12} sm={9} md={10}>
          <SummaryPanel />
        </Grid>
        <Grid item xs={12} sm={2} md={2}>
          <AppearanceSelect
            gridDisplay={gridDisplay}
            onChangeAppearance={(appearance) => setGridDisplay(appearance)}
          />
        </Grid>
      </Grid>

      <Grid container>
        <MerchandiseOverview
          gridDisplay={gridDisplay}
          merchandiseItems={merchandisers}
        />
      </Grid>
      <Grid container>
        <AlarmsOverview gridDisplay={gridDisplay} />
      </Grid>
    </Container>
  );
}

export default OperatorDashboard;
