import React from 'react';
import { useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import {
  Alert,
  Avatar,
  Box,
  Grid,
  Menu,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';

import SkeletonAreaChart from '../ui-component/cards/Skeleton/SkeletonAreaChart';
import MainCard from '../ui-component/cards/MainCard';
import StackedBarChart from './StackedBarChart';
import { Device } from '../../../redux/devices/api/device.model';
import { ITimeFrame } from './util/mock/getMockDataDashboard';
import { TIMEFRAME_LIST } from './util/mock/constants';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { getPolyglot } from '../../../i18n';
import { ZipDeviceControlUnit } from '../../../redux/controlUnit/controlUnit.model';
import {
  generateStartTimeFormattedDate,
  getColumnUnitsBySelectedTimeframe,
  getUnitsBySelectedTimeframe,
  getTimestamps,
} from './util/mock/Utils';
import { useParseErrorMessage } from '../../../handlingErrors';
import { Group } from '../../../redux/groups/api/group.model';
import { getWorkingTimeMetrics } from './util/getWorkingTimeMetrics';
import ReportRealTimeAPI from '../../../redux/reports/api/ReportRealTimeAPI';
interface BarChartProps {
  commanders: Device[];
  group: Group | undefined;
  devicesControlUnitsSource: ZipDeviceControlUnit[];
}

const BarChartCard = ({
  group,
  commanders,
  devicesControlUnitsSource,
}: BarChartProps) => {
  const polyglot = getPolyglot();
  const [timeframe, setTimeframe] = useState<ITimeFrame>('week');
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const workingTimeMetrics = useMemo(
    () => getWorkingTimeMetrics(devicesControlUnitsSource, commanders),
    [commanders, devicesControlUnitsSource]
  );

  const {
    data: workingTimeDataValues,
    isLoading: isLoadingWorkingTime,
    isFetching: isFetchingWorkingTime,
    error: errorWorkingTime,
  } = ReportRealTimeAPI.useGetReportsByGroupIdQuery(
    {
      encodedGroupId: group?.id ?? '',
      spec: {
        outputFormat: 'JSON',
        startTime: generateStartTimeFormattedDate(timeframe),
        columnCount: getUnitsBySelectedTimeframe(timeframe),
        columnDuration: 1,
        columnUnit: getColumnUnitsBySelectedTimeframe(timeframe),
        metrics: workingTimeMetrics,
      },
    },
    {
      skip:
        !devicesControlUnitsSource || devicesControlUnitsSource.length === 0,
    }
  );

  const data = useMemo(() => {
    const timestamps = getTimestamps(timeframe);

    return timestamps.map((timestamp, dayIndex) => {
      const entry: Record<string, number | string> = { timestamp };

      workingTimeDataValues?.rows.forEach((workTime, index) => {
        const key = commanders[index].uuid;
        entry[key] = workTime.values[dayIndex];
      });

      return entry;
    });
  }, [workingTimeDataValues, commanders, timeframe]);

  const csvData = useMemo(() => {
    const exportData = [];
    const header = Object.keys(data[0] ?? []);
    exportData.push(header);
    data.forEach((d) => exportData.push(Object.values(d)));
    return exportData;
  }, [data]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const parseError = useParseErrorMessage();
  return (
    <>
      {!isFetchingWorkingTime && errorWorkingTime && (
        <Alert severity="error">{parseError(errorWorkingTime)}</Alert>
      )}
      {isFetchingWorkingTime ? (
        <SkeletonAreaChart />
      ) : (
        <MainCard>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <Typography variant="h2">
                        {polyglot.t(
                          'composable_device.dashboard_oven.working_time'
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    justifyContent="space-between"
                    spacing={1}
                    alignItems="center"
                  >
                    <Grid item>
                      <TextField
                        id="standard-select-timeframe-working-time"
                        select
                        value={timeframe}
                        onChange={(e) =>
                          setTimeframe(e.target.value as ITimeFrame)
                        }
                      >
                        {TIMEFRAME_LIST.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {polyglot.t(option.label)}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item>
                      <Avatar
                        variant="rounded"
                        sx={{
                          backgroundColor: theme.palette.primary.main,
                          color: theme.palette.background.default,
                          zIndex: 1,
                        }}
                        aria-controls="menu-working-time-card"
                        aria-haspopup="true"
                        onClick={handleClick}
                      >
                        <MoreHorizIcon fontSize="inherit" />
                      </Avatar>
                      <Menu
                        id="menu-working-time-card"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        variant="selectedMenu"
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      >
                        <MenuItem onClick={handleClose}>
                          <CSVLink
                            data={csvData}
                            filename={'working-time-export.csv'}
                            target="_blank"
                          >
                            <Box display="flex" alignItems="center">
                              <FileDownloadIcon sx={{ mr: 1 }} />
                              {polyglot.t(
                                'composable_device.dashboard_oven.actions.export'
                              )}
                            </Box>
                          </CSVLink>
                        </MenuItem>
                      </Menu>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <StackedBarChart
                commanders={commanders}
                timeFrame={timeframe}
                data={data}
              />
            </Grid>
          </Grid>
        </MainCard>
      )}
    </>
  );
};

export default BarChartCard;
