import { getDashboardFilters } from '../selectors';
import {
  getAllChildrenGroups,
  getAllGroups,
} from '../../../../../redux/devices/selectors';
import {
  RegionDetails,
  SCC_TYPE,
  createRegionDetails,
  filterBySCCType,
  getAlarmsInRegion,
  sortRegionDetails,
} from '../../composableDevice/model/SCCGroup.model';
import useAllMerchandisers from '../../composableDevice/hook/useAllMerchandisers';
import IncidentAPI from '../../../../../redux/incident/api/IncidentAPI';
import { getMyWorkspaceSelector } from '../../../../../redux/stoerkID/selectors/StoerkId.selectors';
import {
  AlarmState,
  IncidentAPIResponseMVP,
} from '../../../../../redux/incident/api/Incident.model';
import { Group } from '../../../../../redux/groups/api/group.model';
import { Merchandiser } from '../../composableDevice/model/Merchandiser.model';
import { useGroups } from '../../../../../redux/groups/hooks/useGroups';
import { useAppSelector } from '../../../../../redux/store.model';
import { useMemo } from 'react';

export const useDashboardFilters = () => {
  return useAppSelector(getDashboardFilters);
};

export const useDashboardInformation = () => {
  useGroups();

  const { merchandisers, isLoading } = useAllMerchandisers();

  const groups = useAppSelector((state) => getAllGroups(state));
  const regions = groups.filter(filterBySCCType(SCC_TYPE.REGION));
  const stores = groups.filter(filterBySCCType(SCC_TYPE.STORE));

  return { regions, stores, merchandisers, isLoading };
};

export const useFilteredGroups = (
  regions: Group[],
  stores: Group[],
  merchandisers: Merchandiser[]
) => {
  const filters = useDashboardFilters();
  const filteredRegions =
    filters.regions.length > 0 ? filters.regions : regions;

  const allStoresFromRegions = getAllChildrenGroups(filteredRegions, []).filter(
    filterBySCCType(SCC_TYPE.STORE)
  );

  const filteredStores =
    filters.stores.length > 0 ? filters.stores : allStoresFromRegions;

  const storesToProcess = filteredStores.length > 0 ? filteredStores : stores;

  const filteredMerchandisers = getMerchandisersOfStores(
    storesToProcess,
    merchandisers
  );

  return {
    regions: filteredRegions,
    stores: filteredStores,
    merchandisers: filteredMerchandisers,
    timePeriod: filters.timePeriod,
  };
};

export const getMerchandisersOfStores = (
  stores: Group[],
  merchandisers: Merchandiser[]
) => {
  const childStores = getAllChildrenGroups(
    stores.map((store: Group) => store.childGroups).flat(),
    []
  );

  const selectedMerchandisers =
    merchandisers.length > 0 ? merchandisers : merchandisers;

  const filteredMerchandisers = selectedMerchandisers.filter(
    (merchandiser: Merchandiser) =>
      childStores.find((store) => store.id === merchandiser.id)
  );

  return filteredMerchandisers;
};

export const useAlarmsByRegion = () => {
  const { regions, merchandisers, stores } = useDashboardInformation();

  const workspace = useAppSelector(getMyWorkspaceSelector);
  const filterState = useFilteredGroups(regions, stores, merchandisers);

  const {
    data: { data: incidentsData = [], count: alarmsCount } = {
      data: [],
      count: 0,
    },
    error: errorIncidents,
    isLoading: isLoadingIncidents,
    isFetching: isFetchingIncidents,
  } = IncidentAPI.useQueryMyIncidentByWorkspaceIdQuery(
    {
      workspaceId: workspace?.id,
      params: {
        state: AlarmState.NEW,
        page_size: 100,
        offset: 0,
        created_after: filterState.timePeriod.initial,
        created_before: filterState.timePeriod.end,
      },
    },
    {
      skip: !workspace?.id,
    }
  );

  const totalAlarms = incidentsData.filter((incident) =>
    filterState.merchandisers.find(
      (merch: Merchandiser) => merch.deviceId === btoa(incident.deviceId)
    )
  );
  const alarmsByRegion = new Map(
    regions.map((region) => [region.id, getAlarmsInRegion(totalAlarms, region)])
  );

  return {
    alarmsByRegion,
    isLoadingIncidents,
    isFetchingIncidents,
    errorIncidents,
    totalAlarms,
  };
};

export const useCitiesByRegion = (
  region: Group,
  stores: Group[],
  merchandisers: Merchandiser[],
  alarms: IncidentAPIResponseMVP[]
) => {
  const result = useMemo(() => {
    const regionChildren = getAllChildrenGroups([region], []);
    const cities = regionChildren.filter(filterBySCCType(SCC_TYPE.CITY));
    const cityDetailsMap = new Map<string, RegionDetails>();

    cities.forEach((city) => {
      const regionDetails = createRegionDetails(
        city,
        stores,
        merchandisers,
        alarms
      );

      cityDetailsMap.set(regionDetails.city.id, regionDetails);
    });

    const regionDetails = regionDetailsSorted(cityDetailsMap);

    return { regionDetails };
  }, [region, stores, merchandisers, alarms]);

  return result;
};

const regionDetailsSorted = (cityDetailsMap: Map<string, RegionDetails>) => {
  return new Map(
    [...cityDetailsMap.values()]
      .sort(sortRegionDetails)
      .map((region) => [region.city.id, region])
  );
};
