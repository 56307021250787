import React from 'react';
import LinksFooter, {
  LinksFooterProps,
} from '../../../../../../components/App/footer/LinksFooter/LinksFooter';
import logoFoursFringand from '../../../../assets/fours-fringand-fr__logo-fringand.svg';
import { useMediaQuery, useTheme } from '@mui/material';

function LinksFooterFF(props: LinksFooterProps) {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <LinksFooter
      logoFooter={logoFoursFringand}
      logoHeight={isTablet ? 20 : 30}
      urlAboutUs="https://www.foursfringand.com/"
    />
  );
}

export default LinksFooterFF;
