import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import GroupModel from '../../../../redux/groups/api/GroupModel';
import { STModal } from '../../../commons/Modal';
import { getNumDevices } from '../../../../redux/devices/selectors';
import { getGroups } from '../../../../redux/groups/selectors';
import { withPolyglot } from '../../../../i18n';
import { deleteGroupDevice } from '../../../../redux/groups/actions/thunks';
import { withRouter } from '../../../../util/route-dom';
import { withShowMessage } from '../../../HOC/withShowMessage';
import { withShowErrorMessage } from '../../../HOC';

/**
 * Remove group device
 * this function remove a device from the group
 */
export class RemoveGroupDevice extends Component {
  constructor(props) {
    super(props);
    this.removeGroupDevice = this.removeGroupDevice.bind(this);
  }

  /**
   * Remove group device
   * this funcion call the backend to remove a device from a group
   */
  async removeGroupDevice() {
    const {
      groupId,
      deviceId,
      polyglot,
      showMessage,
      showErrorMessage,
      navigate,
      closeRemoveGroupDevice,
    } = this.props;
    try {
      await this.props.deleteGroupDevice(groupId, deviceId);
      showMessage(polyglot.t('device.remove_successful_message'));
      closeRemoveGroupDevice();
      navigate(`/devicemanager/group/${groupId}`, { replace: true });
    } catch (error) {
      showErrorMessage(error);
    }
  }

  render() {
    const {
      showRemoveGroupDevice,
      polyglot,
      closeRemoveGroupDevice,
      groups,
      groupId,
    } = this.props;
    const group = GroupModel.getGroupByGroupId(groupId, groups);
    const dialogText = polyglot
      .t('device.remove_group_device_dialog_text')
      .replace('#text', group ? `"${group.name}"` : '');
    return (
      <STModal
        open={showRemoveGroupDevice}
        onClose={closeRemoveGroupDevice}
        title={polyglot.t('device.remove_dialog_title')}
        buttonActions={
          <>
            <Button onClick={closeRemoveGroupDevice} variant="text">
              {polyglot.t('device.cancel_button_title')}
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={this.removeGroupDevice}
            >
              {polyglot.t('device.remove_button_title')}
            </Button>
          </>
        }
      >
        <p>{dialogText}</p>
      </STModal>
    );
  }
}

RemoveGroupDevice.propTypes = {
  showErrorMessage: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  polyglot: PropTypes.shape({
    t: PropTypes.func,
    locale: PropTypes.func,
  }).isRequired,
  deviceId: PropTypes.string,
  showRemoveGroupDevice: PropTypes.bool.isRequired,
  closeRemoveGroupDevice: PropTypes.func.isRequired,
  groupId: PropTypes.string,
  groups: PropTypes.arrayOf(PropTypes.object).isRequired,
  deleteGroupDevice: PropTypes.func.isRequired,
};

RemoveGroupDevice.defaultProps = {
  deviceId: '',
  groupId: null,
};

const mapStateToProps = (state) => ({
  totalDevices: getNumDevices(state),
  groups: getGroups(state),
});

export default connect(mapStateToProps, { deleteGroupDevice })(
  withRouter(
    withShowMessage(withShowErrorMessage(withPolyglot(RemoveGroupDevice)))
  )
);
