import React, { useMemo } from 'react';
import {
  Container,
  Box,
  ContainerProps,
  BoxProps,
  styled,
  Paper,
} from '@mui/material';
import { Outlet, useParams } from 'react-router-dom';
import { useShowErrorMessage } from '../../../../handlingErrors';
import { getModuleSelected } from './useNavigationPathMerchandiser';
import { useGroupById } from '../../../../redux/groups/hooks/useGroupById';
import { useAppSelector } from '../../../../redux/store.model';
import { getMerchandiserByMerchandiserIdPropSelector } from '../../redux/composableDevice/selectors/composableDeviceSelectors';
import MerchandiserSubheader from './MerchandiserSubheader';

const MainContainer = (props: { moduleSelected: string } & BoxProps) => {
  const { children, ...others } = props;

  return (
    <Container
      maxWidth="xl"
      disableGutters
      sx={{
        height: '100%',
        backgroundColor: 'background.container',
      }}
    >
      {children}
    </Container>
  );
};

const ChildContainer = (props: { moduleSelected: string } & ContainerProps) => {
  const { moduleSelected, children, ...others } = props;

  return (
    <Container
      disableGutters
      sx={{
        py: 1,
        px: { xs: 1, md: 4 },
        backgroundColor: 'background.container',
      }}
      {...others}
      maxWidth="xl"
    >
      {children}
    </Container>
  );
};

/** Paper with more round */
export const ContentPage = styled(Paper)`
  border-radius: 1rem;
  padding: 0.4rem;
`;
export function MerchandiserLayout() {
  const { merchandiserId } = useParams<{ merchandiserId: string }>();
  const showErrorMessage = useShowErrorMessage();
  const [group, loading] = useGroupById(merchandiserId, showErrorMessage);
  const merchandiser = useAppSelector((state) =>
    getMerchandiserByMerchandiserIdPropSelector(state, {
      merchandiserId,
    })
  );
  const { pathname } = window.location;

  /* Navigation */
  const moduleSelected = useMemo(() => {
    return getModuleSelected(pathname);
  }, [pathname]);

  return (
    <MainContainer moduleSelected={moduleSelected}>
      {merchandiserId && (
        <MerchandiserSubheader
          merchandiserId={merchandiserId}
          merchandiser={merchandiser}
          loading={loading}
          moduleSelected={moduleSelected}
        />
      )}
      <ChildContainer moduleSelected={moduleSelected}>
        <Outlet />
      </ChildContainer>
    </MainContainer>
  );
}

export default MerchandiserLayout;
