import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../../../../../axios/axiosBaseQuery';
import { Report } from '../report.model';
import { Group } from '../../../../../redux/groups/api/group.model';
import { Merchandiser } from '../../composableDevice/model/Merchandiser.model';

type ReportsResponse = Report[];

interface DownloadReportsArgs {
  reports: string[];
  formats: string[];
}

export interface ReportCreateForm {
  stores: Group[];
  initialPeriod: string;
  endPeriod: string;
  merchandisers: Merchandiser[];
  topics: string[];
  formats: string[];
}
// TODO Update the endpoint to match the actual endpoint
export const ReportsGenerationAPI = createApi({
  reducerPath: 'reports_generation',
  tagTypes: ['add-report'],
  baseQuery: axiosBaseQuery({ baseUrl: '/api/' }),
  endpoints: (build) => ({
    getReports: build.query<ReportsResponse, string>({
      query: (cityId) => ({
        url: `reports/${cityId}`,
        invalidatesTags: ['add-report'],
      }),
    }),
    downloadReports: build.query<void, DownloadReportsArgs>({
      query: (args: DownloadReportsArgs) => ({
        url: `reports/download`,
        method: 'POST',
        args,
      }),
    }),
    generateReport: build.query<void, ReportCreateForm>({
      query: (args: ReportCreateForm) => ({
        url: `reports/generate`,
        method: 'POST',
        args,
      }),
    }),
  }),
});

export const {
  useGetReportsQuery,
  useLazyDownloadReportsQuery,
  useLazyGenerateReportQuery,
} = ReportsGenerationAPI;
