import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  AccordionLight,
  AccordionSummaryLight,
  VIEW_TYPE,
} from '../../../theme/components';
import { Group } from '../../../redux/groups/api/group.model';
import { Device } from '../../../redux/devices/api/device.model';
import { getPolyglot } from '../../../i18n';
import TooltipLight from '../../commons/TooltipLight';
import { InfoIcon } from '../../../theme/icons';
import Devices from '../Device';
import GroupsOverview from '../Group/Overview';

interface ListEntitiesProps {
  groupsResults: Group[];
  devicesResults: Device[];
  view: VIEW_TYPE;
  groupId: string;
  group?: Group;
}

function ListEntities(props: ListEntitiesProps) {
  const { groupsResults, devicesResults, view, groupId, group } = props;
  const polyglot = getPolyglot();

  return (
    <div>
      {groupsResults.length > 0 ? (
        <AccordionLight defaultExpanded={true}>
          <AccordionSummaryLight
            id="accordion-groups-title"
            aria-controls="accordion-groups-title"
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography variant="h5">{polyglot.t('group.groups')}</Typography>
          </AccordionSummaryLight>
          <div>
            <GroupsOverview groups={groupsResults} view={view} />
          </div>
        </AccordionLight>
      ) : (
        <GroupsOverview groups={groupsResults} view={view} />
      )}
      {devicesResults.length > 0 && groupsResults.length ? <Divider /> : null}
      {devicesResults.length > 0 && (
        <AccordionLight defaultExpanded={true}>
          <AccordionSummaryLight
            id="accordion-devices-title"
            aria-controls="accordion-devices-title"
            expandIcon={<ExpandMoreIcon />}
          >
            <TooltipLight
              title={
                // show only in root group
                !group && (
                  <Typography variant="body2">
                    {polyglot.t('group.tooltip.device_explain_tootltip')}
                  </Typography>
                )
              }
            >
              <Typography variant="h5" display="flex">
                {polyglot.t('group.devices.devices')}
                {!group ? (
                  <Box
                    ml={0.5}
                    display="flex"
                    alignItems="center"
                    component={'span'}
                  >
                    <InfoIcon />
                  </Box>
                ) : null}
              </Typography>
            </TooltipLight>
          </AccordionSummaryLight>
          <div>
            <Devices devices={devicesResults} view={view} groupId={groupId} />
          </div>
        </AccordionLight>
      )}
    </div>
  );
}

export default ListEntities;
