import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
} from '@mui/material';
import { Merchandiser } from '../../../redux/composableDevice/model/Merchandiser.model';
import { useAppSelector } from '../../../../../redux/store.model';
import { getAlarmActiveBySelector } from '../../../../../redux/incident/selectors/getAlarmActiveBySelector';
import { useNavigate } from 'react-router-dom';
import MerchandiseShelfConnected from '../../../components/Dashboard/Operator/MerchandiseOverview/MerchandiseShelfConnected';
import { getPolyglot } from '../../../../../i18n';
import { StyledHeaderRow, StyledRoundedRow } from '../StyledTable/StyledTable';

const MerchandiseTable = (props: { data: Merchandiser[] }) => {
  const { data } = props;
  const navigate = useNavigate();
  const polyglot = getPolyglot();

  const onClick = ({ id }: Merchandiser) => {
    navigate(`merchandiser/${id}`);
  };
  if (!data.length) {
    return (
      <Typography>
        {polyglot.t('device.search.message_no_results_found')}
      </Typography>
    );
  }
  return (
    <TableContainer
      component={Paper}
      sx={{
        background: 'transparent',
        border: 'none',
      }}
    >
      <Table
        sx={{
          minWidth: 650,
          borderCollapse: 'separate',
          borderSpacing: '0 0.3rem',
          padding: '0.5rem',
        }}
        aria-label="Merchandise items"
      >
        <TableHead sx={{ bgcolor: 'neutral.dark' }}>
          <StyledHeaderRow>
            <TableCell sx={{ width: '20%', fontWeight: 'bolder' }}>
              {polyglot.t('freshwatch.dashboard.detail.product_name')}
            </TableCell>
            <TableCell sx={{ width: '10%', fontWeight: 'bolder' }}>
              {polyglot.t('freshwatch.dashboard.detail.product_type')}
            </TableCell>
            <TableCell sx={{ width: '20%', fontWeight: 'bolder' }}>
              {polyglot.t('freshwatch.dashboard.detail.temperature_type')}
            </TableCell>
            <TableCell sx={{ width: '40%', fontWeight: 'bolder' }}>
              {polyglot.t('freshwatch.dashboard.detail.current_temperature')}
            </TableCell>
            <TableCell sx={{ width: '10%', fontWeight: 'bolder' }}>
              {polyglot.t('freshwatch.dashboard.alarms')}
            </TableCell>
          </StyledHeaderRow>
        </TableHead>
        <TableBody>
          {data.map((merchandiser: Merchandiser, i) => {
            const hasRefrigerated = !!merchandiser.refrigeratedShelfs.length;
            const hasHeated = !!merchandiser.heatedShelfs.length;
            const { data: alarmsActive, isLoading: isLoadingAlarmsActive } =
              useAppSelector((state) =>
                getAlarmActiveBySelector(state, {
                  groupId: merchandiser.id,
                })
              );

            // TODO: Implement the logic for alarmsHeated and alarmsRefrigerated
            const alarmsHeated = alarmsActive.length > 0;
            const alarmsRefrigerated = alarmsActive.length > 0;

            return (
              <StyledRoundedRow
                key={`${merchandiser.id}__${i}`}
                sx={{ boxShadow: 2 }}
                error={alarmsHeated || alarmsRefrigerated}
                // TODO: Change to component Link when updating to mui/material v16
                onClick={() => onClick(merchandiser)}
                hover
                style={{ cursor: 'pointer' }}
              >
                <TableCell
                  sx={{
                    bgcolor: 'primary.main',
                    color: 'primary.contrastText',
                    fontWeight: 'bold',
                  }}
                >
                  {merchandiser.name}
                </TableCell>
                <TableCell sx={{ fontWeight: 'normal' }}>
                  {merchandiser.machineType?.labels?.businessFamily}
                </TableCell>
                <TableCell
                  sx={{
                    color: alarmsActive.length > 0 ? 'error' : 'common.black',
                    fontWeight: 'normal',
                  }}
                >
                  {hasRefrigerated
                    ? polyglot.t('freshwatch.dashboard.refrigerated')
                    : null}
                  {hasRefrigerated && hasHeated ? `${' / '}` : ''}
                  {hasHeated ? polyglot.t('freshwatch.dashboard.heated') : null}
                </TableCell>
                <TableCell
                  sx={{
                    color: alarmsActive.length > 0 ? 'error' : 'common.black',
                    fontWeight: 'normal',
                  }}
                >
                  {hasRefrigerated
                    ? merchandiser.refrigeratedShelfs.map((shelf) => (
                        <MerchandiseShelfConnected
                          commanderId={shelf.commanderId}
                          controlUnitId={shelf.controlUnitId}
                          variant="refrigerated"
                          key={`refrigerated-${shelf.commanderId}`}
                          type="text"
                        />
                      ))
                    : null}
                  {hasRefrigerated && hasHeated ? ' / ' : null}
                  {merchandiser.heatedShelfs.length
                    ? merchandiser.heatedShelfs
                        .map<React.ReactNode>((shelf) => (
                          <MerchandiseShelfConnected
                            commanderId={shelf.commanderId}
                            controlUnitId={shelf.controlUnitId}
                            variant="heated"
                            key={`heated-${shelf.commanderId}`}
                            type="text"
                          />
                        ))
                        .reduce((prev, curr) => [prev, ', ', curr])
                    : null}
                </TableCell>
                {/* TODO: Change for heatedAlarms + refrigeratedAlarms */}
                <TableCell>{alarmsActive.length}</TableCell>
              </StyledRoundedRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MerchandiseTable;
