import { createSelector } from '@reduxjs/toolkit';
import { getGroups } from '../../../../../redux/devices/selectors';
import GroupModel from '../../../../../redux/groups/api/GroupModel';
import { getGroupIdProps } from '../../../../../redux/groups/selectors';
import { Group } from '../../../../../redux/groups/api/group.model';
import { ComposableDeviceUserInterfaces } from '../../../../../redux/composableDevice/ComposableDevice.model';
import { Merchandiser } from '../model/Merchandiser.model';
import _ from 'lodash';
import { getMachinesTypeSelector } from '../../../../../redux/composableDevice/selectors/composableDeviceSelectors';

/**
 * List of supported composable device types
 *
 * Those ids comes from the real ids that Structural Concepts uses for their devices
 * So are areal family/group of devices that we support, at the momento Oasis merchandisers
 */
const listTypeSupported = [ComposableDeviceUserInterfaces.SCC_MERCHANDISE];

const getRefrigeratedShelfs = (group: Group) => {
  const composableDeviceData = group?.composableDevice;
  if (composableDeviceData?.labels.cus_refrigerated?.trim() === '') {
    return [];
  }

  return (
    (composableDeviceData?.labels.cus_refrigerated?.split(',') || []).map(
      (shelf: string) => {
        const [commanderId, controlUnitId] = shelf.split('/');
        return {
          commanderId,
          controlUnitId,
        };
      }
    ) ?? []
  );
};

const getHeatedShelfs = (group: Group) => {
  const composableDeviceData = group?.composableDevice;
  if (composableDeviceData?.labels.cus_heated?.trim() === '') {
    return [];
  }

  return (
    (composableDeviceData?.labels?.cus_heated?.split(',') || []).map(
      (shelf: string) => {
        const [commanderId, controlUnitId] = shelf.split('/');
        return {
          commanderId,
          controlUnitId,
        };
      }
    ) ?? []
  );
};

const getGroupTreeSelected = createSelector(
  [getGroupIdProps, getGroups],
  (_groupId, groups) => {
    if (!_groupId) {
      return groups;
    }

    return GroupModel.getGroupsByPredicate(
      (group) => group?.id === _groupId,
      groups
    );
  }
);

export const getMachinesTypeIdSupportedSelector = createSelector(
  [getMachinesTypeSelector],
  (machinesType) => {
    return machinesType
      .filter((machineType) =>
        listTypeSupported.includes(
          machineType.userInterface as ComposableDeviceUserInterfaces
        )
      )
      .map((machineType) => machineType.id);
  }
);

export const getAllComposableDeviceByPropGroupIdSelector = createSelector(
  [
    getGroupIdProps,
    getGroupTreeSelected,
    getMachinesTypeIdSupportedSelector,
    getMachinesTypeSelector,
  ],
  (_groupId, groups, machineTypeIdSupported, machinesType) => {
    return GroupModel.getGroupsByPredicate((group: Group | undefined) => {
      return (
        group?.composableDevice &&
        machineTypeIdSupported.includes(group?.composableDevice?.machineTypeId)
      );
    }, groups).map((group) => {
      const machineType = machinesType.find(
        (machineType) =>
          machineType.id === group?.composableDevice?.machineTypeId
      );
      const refrigeratedShelfs = getRefrigeratedShelfs(group);
      const heatedShelfs = getHeatedShelfs(group);
      return {
        ...group,
        // Merchandiser only with one device
        deviceId: _.first(group.devices),
        machineType,
        refrigeratedShelfs,
        heatedShelfs,
      } as Merchandiser;
    });
  }
);

export const getMerchandiserByMerchandiserIdPropSelector = createSelector(
  [
    (_state, props: { merchandiserId?: string }) => props.merchandiserId,
    getAllComposableDeviceByPropGroupIdSelector,
  ],
  (merchandiserId, merchandisers) => {
    return merchandisers.find(
      (merchandiser) => merchandiser.id === merchandiserId
    );
  }
);
