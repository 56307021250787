import { AnyAction, combineReducers } from 'redux';
import userReducer from '../users/reducer';
import roleReducer from '../roles/reducer';
import integrationRegistryReducer from '../integrationRegistry/reducer';
import eventsReducer from '../events/reducer';
import rightsReducer from '../rights/reducer';
import notificationsReducer from '../notifications/reducer';
import maintenanceMessageReducer from '../maintenanceMessage/reducer';
import addressesCoordinatesReducer from '../addressesCoordinates/reducer';
import cameraReducer from '../cameras/reducer';
import recipesReducer from '../recipes/reducer';
import authReducer from '../auth/reducer';
import billingReducer from '../billing/reducer';

import { DESTROY_SESSION } from '../auth/actions/Types';
import { nameReducerGroups, reducerGroups } from '../groups/slice/groupsSlice';
import {
  nameReducerDevices,
  reducerDevices,
} from '../devices/slice/devicesSlice';
import {
  nameReducerMonitoring,
  reducerMonitoring,
} from '../monitoring/slice/monitoringSlice';
import {
  nameReducerFeatureToggle,
  reducerFeatureToggle,
} from '../featureToggle/slice/featureToggleSlice';
import {
  nameReducerControlUnit,
  reducerControlUnit,
} from '../controlUnit/slice/controlUnitSlice';
import {
  nameReducerCUTranslation,
  reducerCUTranslation,
} from '../CUTranslation/slice/CUTranslationSlice';
import {
  nameReducerCUParameters,
  reducerCUParameters,
} from '../CUParameters/slice/CUParametersSlice';
import StoerkIdAPI from '../stoerkID/StoerkIdAPI';
import UserAPI from '../users/api/UserAPI';
import DeviceManagerPageSlice from '../../components/DeviceManager/redux/DeviceManagerPageSlice';
import { StoerkIdRestrictionsSlice } from '../stoerkID/slice';
import IncidentAPI from '../incident/api/IncidentAPI';
import NotificationAPI from '../notifications/api/NotificationAPI';
import CUActionsAPI from '../CUActions/api/CUActionsAPI';
import { StoerkIdAsyncThunkSlice } from '../stoerkID/slice/StoerkIdAsyncThunkSlice';
import ControlUnitAPI from '../controlUnit/api/ControlUnitAPI';
import { DashboardFiltersSlice } from '../../customized/fresh-watch/redux/dashboardFilters/slice/dashboardFiltersSlice';
import { ReportsGenerationAPI } from '../../customized/fresh-watch/redux/reports/api/ReportsGenerationAPI';
import ReportRealTimeAPI from '../reports/api/ReportRealTimeAPI';

const appReducer = combineReducers({
  auth: authReducer,
  [nameReducerGroups]: reducerGroups,
  [nameReducerDevices]: reducerDevices,
  [nameReducerControlUnit]: reducerControlUnit,
  [DeviceManagerPageSlice.name]: DeviceManagerPageSlice.reducer,
  users: userReducer,
  roles: roleReducer,
  events: eventsReducer,
  integrationRegistry: integrationRegistryReducer,
  rights: rightsReducer,
  notifications: notificationsReducer,
  maintenanceMessage: maintenanceMessageReducer,
  addressesCoordinates: addressesCoordinatesReducer,
  cameras: cameraReducer,
  recipes: recipesReducer,
  billing: billingReducer,
  [nameReducerMonitoring]: reducerMonitoring,
  [nameReducerFeatureToggle]: reducerFeatureToggle,
  [nameReducerCUTranslation]: reducerCUTranslation,
  [nameReducerCUParameters]: reducerCUParameters,
  [ControlUnitAPI.reducerPath]: ControlUnitAPI.reducer,
  [CUActionsAPI.reducerPath]: CUActionsAPI.reducer,
  [IncidentAPI.reducerPath]: IncidentAPI.reducer,
  [StoerkIdAPI.reducerPath]: StoerkIdAPI.reducer,
  [StoerkIdAsyncThunkSlice.name]: StoerkIdAsyncThunkSlice.reducer,
  [StoerkIdRestrictionsSlice.name]: StoerkIdRestrictionsSlice.reducer,
  [UserAPI.reducerPath]: UserAPI.reducer,
  [NotificationAPI.reducerPath]: NotificationAPI.reducer,
  [DashboardFiltersSlice.name]: DashboardFiltersSlice.reducer,
  [ReportsGenerationAPI.reducerPath]: ReportsGenerationAPI.reducer,
  [ReportRealTimeAPI.reducerPath]: ReportRealTimeAPI.reducer,
});

const rootReducer: typeof appReducer = (state: any, action: AnyAction) => {
  // Clear all data in redux store to initial.
  if (action.type === DESTROY_SESSION) {
    state = undefined;
  }

  // console.log({ state, action });

  return appReducer(state, action);
};

export default rootReducer;
