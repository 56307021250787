import React, { useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { IconUpload } from '../../redux/groups/groups.model';
import { getPolyglot } from '../../i18n';
import { useAppDispatch, useAppSelector } from '../../redux/store.model';
import useShowErrorMessage from '../../handlingErrors/useShowErrorMessage';
import { Group, GroupCreateAPI } from '../../redux/groups/api/group.model';
import * as yup from 'yup';
import { loadGroupsDevices, putGroup } from '../../redux/groups/actions/thunks';
import ComposableDeviceForm from './ComposableDeviceForm/ComposableDeviceForm';
import { Base64 } from '../../util/Base64';
import { Box, Stack, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { useShowMessage } from '../../util/hooks';
import { ComposableDeviceValues } from './ComposableDeviceAdd';

interface ComposableDeviceEditProps {
  group?: Group;
}

export default function ComposableDeviceEdit({
  group,
}: ComposableDeviceEditProps) {
  const polyglot = getPolyglot();

  const dispatch = useAppDispatch();

  const showError = useShowErrorMessage();
  const showSuccess = useShowMessage();
  const [logo, setLogo] = useState<IconUpload | null>(null);
  const [image, setImage] = useState<IconUpload | null>(null);
  const composableDevice = group?.composableDevice;

  const initialValues: ComposableDeviceValues = useMemo(() => {
    setLogo({
      fileName: '',
      toolTip: '',
      file: new File([], '', {}),
      imagePreviewUrl: group?.iconURL ?? '',
    });
    setImage({
      fileName: '',
      toolTip: '',
      file: new File([], '', {}),
      imagePreviewUrl: composableDevice?.labels.imageURL ?? '',
    });
    return {
      ...group,
      name: group?.name ?? '',
      devices: group?.devices?.map((item: string) => Base64.decode(item)) ?? [],
      logoURL: group?.iconURL ?? '',
      imageURL: composableDevice?.labels.imageURL ?? '',
      attributes: {
        ...group?.attributes,
        contacts:
          group?.attributes.contacts && group?.attributes.contacts.length > 0
            ? JSON.stringify(group?.attributes.contacts)
            : '',
      },
    };
  }, [composableDevice]);

  const validationSchema = useMemo(
    () =>
      yup.object({
        name: yup
          .string()
          .required(polyglot.t('composable_device.name_required')),
        devices: yup
          .array()
          .of(yup.string())
          .min(1, polyglot.t('composable_device.devices_required'))
          .required(polyglot.t('composable_device.devices_required')),
      }),
    [polyglot]
  );

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: async (values, helpers) => {
      const groupData: GroupCreateAPI = {
        name: values.name,
        devices: values.devices,
        composableDevice: values.composableDevice,
      };

      try {
        await dispatch(
          putGroup(
            group?.id ?? '',
            groupData,
            logo && logo?.fileName !== '' ? logo : undefined
          )
        );
        await dispatch(loadGroupsDevices());
        showSuccess(polyglot.t('composable_device.save_successful_message'));
      } catch (error: any) {
        showError(polyglot.t('composable_device.failed_to_update'));
      }
    },
  });

  return (
    <Stack spacing={2}>
      <Typography variant="h2">
        {polyglot.t('composable_device.edit_composable_device')}
      </Typography>
      <ComposableDeviceForm
        formik={formik}
        setImage={setImage}
        setLogo={setLogo}
        image={image}
        logo={logo}
      />
      <Box
        sx={{
          textAlign: 'right',
          mt: 3,
        }}
      >
        <LoadingButton
          color="primary"
          variant="contained"
          size="medium"
          type="submit"
          loading={formik.isSubmitting}
          onClick={formik.submitForm}
          startIcon={<SaveIcon />}
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {polyglot.t('button.save')}
        </LoadingButton>
      </Box>
    </Stack>
  );
}
