import React from 'react';
import {
  Accordion,
  Grid,
  IconButton,
  Typography,
  accordionClasses,
  accordionSummaryClasses,
  useTheme,
} from '@mui/material';
import Breadcrumb from '../../../../../../theme/components/Breadcrumb/Breadcrumb';
import { getPolyglot } from '../../../../../../i18n';
import { useNavigate } from 'react-router-dom';
import { ArrowDownIcon } from '../../../../theme/icons';
import { AccordionSummaryLight } from '../../../../../../theme/components';
import { ReportCityCard } from '../../../../theme/components/ReportCityCard/ReportCityCard';
import FilterPanel from '../Panels/FilterPanel';
import {
  useDashboardInformation,
  useFilteredGroups,
} from '../../../../redux/dashboardFilters/hooks/useDashboardFilters';
import { Group } from '../../../../../../redux/groups/api/group.model';

const ReportsMainPage = () => {
  const { regions, stores, merchandisers } = useDashboardInformation();

  const filterState = useFilteredGroups(regions, stores, merchandisers);

  const polyglot = getPolyglot();
  const navigate = useNavigate();
  const navigation = [
    {
      content: polyglot.t('nav.menu.dashboard'),
      onClick: () => navigate('/dashboard'),
      href: '/dashboard',
      key: 'dashboard',
    },
    {
      content: polyglot.t('reports.reports'),
      onClick: () => navigate('reports'),
      href: 'reports',
      key: 'reports',
    },
  ];
  const theme = useTheme();
  return (
    <>
      <Grid sx={{ backgroundColor: 'common.white' }}>
        <FilterPanel
          regions={regions}
          stores={stores}
          merchandisers={merchandisers}
        />
      </Grid>
      <Grid
        container
        sx={{
          backgroundColor: 'neutral.light',
          padding: '1rem 2rem',
        }}
      >
        <Breadcrumb sections={navigation} />
        <Grid item xs={12}>
          {filterState.regions.map((region: Group) => (
            <Accordion
              key={region.id}
              sx={{
                background: theme.palette.common.white,
                borderRadius: '1.5rem',
                padding: '0',
                [`&.${accordionClasses.root}`]: {
                  '&:first-of-type': {
                    borderRadius: '1.5rem',
                  },
                  '&:last-of-type': {
                    borderRadius: '1.5rem',
                  },
                },
              }}
              defaultExpanded={true}
            >
              <AccordionSummaryLight
                id="regions-title"
                aria-controls="accordion-regions-title"
                expandIcon={
                  <IconButton>
                    <ArrowDownIcon
                      color={theme.palette.common.black}
                      height={'0.8rem'}
                    />
                  </IconButton>
                }
                sx={{
                  [`&.${accordionSummaryClasses.root}`]: {
                    padding: '0',
                    justifyContent: 'flex-start',
                  },
                }}
              >
                <Grid
                  item
                  display={'flex'}
                  alignItems={'center'}
                  gap="1rem"
                  padding={'0 1rem'}
                >
                  <Typography color="common.black" fontWeight={'600'}>
                    {region.name}
                  </Typography>
                </Grid>
              </AccordionSummaryLight>
              <ReportCityCard
                region={region}
                merchandisers={filterState.merchandisers}
                stores={filterState.stores}
              />
            </Accordion>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default ReportsMainPage;
