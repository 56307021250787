import { Group } from '../../../../../redux/groups/api/group.model';
import React, { useState } from 'react';
import { Merchandiser } from '../../../redux/composableDevice/model/Merchandiser.model';
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { getPolyglot } from '../../../../../i18n';
import { DownloadIcon, EyeIcon, MercIcon } from '../../icons';
import { StyledTableRow } from '../StyledTable/StyledTable';
import {
  useCitiesByRegion,
  useAlarmsByRegion,
} from '../../../redux/dashboardFilters/hooks/useDashboardFilters';
import { useNavigate } from 'react-router-dom';
import { StyledActionButton } from '../StyledActionButton/StyledActionButton';
import NewReportModal from '../NewReportModal/NewReportModal';

export const ReportCityCard = ({
  region,
  merchandisers,
  stores,
}: {
  region: Group;
  merchandisers: Merchandiser[];
  stores: Group[];
}) => {
  const { alarmsByRegion } = useAlarmsByRegion();
  const { regionDetails } = useCitiesByRegion(
    region,
    stores,
    merchandisers,
    alarmsByRegion.get(region.id) || []
  );

  const polyglot = getPolyglot();
  const theme = useTheme();
  const navigate = useNavigate();

  const [showNewReportModal, setShowNewReportModal] = useState(false);
  const [selectedCity, setSelectedCity] = useState<Group | null>(null);

  const TableHeader = (
    <TableHead>
      <TableRow
        sx={{
          background: theme.palette.primary.main,
        }}
      >
        <TableCell sx={{ color: theme.palette.primary.contrastText }}>
          {polyglot.t('freshwatch.dashboard.city')}
        </TableCell>
        <TableCell sx={{ color: theme.palette.primary.contrastText }}>
          {polyglot.t('reports.reports')}
        </TableCell>
      </TableRow>
    </TableHead>
  );

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ margin: '1rem', width: 'calc(100% - 2rem)' }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          {TableHeader}
          <TableBody>
            {[...regionDetails.values()].map((row) => {
              const error = row.citiesDetails.some(
                (detail) => detail.alarms.length > 0
              );
              return (
                <StyledTableRow
                  error={error}
                  key={row.city.id}
                  color={error ? 'error.main' : 'common.black'}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  hover={false}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ textAlign: 'start' }}
                    width={'80%'}
                  >
                    <Box display={'flex'} alignItems={'center'}>
                      <Typography
                        color={error ? 'error.main' : 'common.black'}
                        component={'span'}
                        fontWeight={'bold'}
                        mr="1rem"
                      >
                        <MercIcon
                          fontSize="1.5rem"
                          color={error ? 'error.main' : 'common.black'}
                        />
                      </Typography>
                      <Box>
                        <Typography
                          color={error ? 'error.main' : 'common.black'}
                          component={'p'}
                          fontWeight={'bold'}
                        >
                          {row.city.name}
                        </Typography>
                        <Typography
                          color={error ? 'error.main' : 'common.black'}
                          component={'p'}
                          fontWeight={'normal'}
                        >
                          {row.citiesDetails.reduce(
                            (prev, curr) => prev + curr.merchandisers.length,
                            0
                          )}
                          <Typography
                            color={error ? 'error.main' : 'common.black'}
                            ml={'0.5rem'}
                            component={'span'}
                            fontWeight={'normal'}
                          >
                            {polyglot.t('freshwatch.dashboard.merchandisers')}
                          </Typography>
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Stack direction={'row'} spacing={1}>
                      <Tooltip title={polyglot.t('reports.go_to_city_reports')}>
                        <StyledActionButton
                          onClick={() => {
                            navigate(`detail/${row.city.id}`);
                          }}
                        >
                          <EyeIcon color={theme.palette.primary.main} />
                        </StyledActionButton>
                      </Tooltip>
                      <Tooltip
                        title={polyglot.t('reports.generate_new_report')}
                      >
                        <StyledActionButton
                          onClick={() => {
                            setSelectedCity(row.city);
                            setShowNewReportModal(true);
                          }}
                        >
                          <DownloadIcon color={theme.palette.primary.main} />
                        </StyledActionButton>
                      </Tooltip>
                    </Stack>
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <NewReportModal
        open={showNewReportModal}
        onClose={() => setShowNewReportModal(false)}
        city={selectedCity}
        stores={stores}
        merchandisers={merchandisers}
      />
    </>
  );
};
