import { TableRow, alpha, styled } from '@mui/material';

export const StyledHeaderRow = styled(TableRow)`
  & .MuiTableCell-root {
    text-align: center;
    font-weight: 600;
  }
`;
export const StyledRoundedRow = styled(TableRow)<{ error: boolean }>(
  ({ error, theme }) => `
  border-radius: 1.5rem;
  background: ${theme.palette.primary.contrastText};
  padding: 0.5rem;

  & .MuiTableCell-root {
    border: none;
    text-align: center;

    &:first-of-type {
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;
    }

    &:last-child {
      border-top-right-radius: 1rem;
      border-bottom-right-radius: 1rem;
    }

    ${
      error &&
      `
      background: ${theme.palette.error.contrastText}; 
      color: ${theme.palette.error.main};
      &:first-of-type {
        background: ${theme.palette.error.main}; 
        color: ${theme.palette.primary.contrastText};
      }
      `
    }
  }
`
);

export const StyledTableRow = styled(TableRow)<{
  error: boolean;
  hover?: boolean;
}>(
  ({ error, hover = true, theme }) => `
    background: ${
      error
        ? theme.palette.error.contrastText
        : theme.palette.primary.contrastText
    };
    padding: 0.5rem;
       ${
         hover &&
         `&:hover {
          background: ${
            error ? theme.palette.error.light : theme.palette.secondary.light
          };
          color: ${
            error ? theme.palette.error.main : theme.palette.primary.main
          };
       }`
       }
    ${
      error &&
      `
      background: ${theme.palette.error.contrastText}; 
      color: ${theme.palette.error.main};
      
      `
    }
 
    & .MuiTableCell-root {
      border: none;
      border-bottom: 2px solid ${theme.palette.neutral.main};
      text-align: center;
      &:first-of-type {
        text-align: start;
      }
    }
  `
);
