import React from 'react';
import { Group } from '../../../../../../redux/groups/api/group.model';
import { Merchandiser } from '../../../../redux/composableDevice/model/Merchandiser.model';
import { IncidentAPIResponseMVP } from '../../../../../../redux/incident/api/Incident.model';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { getPolyglot } from '../../../../../../i18n';
import StoreOverviewWrapper from '../../../../theme/components/Store/StoreOverviewWrapper/StoreOverviewWrapper';
import { MapWithDevices } from './MapRegion';
import MerchandiserOverview from '../PanelSection/MerchandiserOverview/MerchandiserOverview';
import AlarmOverview from '../PanelSection/AlarmOverview/AlarmOverview';
import { useCitiesByRegion } from '../../../../redux/dashboardFilters/hooks/useDashboardFilters';
import { getAllDeviceIds } from '../../../../redux/composableDevice/model/SCCGroup.model';

const RegionDetail = ({
  region,
  merchandisers,
  stores,
  alarms,
  gridDisplay = false,
}: {
  region: Group;
  merchandisers: Merchandiser[];
  stores: Group[];
  alarms: IncidentAPIResponseMVP[];
  gridDisplay: boolean;
}) => {
  const { regionDetails } = useCitiesByRegion(
    region,
    stores,
    merchandisers,
    alarms
  );
  const allDevicesIds = getAllDeviceIds(regionDetails);
  const polyglot = getPolyglot();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const noCities = (
    <Typography>
      {polyglot.t('device.search.message_no_results_found')}
    </Typography>
  );

  if (!regionDetails.size) {
    return noCities;
  }
  let gridTemplateAreas = '';

  if (gridDisplay || isMobile) {
    gridTemplateAreas = `"location location"
    "map map"
    "merchandisers merchandisers"
    "alarms alarms"`;
  } else {
    gridTemplateAreas = `"location merchandisers"
    "map merchandisers"
    "alarms merchandisers"`;
  }

  return (
    <Box
      display={'grid'}
      gridTemplateAreas={gridTemplateAreas}
      gridTemplateColumns={'repeat(2, minmax(50%, auto))'}
      paddingBottom={'2rem'}
    >
      {/* List of Cities with the number of merchandisers */}
      <Box gridArea={'location'}>
        <StoreOverviewWrapper
          gridDisplay={gridDisplay}
          storeWithMerchandisers={regionDetails}
        />
      </Box>
      {/* Map Overview with the merchandisers in the city */}
      <Box
        gridArea={'map'}
        sx={{ height: '30vh', overflow: 'hidden', margin: '1rem 0' }}
      >
        {<MapWithDevices key={`map_${region.id}`} devicesIds={allDevicesIds} />}
      </Box>
      {/* Merchandisers Overview */}
      <Box gridArea={'merchandisers'}>
        <MerchandiserOverview
          displayGrid={gridDisplay}
          cityInformation={regionDetails}
        />
      </Box>
      {/* Alarms */}
      <Box gridArea={'alarms'}>
        <AlarmOverview
          gridDisplay={gridDisplay}
          alarmsActive={alarms}
          cities={regionDetails}
        />
      </Box>
    </Box>
  );
};

export default RegionDetail;
