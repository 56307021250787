import {
  ThemeProvider,
  StyledEngineProvider,
  CssBaseline,
  LinkProps,
} from '@mui/material';
import React from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { createTheme, ThemeOptions } from '@mui/material/styles';
import { defaultThemeOptions } from '../../../theme/ThemeProviderWithTheme';
import _ from 'lodash';
import './fontface.scss';

// eslint-disable-next-line react/display-name
const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (Material UI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

export const theme = createTheme(
  _.merge(defaultThemeOptions, {
    typography: {
      fontFamily: '"Poppins", Roboto, Helvetica, Arial, sans-serif!important',
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily:
            '"Poppins", Roboto, Helvetica, Arial, sans-serif!important',
        },
      },
    },
    palette: {
      background: {
        container: '#F6F7F9',
        default: '#E2E7EA',
      },
      primary: {
        main: '#2576AA',
        dark: '#284F69',
        light: '#70A7CC',
        contrastText: '#FFFFFF',
      },
      secondary: {
        main: '#B9D9ED',
        light: '#EBF2F9',
        dark: '#5D6B70',
        contrastText: '#FFFFFF',
      },
      error: {
        main: '#FF1544',
        dark: '#692830',
        light: '#FFCDD3',
        contrastText: '#FEEAEE',
      },
      warning: {
        main: '#FF6D00',
        light: '#FFE9D8',
        contrastText: '#FFFEF2',
      },
      gray: {
        main: '#707070',
        dark: '#5D6B70',
        light: '#A7A7A7',
      },
      neutral: {
        main: '#F0F2F4',
        dark: '#E2E7EA',
        light: '#F6F7F9',
      },
      success: {
        main: '#19C4A2',
      },
    },
    components: {
      MuiLink: {
        defaultProps: {
          component: LinkBehavior,
        } as LinkProps,
      },
      MuiButtonBase: {
        defaultProps: {
          LinkComponent: LinkBehavior,
        },
      },
    },
  } as ThemeOptions)
);

export default function ThemeProviderWithThemeFW({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
