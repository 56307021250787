import moment from 'moment';
import React from 'react';
import { getPolyglot } from '../../../../../../i18n';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';

const TimeSelect = (props: {
  selectedPeriod: { initial: number; end: number };
  setSelectedPeriod: (period: { initial: number; end: number }) => void;
}) => {
  const { selectedPeriod, setSelectedPeriod } = props;
  const polyglot = getPolyglot();

  const timePeriods: Record<string, string> = {
    week: polyglot.t('freshwatch.dashboard.last_week'),
    month: polyglot.t('freshwatch.dashboard.last_month'),
    threeMonths: polyglot.t('freshwatch.dashboard.last_3_months'),
    sixMonths: polyglot.t('freshwatch.dashboard.last_6_months'),
    year: polyglot.t('freshwatch.dashboard.last_year'),
  };
  const getTime = (period: { initial: number; end: number }) => {
    const endDate = moment(period.end);
    const initialDate = moment(period.initial);

    const diffWeeks = endDate.diff(initialDate, 'weeks');
    const diffMonth = endDate.diff(initialDate, 'months');
    const diffYear = endDate.diff(initialDate, 'years');

    if (diffYear === 1) {
      return timePeriods.year;
    }
    if (diffMonth === 6) {
      return timePeriods.sixMonths;
    }
    if (diffMonth === 3) {
      return timePeriods.threeMonths;
    }
    if (diffMonth === 1) {
      return timePeriods.month;
    }
    if (diffWeeks === 1) {
      return timePeriods.week;
    }

    return timePeriods.week;
  };

  const setPeriod = (period: string) => {
    let timePeriod;
    switch (period) {
      case timePeriods.week:
        timePeriod = {
          end: new Date().getTime(),
          initial: moment().subtract(1, 'week').valueOf(),
        };
        break;
      case timePeriods.month:
        timePeriod = {
          end: new Date().getTime(),
          initial: moment().subtract(1, 'month').valueOf(),
        };
        break;
      case timePeriods.threeMonths:
        timePeriod = {
          end: new Date().getTime(),
          initial: moment().subtract(3, 'month').valueOf(),
        };
        break;
      case timePeriods.sixMonths:
        timePeriod = {
          end: new Date().getTime(),
          initial: moment().subtract(6, 'month').valueOf(),
        };
        break;
      case timePeriods.year:
        timePeriod = {
          end: new Date().getTime(),
          initial: moment().subtract(1, 'year').valueOf(),
        };
        break;
      default:
        timePeriod = {
          end: new Date().getTime(),
          initial: moment().subtract(1, 'year').valueOf(),
        };
    }

    setSelectedPeriod(timePeriod);
  };
  return (
    <Select
      id="time-select"
      placeholder={getTime(selectedPeriod)}
      value={getTime(selectedPeriod)}
      onChange={(e: SelectChangeEvent) => setPeriod(e?.target.value as string)}
    >
      {[...Object.keys(timePeriods)].map((timePeriod) => (
        <MenuItem key={timePeriods[timePeriod]} value={timePeriods[timePeriod]}>
          {timePeriods[timePeriod]}
        </MenuItem>
      ))}
    </Select>
  );
};

export default TimeSelect;
