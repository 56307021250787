import React, {
  ComponentType,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import MUITableCell, { TableCellProps } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  Box,
  useMediaQuery,
  useTheme,
  Paper,
  styled,
  TableSortLabel,
  Checkbox,
} from '@mui/material';

const TableCell = styled(MUITableCell)(({ theme }) => ({
  padding: `${theme.spacing(0.5)} 4px ${theme.spacing(0.5)} 0 `,
  fontSize: '1rem',
}));

const TableCellHeader = styled(TableCell)(({ theme }) => ({
  borderBottom: 'none',
}));
const TableLabel: ComponentType<any> = styled('span', {
  shouldForwardProp: (attr) => attr === 'children',
})``;

const PaperStyled = styled(Paper)(({ theme }) => ({
  padding: `0 ${theme.spacing(2)}`,
}));

type ValidRowModel = Record<string | symbol, any>;

export interface LightTableColumDef {
  /**
   * The column identifier. It's used to map with [[GridRowModel]] values.
   */
  field: string;
  /**
   * The title of the column rendered in the column header cell.
   */
  headerName: string;
  /**
   * Set the width of the column.
   * @default 100
   */
  width?: TableCellProps['width'];
  /**
   * The props for cell component
   * @default {}
   */
  TableCellProps?: TableCellProps;

  /**
   * If `true`, the column is sortable.
   */
  orderable?: boolean;
  textAlign?: TableCellProps['align'];
}
export type Order = 'asc' | 'desc';
const defaultVariant = 'lines';
export interface LightTableProps<RowModel> {
  columns: LightTableColumDef[];
  rows: RowModel[];
  /** Return the id of a given RowModel. @default `(row) => row?.id`*/
  getRowId?: (row: RowModel) => React.Key;
  getRowElement?: (row: RowModel, field: keyof RowModel) => React.ReactNode;
  getRowValue?: (row: RowModel, field: keyof RowModel) => any;
  getRowTitle?: (row: RowModel, field: keyof RowModel) => string | undefined;
  getComparator?: ReturnType<typeof getComparatorDefault>;
  TableContainerComponent?: ComponentType<any>;

  /** default value for the column that is desired order */
  orderByDefault?: keyof RowModel;
  variant?: 'clear' | 'lines';

  /* Props to display select rows */
  displaySelect?: boolean;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectChange?: (selected: readonly unknown[]) => void;
}
function getRowIdDefault(row: any) {
  return row?.id;
}

/**
 * get the value to order of a given row and field
 * @param row
 * @param field
 * @returns
 */
export function getRowValueDefault<RowModel>(row: any, field: keyof RowModel) {
  const rowField = row?.[field];
  return rowField?.value ?? rowField;
}

function getRowTitleDefault<RowModel>(row: any, field: keyof RowModel) {
  const rowField = row?.[field];
  return rowField?.title ?? undefined;
}
/**
 * get the element to render of a given row and field
 * @param row
 * @param field
 * @returns
 */
function getRowElementDefault<RowModel>(row: any, field: keyof RowModel) {
  const rowField = row?.[field];
  return rowField?.element ?? rowField;
}

function descendingComparator<RowModelValue>(
  a: RowModelValue,
  b: RowModelValue
) {
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}

/**
 * function to compare two values to order those
 */
function getComparatorDefault<RowModel>(
  getRowValue: (row: RowModel, field: keyof RowModel) => any
) {
  return (order: Order, orderBy: any) => (rowA: any, rowB: any) => {
    const valueA = getRowValue(rowA, orderBy);
    const valueB = getRowValue(rowB, orderBy);

    return order === 'desc'
      ? descendingComparator(valueA, valueB)
      : -descendingComparator(valueA, valueB);
  };
}

type RowData =
  | ReactNode
  | {
      value: any;
      element: ReactNode;
    };
/**
 *
 * Create a light table
 * se an example here [./LightTableExample.tsx](./http://gitlab.st.local/stoerk/web-frontends/commander-cloud-webclient/-/blob/StoerkId/src/theme/components/LightTable/LightTableExample.tsx)
 * @returns
 */
export function LightTable<RowModel extends ValidRowModel = any>(
  props: LightTableProps<RowModel>
) {
  const {
    columns,
    rows,
    getRowId = getRowIdDefault,
    getRowElement = getRowElementDefault,
    getRowValue = getRowValueDefault,
    getRowTitle = getRowTitleDefault,
    getComparator = getComparatorDefault(getRowValue),
    orderByDefault = columns[0]?.field,
    TableContainerComponent,
    variant = defaultVariant,
    displaySelect = false,
    onSelectChange = () => {},
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const TableContainer = TableContainerComponent
    ? TableContainerComponent
    : variant === 'lines'
    ? isMobile
      ? Box
      : PaperStyled
    : Box;
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof RowModel>(orderByDefault);
  const [selected, setSelected] = useState<readonly number[]>([]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof RowModel
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows = useMemo(
    () => rows.slice().sort(getComparator(order, orderBy)),
    [rows, getComparator, order, orderBy]
  );

  const createSortHandler = (property: string) => (event: any) => {
    handleRequestSort(event, property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      updateSelected(newSelected);

      return;
    }
    updateSelected([]);
  };

  const updateSelected = (selected: readonly number[]) => {
    setSelected(selected);
    onSelectChange(selected);
  };

  const handleRowClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    updateSelected(newSelected);
  };

  const numSelected = selected.length;
  const rowCount = rows.length;

  return (
    <TableContainer
      component={TableContainer}
      variant="outlined"
      sx={{
        overflow: 'auto',
      }}
    >
      <Table
        aria-label="light table"
        sx={{
          borderSpacing: '1rem',
          ...(variant === 'clear' && { borderCollapse: 'separate' }),
        }}
      >
        <TableHead>
          <TableRow>
            {displaySelect && (
              <TableCell
                padding="checkbox"
                sx={{
                  ...(variant === 'clear' && { borderBottom: 'none' }),
                }}
              >
                <Checkbox
                  color="primary"
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={handleSelectAllClick}
                />
              </TableCell>
            )}
            {columns.map((column) => {
              const { field, headerName, width, TableCellProps, orderable } =
                column;

              const TableLabelComponent = orderable
                ? TableSortLabel
                : TableLabel;
              return (
                <TableCellHeader
                  key={field}
                  width={width}
                  {...TableCellProps}
                  sortDirection={orderBy === column.field ? order : false}
                  sx={{
                    textAlign: column.textAlign,
                    ...(variant === 'clear' && { borderBottom: 'none' }),
                    ...TableCellProps?.sx,
                  }}
                >
                  <TableLabelComponent
                    active={orderBy === column.field}
                    direction={orderBy === column.field ? order : 'asc'}
                    onClick={createSortHandler(column.field)}
                  >
                    {headerName}
                  </TableLabelComponent>
                </TableCellHeader>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {visibleRows.map((row, index) => {
            const isItemSelected = selected.includes(row.id);
            const labelId = `checkbox-${index}`;
            return (
              <TableRow
                key={getRowId(row)}
                onClick={(event) => handleRowClick(event, getRowId(row))}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  cursor: displaySelect ? 'pointer' : 'default',
                }}
              >
                {displaySelect && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                    />
                  </TableCell>
                )}
                {columns.map((column) => {
                  const { field, width, TableCellProps } = column;
                  return (
                    <TableCell
                      key={field}
                      width={width}
                      title={getRowTitle(row, field)}
                      {...TableCellProps}
                      sx={{
                        textAlign: column.textAlign,
                        ...(variant === 'clear' && { borderBottom: 'none' }),
                        ...TableCellProps?.sx,
                      }}
                    >
                      {getRowElement(row, field)}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default LightTable;
