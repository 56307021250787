import React from 'react';
import logo_desktop from '../../../../assets/logo_desktop.svg';
import scc_logo from '../../../../assets/SCC-Logo_FullColors.svg';
import { Box } from '@mui/material';
import {
  HeaderIcon,
  HeaderIconProps,
} from '../../../../../../components/auth/AuthLayout/HeaderIcon/HeaderIcon';
import { useLocation } from 'react-router-dom';

function HeaderIconFW(props: HeaderIconProps) {
  const location = useLocation();
  const { pathname } = location;

  if ([`/login`, `/signup`, '/forgot-password'].includes(pathname)) {
    return (
      <Box
        display="flex"
        flexDirection={'column'}
        width="100%"
        alignItems={'center'}
      >
        <img
          style={{ alignSelf: 'end' }}
          src={scc_logo}
          alt="logo"
          className="logo"
        />
        <HeaderIcon {...props} logoIcon={logo_desktop} title="" />
      </Box>
    );
  }
  return <HeaderIcon {...props} logoIcon={logo_desktop} title="" />;
}

export default HeaderIconFW;
