import React from 'react';
import { RootState } from '../../store.model';
import { getAllGroups } from '../../devices/selectors';
import { createSelector } from '@reduxjs/toolkit';
import { getGroups } from './index';
import GroupModel from '../api/GroupModel';

export const getAllGroupsWithChainGroups = createSelector(
  [getAllGroups, getGroups],
  (allGroups, treeGroups) =>
    allGroups.map((group) => {
      const chainGroups = GroupModel.getPathToGroupRecursive(group, treeGroups);
      return {
        ...group,
        chainGroups,
      };
    })
);
