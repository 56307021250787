import React from 'react';
import {
  AppBar,
  AppBarProps,
  Box,
  Link,
  Toolbar,
  alpha,
  styled,
} from '@mui/material';
import { Link as LinkRouter } from 'react-router-dom';
import { getPolyglot } from '../../../../i18n';
import logoFooterSrc from './logo-footer.svg';

const LinkStyled: typeof Link = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  textDecoration: 'underline',
  '&:hover': {
    color: alpha(theme.palette.primary.contrastText, 0.8),
  },
})) as typeof Link;
export interface LinksFooterProps extends AppBarProps {
  logoFooter?: string;
  urlAboutUs?: string;
  slots?: { LinkComponent: typeof Link };
  logoHeight?: string | number;
}
function LinksFooter(props: LinksFooterProps) {
  const {
    logoFooter = logoFooterSrc,
    urlAboutUs = 'http://stoerk-tronic.com/',
    ...appBarProps
  } = props;
  const LinkComponent = props.slots?.LinkComponent ?? LinkStyled;
  const polyglot = getPolyglot();
  return (
    <AppBar
      position="static"
      component={'footer'}
      color="primary"
      sx={{
        alignItems: 'center',
        display: { xs: 'none', md: 'flex' },
      }}
      {...appBarProps}
    >
      <Toolbar
        sx={{
          height: '4rem',
          maxWidth: 1536,
          justifyContent: 'space-between',
          mx: 2,
          gap: 2,
          width: '100%',
          margin: 'auto',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <Box
          sx={{
            gap: { lg: '4rem', md: '2rem' },
            display: 'flex',
          }}
        >
          <LinkComponent to="/terms-and-conditions" component={LinkRouter}>
            {polyglot.t('footer.terms_and_conditions')}
          </LinkComponent>
          <LinkComponent to="/privacy" component={LinkRouter}>
            {polyglot.t('footer.privacy_policy')}
          </LinkComponent>
          <LinkComponent to="/cookies" component={LinkRouter}>
            {polyglot.t('footer.cookie_policy')}
          </LinkComponent>
          <LinkComponent to="/impressum" component={LinkRouter}>
            {polyglot.t('footer.impressum')}
          </LinkComponent>
          <LinkComponent href={urlAboutUs} target="_blank">
            {polyglot.t('footer.about_us')}
          </LinkComponent>
        </Box>

        {!!logoFooter && (
          <img
            src={logoFooter}
            height={props.logoHeight ?? 30}
            alt="logo-stoerk"
          />
        )}
      </Toolbar>
    </AppBar>
  );
}

export default LinksFooter;
