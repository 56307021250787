import { createSelector } from '@reduxjs/toolkit';
import { getMerchandiserByMerchandiserIdPropSelector } from '../composableDeviceSelectors';
import { getSetPointsSelectorById } from '../../../../../../redux/controlUnit/selectors/setPoint';
import { getMixedCurrentEventsFromId } from '../../../../../../redux/controlUnit/selectors/events';
import { getCUParametersComposedFromControlUnit } from '../../../../../../redux/CUParameters/selectors';
import { getSetPoints } from '../../../../../../redux/controlUnit/api/util/setPoint/getSetPoints';
import { DeviceControlSetPointsMetadataAPIResponse } from '../../../../../../redux/devices/api/device.model';
import _ from 'lodash';
import {
  getControlUnitsSensorDataByControlUnitId,
  getSensorDataMetadataSelector,
} from '../../../../../../redux/controlUnit/selectors/sensordata';

const getSetPointNameFromMerchandiser = createSelector(
  [getMerchandiserByMerchandiserIdPropSelector],
  (merchandiser) => {
    return merchandiser?.machineType?.labels?.businessGroup;
  }
);

export const getMerchandiserSetPointsSelectorById = createSelector(
  [
    getSetPointNameFromMerchandiser,
    getMixedCurrentEventsFromId,
    getSensorDataMetadataSelector,
    getControlUnitsSensorDataByControlUnitId,
    getCUParametersComposedFromControlUnit,
  ],
  (setPointName, actions, channels, sensorData, cuParameters) => {
    // create a fake setpoint metadata to be able to use the getSetPoints function
    // using the the setpoint name from the merchandiser
    const setPoints: DeviceControlSetPointsMetadataAPIResponse[] | undefined =
      setPointName
        ? [
            {
              id: setPointName,
              name: setPointName,
              // those metadata should not be used, use parameters values instead
              dec: 1,
              exp: 0,
              min: 0,
              max: 0,
              unit: 'Ignore',
              unitString: 'Ignore',
            },
          ]
        : [];
    return getSetPoints(setPoints, actions, channels, sensorData, cuParameters);
  }
);

export const getMainSetPointFromMerchandiserAndCUId = createSelector(
  [
    getSetPointNameFromMerchandiser,
    getSetPointsSelectorById,
    getMerchandiserSetPointsSelectorById,
  ],
  (
    setPointNameFromBluePrint,
    defaultSetPointFromCu,
    setPointDefinedManually
  ) => {
    const fromDefault = defaultSetPointFromCu?.filter(
      (setPoint) =>
        setPointNameFromBluePrint &&
        setPoint.parameter?.name === setPointNameFromBluePrint
    );

    if (fromDefault?.length) {
      // is better keep using the default setPoint from the CU
      // if is possible, current version don't have setPoints defined by cu
      return _.first(fromDefault);
    } else {
      return _.first(setPointDefinedManually);
    }
  }
);
