import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import SearchModal, { SearchModalProps } from './SearchModal';
import styled from '@emotion/styled';
import ListEntitiesSearch from './ListEntitiesSearch';

interface SearchEntitiesModalProps extends SearchModalProps {}
function SearchEntitiesModal(props: SearchEntitiesModalProps) {
  const { open, TextFieldProps, onClose } = props;
  const [searchValue, setSearchValue] = useState('');
  // Reset search value when modal is closed
  useEffect(() => {
    if (!open) setSearchValue('');
  }, [open]);

  return (
    <SearchModal
      {...props}
      TextFieldProps={{
        ...TextFieldProps,
        autoFocus: true,
        onChange: (e) => setSearchValue(e.target.value),
      }}
    >
      <SearchModalContent>
        <ListEntitiesSearch searchValue={searchValue} close={onClose} />
      </SearchModalContent>
    </SearchModal>
  );
}

const SearchModalContent = styled(Box)`
  min-height: 50vh;
`;

export default SearchEntitiesModal;
