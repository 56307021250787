import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useDevice } from '../../../redux/devices/hooks/useDevice';
import useShowErrorMessage from '../../../handlingErrors/useShowErrorMessage';
import { useControlUnitsById } from '../../../redux/controlUnit/hooks/useControlUnitsById';
import PanControlUnitFryer from './PanControlUnitFryer';
import { Base64 } from '../../../util/Base64';

interface FryerPanProps {
  encodedDeviceId: string;
  isLoadingComposableDevice: boolean;
}

export default function FryerPan(props: FryerPanProps) {
  const { encodedDeviceId, isLoadingComposableDevice } = props;

  /** Init Groups */
  const [device, loadingDevices] = useDevice(encodedDeviceId);
  const showErrorMessage = useShowErrorMessage();
  const [controlUnits] = useControlUnitsById(
    encodedDeviceId,
    showErrorMessage,
    true
  );

  return (
    <Box minHeight="625px" flex={1}>
      {loadingDevices || isLoadingComposableDevice ? (
        <div>
          <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        </div>
      ) : controlUnits && controlUnits.length > 0 ? (
        controlUnits.slice(0, 1).map((cu, index) => (
          <Box key={cu.id.concat(index.toString())} flex={1}>
            <PanControlUnitFryer
              deviceName={cu.name ?? device.name}
              controlUnit={cu}
              encodedDeviceId={encodedDeviceId}
              deviceId={Base64.decode(encodedDeviceId)}
            />
          </Box>
        ))
      ) : null}
    </Box>
  );
}
