import thunk from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import { setAutoFreeze } from 'immer';
import StoerkIdAPI from './stoerkID/StoerkIdAPI';
import UserAPI from './users/api/UserAPI';
import IncidentAPI from './incident/api/IncidentAPI';
import NotificationAPI from './notifications/api/NotificationAPI';
import CUActionsAPI from './CUActions/api/CUActionsAPI';
import ControlUnitAPI from './controlUnit/api/ControlUnitAPI';
import { ReportsGenerationAPI } from '../customized/fresh-watch/redux/reports/api/ReportsGenerationAPI';
import ReportRealTimeAPI from './reports/api/ReportRealTimeAPI';

// Fixes "Cannot assign to read only property" error message
// when modifying objects from Redux state directly.
// it is only for compatible production bundle meanwhile whe fix all mutation errors
// https://github.com/reduxjs/redux-toolkit/issues/424
if (process.env.NODE_ENV === 'production') {
  setAutoFreeze(false);
}

const initialState = {};
const middleware = [
  thunk,
  ControlUnitAPI.middleware,
  CUActionsAPI.middleware,
  IncidentAPI.middleware,
  StoerkIdAPI.middleware,
  UserAPI.middleware,
  NotificationAPI.middleware,
  ReportsGenerationAPI.middleware,
  ReportRealTimeAPI.middleware,
];

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      // FIXME: check for serializable values in actions
      // serializableCheck:{
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
      immutableCheck: process.env.NODE_ENV !== 'production',
    }).concat(middleware),
  devTools: true,
  preloadedState: initialState,
});

export default store;
