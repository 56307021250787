import React, { useEffect, useState } from 'react';
import {
  TextField,
  Checkbox,
  Autocomplete,
  Box,
  FilterOptionsState,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { getPolyglot } from '../../../../i18n';

export interface MultiSelectAutocompleteProps<T> {
  options: T[];
  label: string;
  allOptionsSelectedLabel?: string;
  placeholder: string;
  chosenOptions?: T[];
  required?: boolean;
  onSelectionChange: (selectedOptions: T[]) => void;
  equalFn?: (option: T, value: T) => boolean;
  displayResultFn?: (option: T) => string;
  labelFn?: (option: T) => string;
  filterOptionsFn?: (options: T[], state: any) => T[];
}
function MultiSelectAutocomplete<T>({
  options,
  label,
  placeholder,
  allOptionsSelectedLabel,
  chosenOptions,
  required = false,
  equalFn = (option: T, value: T) => option === value,
  displayResultFn = (option: T) => String(option),
  onSelectionChange,
  labelFn = (option: T) => String(option),
  filterOptionsFn,
}: MultiSelectAutocompleteProps<T>) {
  const [selectedOptions, setSelectedOptions] = useState<T[]>(
    chosenOptions || []
  );

  const selectedItems = chosenOptions || selectedOptions;
  const polyglot = getPolyglot();

  const allOption = {
    id: 'all',
    label: allOptionsSelectedLabel || polyglot.t('all'),
  } as T;
  const optionsWithAll = [allOption, ...options];

  useEffect(() => {
    if (!options.length) {
      setSelectedOptions([]);
    }
  }, [options]);

  const updateSelected = (_event: any, value: T[]) => {
    const isAllSelected = value.some((option: any) => option.id === 'all');
    if (isAllSelected) {
      if (selectedItems.length !== options.length) {
        updateSelectedOptions(options);
      } else {
        updateSelectedOptions([]);
      }
    } else {
      updateSelectedOptions(value);
    }
  };

  const updateSelectedOptions = (value: T[]) => {
    setSelectedOptions(value);
    onSelectionChange(value);
  };

  const customFilter = (options: T[], state: FilterOptionsState<T>) => {
    // remove all from the options in order to not search for it
    options = options.filter((option) => (option as any).id !== 'all');
    let result = [];
    if (filterOptionsFn) {
      result = filterOptionsFn(options, state);
    }

    result = options.filter(
      (option) =>
        (option as any).id !== 'all' &&
        labelFn(option).toLowerCase().includes(state.inputValue.toLowerCase())
    );

    // add allOption to the options
    return [allOption, ...result];
  };

  const renderSelectedItems = () => {
    if (selectedItems.length === 0) {
      return '';
    }
    if (selectedItems.length === options.length) {
      return allOptionsSelectedLabel;
    }

    return selectedItems.map(displayResultFn).join(', ');
  };

  const labelFunctionWithAll = (option: T) =>
    (option as any).id === 'all' ? (option as any).label : labelFn(option);

  return (
    <Autocomplete
      multiple
      options={optionsWithAll}
      isOptionEqualToValue={equalFn}
      disableCloseOnSelect
      getOptionLabel={labelFunctionWithAll}
      renderOption={(props, option, { selected }) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <li {...props}>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            checked={
              (option as any).id === 'all' && options.length > 0
                ? selectedItems.length === options.length
                : selected
            }
            indeterminate={
              (option as any).id === 'all' &&
              selectedItems.length !== options.length &&
              selectedItems.length > 0
            }
            id={
              (option as any).id && (option as any).id === 'all'
                ? (option as any).id
                : labelFn(option)
            }
          />
          {labelFunctionWithAll(option)}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={`${label} ${required ? '*' : ''}`}
          InputProps={{
            ...params.InputProps,
            placeholder: selectedItems.length > 0 ? '' : placeholder,
            startAdornment: (
              <Box
                component="span"
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '70%',
                  marginRight: '10px',
                }}
              >
                {renderSelectedItems()}
              </Box>
            ),
          }}
        />
      )}
      value={selectedItems}
      onChange={updateSelected}
      renderTags={() => null}
      filterOptions={customFilter}
    />
  );
}

export default MultiSelectAutocomplete;
