export enum ComposableDeviceUserInterfaces {
  OVEN = 'ST_OVEN',
  FRYER = 'ST_FRYER',
  SCC_MERCHANDISE = 'SCC_MERCHANDISE',
}

export const ST_COMPOSABLE_CONSUMPTION_DATA_KEY =
  'ST_COMPOSABLE_CONSUMPTION_DATA_KEY';

export const ST_KILO_WATT_EUR_CONSUMPTION_KEY = 'ST_KILO_WATT_EUR_CONSUMPTION';
