import { GlobalStyles } from '@mui/material';
import React from 'react';

function CookieStyles() {
  return (
    <GlobalStyles
      styles={(theme) => ({
        ['#cc-main']: {
          '--cc-btn-primary-bg': theme.palette.primary.main,
          '--cc-btn-primary-color': theme.palette.primary.contrastText,
          '--cc-btn-primary-hover-bg': theme.palette.primary.dark,
          '--cc-btn-primary-hover-color': '#fff',
          '--cc-bg': theme.palette.common.white,

          '--cc-btn-secondary-bg': '#fff',
          '--cc-btn-secondary-color': theme.palette.primary.main,
          '--cc-btn-secondary-hover-bg': theme.palette.secondary.light,
          '--cc-btn-secondary-hover-color': theme.palette.primary.main,
        },
      })}
    />
  );
}

export default CookieStyles;
