import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { PolyglotComponentProps, withPolyglot } from '../../../../../i18n';
import ListItem from '../../../../../theme/components/ListItem';
import { Group } from '../../../../../redux/groups/api/group.model';
import GroupModel from '../../../../../redux/groups/api/GroupModel';
import { RootState, useAppSelector } from '../../../../../redux/store.model';
import { groupButtonsRightsSelector } from '../../../../../redux/groups/selectors/groupRightsSelectors';
import {
  RouterComponentProps,
  withRouter,
} from '../../../../../util/route-dom';
import {
  GroupActionsGrid,
  GroupActionsList,
} from './GroupActions/GroupActions';
import { DeviceGroupIcon } from '../../../../../theme/icons';
import { getAlarmActiveBySelector } from '../../../../../redux/incident/selectors/getAlarmActiveBySelector';

interface GroupCardProps
  extends PolyglotComponentProps,
    RouterComponentProps,
    OwnProps,
    ConnectedComponentProps {}
interface OwnProps {
  group?: Group;
  loading?: boolean;
  groupId: string;
  moduleSelected: string | '';
}

export function GroupCardSimple(props: GroupCardProps) {
  const { group, groupId, navigate, moduleSelected, loading } = props;

  const { data: alarmsActive } = useAppSelector((state) =>
    getAlarmActiveBySelector(state, {
      groupId: groupId,
    })
  );

  const openDeviceDetail = () => {
    if (moduleSelected === '') return;
    const url = `/devicemanager/group/${groupId}`;
    navigate(url);
  };

  return (
    <ListItem
      key={groupId}
      id={groupId}
      name={group?.name}
      loading={loading}
      iconURL={group?.iconURL}
      subtitle={GroupModel.getGroupSubtitles(group)}
      iconNode={!group?.iconURL && <DeviceGroupIcon />}
      className={GroupModel.getClassesNamesFromDevice(group)}
      onClick={openDeviceDetail}
      buttons={<GroupActionsList group={group} />}
      menuButtons={<GroupActionsGrid group={group} />}
      ItemContainerProps={{ sx: { px: 3 } }}
      error={alarmsActive.length > 0}
    />
  );
}

const mapStateToProps = (
  state: RootState,
  props: OwnProps &
    RouterComponentProps<{
      groupId?: string;
    }>
) => ({
  ...groupButtonsRightsSelector(state, props),
  accessToken: state.auth.accessToken,
});

const connector = connect(mapStateToProps);

export type ConnectedComponentProps = ConnectedProps<typeof connector>;

const GroupCard = withPolyglot(withRouter(connector(GroupCardSimple)));
export default GroupCard;
