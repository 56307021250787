import React, { useMemo } from 'react';
import ComposableDeviceFormGeneric from './ComposableDeviceFormGeneric';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import { getPolyglot } from '../../../i18n';
import { FormikProps } from 'formik';
import { GroupCreateAPI } from '../../../redux/groups/api/group.model';
import { ComposableDeviceUserInterfaces } from '../../../redux/composableDevice/ComposableDevice.model';
import { useAppSelector } from '../../../redux/store.model';

import { IconUpload } from '../../../redux/groups/groups.model';
import ComposableDeviceFormOasis from '../../../customized/fresh-watch/components/ComposableDevice/ComposableDeviceForm/ComposableDeviceFormOasis';
import { getMachinesTypeSelector } from '../../../redux/composableDevice/selectors/composableDeviceSelectors';
import { ComposableDeviceValues } from '../ComposableDeviceAdd';
export interface ComposableDeviceFormProps {
  groupNameParent?: string;
  formik: FormikProps<ComposableDeviceValues>;
  setImage: React.Dispatch<React.SetStateAction<IconUpload | null>>;
  setLogo: React.Dispatch<React.SetStateAction<IconUpload | null>>;
  image: IconUpload | null;
  logo: IconUpload | null;
}
function ComposableDeviceForm(props: ComposableDeviceFormProps) {
  const { formik } = props;
  const polyglot = getPolyglot();
  const machineTypes = useAppSelector(getMachinesTypeSelector);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={2}>
        <FormControl fullWidth>
          <InputLabel>{polyglot.t('composable_device.type')}</InputLabel>
          <Select
            label={polyglot.t('composable_device.type')}
            onChange={(event) => {
              formik.handleChange(event);
              formik.setFieldValue(
                'composableDevice.machineTypeId',
                event.target.value
              );
            }}
            value={formik.values.composableDevice?.machineTypeId}
            defaultValue={formik.initialValues.composableDevice?.machineTypeId}
          >
            {machineTypes.map((machineType) => (
              <MenuItem key={machineType.id} value={machineType.id}>
                {machineType?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <ComposableDeviceFormCustom {...props} />
      </Stack>
    </form>
  );
}

function ComposableDeviceFormCustom(props: ComposableDeviceFormProps) {
  const machineTypes = useAppSelector(getMachinesTypeSelector);
  const machineType = useMemo(
    () =>
      machineTypes.find(
        (machineType) =>
          machineType.id === props.formik.values.composableDevice?.machineTypeId
      ),
    [machineTypes, props.formik.values.composableDevice?.machineTypeId]
  );

  switch (machineType?.userInterface) {
    case null:
    case undefined:
      return null;
    case ComposableDeviceUserInterfaces.SCC_MERCHANDISE:
      return <ComposableDeviceFormOasis {...props} />;
    default:
      return <ComposableDeviceFormGeneric {...props} />;
  }
}

export default ComposableDeviceForm;
