import {
  Box,
  Button,
  FormControl,
  Grid,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Group } from '../../../../../../redux/groups/api/group.model';
import { getAllChildrenGroups } from '../../../../../../redux/devices/selectors';
import {
  SCC_TYPE,
  filterBySCCType,
} from '../../../../redux/composableDevice/model/SCCGroup.model';
import { Merchandiser } from '../../../../redux/composableDevice/model/Merchandiser.model';
import { getPolyglot } from '../../../../../../i18n';
import MultiSelectAutocomplete from '../../../../../../theme/components/Forms/MultiSelectAutocomplete';
import { DashBoardIcon } from '../../../../theme/icons';
import { useAppDispatch } from '../../../../../../redux/store.model';
import _ from 'lodash';
import TimeSelect from './TimeSelect';
import { useDashboardFilters } from '../../../../redux/dashboardFilters/hooks/useDashboardFilters';
import {
  dashboardFiltersActions,
  initialState,
} from '../../../../redux/dashboardFilters/slice/dashboardFiltersSlice';
import StyledButton from '../../../../theme/components/StyledButton/StyledButton';

const FilterPanel = (props: {
  regions: Group[];
  stores: Group[];
  merchandisers: Merchandiser[];
  displayTitle?: boolean;
}) => {
  const { regions, stores, merchandisers, displayTitle = false } = props;

  const filters = useDashboardFilters();

  useEffect(() => {
    setFilteredRegions(filters.regions);
  }, [filters.regions]);
  useEffect(() => {
    setFilteredStores(filters.stores);
  }, [filters.stores]);
  useEffect(() => {
    setFilteredMerchandisers(filters.merchandisers);
  }, [filters.merchandisers]);
  useEffect(() => {
    setSelectedPeriod(filters.timePeriod);
  }, [filters.timePeriod]);

  const dispatch = useAppDispatch();
  const polyglot = getPolyglot();

  const [storesOptions, setStoresOptions] = useState<Group[]>(stores);
  const [merchandisersOptions, setMerchandisersOptions] =
    React.useState<Merchandiser[]>(merchandisers);

  const [filteredRegions, setFilteredRegions] = useState<Group[]>(
    filters.regions
  );

  const [filteredStores, setFilteredStores] = useState<Group[]>(filters.stores);
  const [filteredMerchandisers, setFilteredMerchandisers] = useState<
    Merchandiser[]
  >(filters.merchandisers);

  const [selectedPeriod, setSelectedPeriod] = useState<{
    initial: number;
    end: number;
  }>(filters.timePeriod);

  useEffect(() => {
    if (filteredRegions.length > 0) {
      const storesFromFilteredRegions = getAllChildrenGroups(
        filteredRegions,
        []
      ).filter(filterBySCCType(SCC_TYPE.STORE));
      setStoresOptions(storesFromFilteredRegions);
      const newSelectedItems = _.intersectionBy(
        storesFromFilteredRegions,
        filteredStores,
        'id'
      );

      setFilteredStores(newSelectedItems);
    } else {
      setStoresOptions(stores);
    }
  }, [filteredRegions]);

  useEffect(() => {
    if (filteredStores.length > 0 || storesOptions.length > 0) {
      const itemToFilter =
        filteredStores.length > 0 ? filteredStores : storesOptions;
      const childrenStores = getAllChildrenGroups(
        itemToFilter.map((store) => store.childGroups).flat(),
        []
      );

      const merchandisersFromStores = merchandisers.filter((merchandiser) =>
        childrenStores.find((store) => store.id === merchandiser.id)
      );
      setMerchandisersOptions(merchandisersFromStores);

      const newSelectedItems = _.intersectionBy(
        merchandisersFromStores,
        filteredMerchandisers,
        'id'
      );

      setFilteredMerchandisers(newSelectedItems);
    } else {
      setMerchandisersOptions([]);
    }
  }, [storesOptions, filteredStores]);

  const filterHandle = () => {
    dispatch(
      dashboardFiltersActions.updateFilters({
        regions: filteredRegions,
        stores: filteredStores,
        merchandisers: filteredMerchandisers,
        timePeriod: selectedPeriod,
      })
    );
  };

  const onClearHandle = () => {
    dispatch(dashboardFiltersActions.resetFilters());
    setFilteredRegions([]);
    setFilteredStores([]);
    setFilteredMerchandisers([]);
    setSelectedPeriod(initialState.timePeriod);
  };
  const theme = useTheme();

  let filterWidth = '20%';
  if (useMediaQuery(theme.breakpoints.down('md'))) {
    filterWidth = '40%';
  }
  if (useMediaQuery(theme.breakpoints.down('sm'))) {
    filterWidth = '100%';
  }
  return (
    <Grid container sx={{ padding: '0.5rem 2rem 1rem 2rem' }} spacing={1}>
      {displayTitle && (
        <Grid sx={{ width: '100%', paddingTop: 0, paddingLeft: '1rem' }}>
          <Button
            variant="text"
            disableRipple={true}
            disableFocusRipple={true}
            sx={{
              color: theme.palette.common.black,
              cursor: 'default',
              '&:hover': {
                backgroundColor: 'transparent',
                boxShadow: 'none',
              },
            }}
            startIcon={
              <DashBoardIcon
                fontSize="1.5rem"
                color={theme.palette.common.black}
              />
            }
          >
            {polyglot.t('freshwatch.dashboard.my_dashboard')}
          </Button>
        </Grid>
      )}
      <Grid item xs={9} margin={'auto'}>
        <Box display={'flex'} gap={1} flexWrap={'wrap'} alignItems={'end'}>
          <FormControl fullWidth sx={{ flex: `1 0 ${filterWidth}` }}>
            <MultiSelectAutocomplete
              label=""
              placeholder={polyglot.t('freshwatch.dashboard.all_regions')}
              allOptionsSelectedLabel={polyglot.t(
                'freshwatch.dashboard.all_regions'
              )}
              options={regions}
              chosenOptions={filteredRegions}
              onSelectionChange={setFilteredRegions}
              displayResultFn={(option) => option.name}
              labelFn={(option) => option.name}
              equalFn={(option, value) => option.id === value.id}
            />
          </FormControl>
          <FormControl fullWidth sx={{ flex: `1 0 ${filterWidth}` }}>
            <MultiSelectAutocomplete
              placeholder={polyglot.t('freshwatch.dashboard.all_stores')}
              allOptionsSelectedLabel={polyglot.t(
                'freshwatch.dashboard.all_stores'
              )}
              label=""
              options={storesOptions}
              chosenOptions={filteredStores}
              onSelectionChange={setFilteredStores}
              displayResultFn={(option) => option.name}
              labelFn={(option) => option.name}
              equalFn={(option, value) => option.id === value.id}
            />
          </FormControl>
          <FormControl fullWidth sx={{ flex: `1 0 ${filterWidth}` }}>
            <MultiSelectAutocomplete
              label=""
              placeholder={polyglot.t('freshwatch.dashboard.all_merchandisers')}
              allOptionsSelectedLabel={polyglot.t(
                'freshwatch.dashboard.all_merchandisers'
              )}
              options={merchandisersOptions}
              chosenOptions={filteredMerchandisers}
              onSelectionChange={setFilteredMerchandisers}
              displayResultFn={(option) => option.name}
              labelFn={(option) => option.name}
              equalFn={(option, value) => option.id === value.id}
            />
          </FormControl>
          <FormControl fullWidth sx={{ flex: `1 0 ${filterWidth}` }}>
            <TimeSelect
              selectedPeriod={selectedPeriod}
              setSelectedPeriod={setSelectedPeriod}
            />
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={3} alignContent={'center'} pl={'4rem'}>
        <Stack
          direction={{ sm: 'column', lg: 'row' }}
          gap={'1rem'}
          alignItems="baseline"
        >
          <StyledButton
            color="primary"
            variant="outlined"
            onClick={onClearHandle}
            sx={{ flex: `1 0 ${filterWidth}` }}
          >
            {polyglot.t('freshwatch.dashboard.clear')}
          </StyledButton>
          <StyledButton
            color="primary"
            variant="contained"
            onClick={filterHandle}
            sx={{ flex: `1 0 ${filterWidth}` }}
          >
            {polyglot.t('freshwatch.dashboard.ok')}
          </StyledButton>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default FilterPanel;
