import React from 'react';
import AuthLayout, {
  AuthLayoutProps,
} from '../../../../../components/auth/AuthLayout/AuthLayout';
import imageLandingPage3Desktop from '../../../assets/fours-fringand-fr__FringandScreenshoot1_cutted.jpg';

function AuthLayoutFF(props: AuthLayoutProps) {
  return (
    <AuthLayout {...props} imageRight={imageLandingPage3Desktop}>
      {props.children}
    </AuthLayout>
  );
}

export default AuthLayoutFF;
