import { createSlice } from '@reduxjs/toolkit';
import {
  Device,
  DeviceControlUnitsAPIResponse,
} from '../../devices/api/device.model';

import { ErrorRestST } from '../../../axios/rest.model';
import { arrayToRecord } from '../../../util/ArrayUtil';
import { fetchDeviceControlUnits } from '../actions/thunks';
import { nameReducer } from '../controlUnit.model';

export const initialStateControlUnits: ControlUnitState = {
  dictionaryControlUnit: {},
  loadingDeviceControlUnit: {},
  errorDeviceControlUnit: {},
};

export interface ControlUnitState {
  dictionaryControlUnit: Record<
    Device['uuid'],
    | Record<DeviceControlUnitsAPIResponse['id'], DeviceControlUnitsAPIResponse>
    | undefined
  >;
  loadingDeviceControlUnit: Record<Device['uuid'], boolean | undefined>;
  errorDeviceControlUnit: Record<Device['uuid'], ErrorRestST | undefined>;
}

export const ControlUnitSlice = createSlice({
  name: nameReducer,
  initialState: initialStateControlUnits,
  reducers: {},
  extraReducers: (builder) => {
    // fetchDeviceControlUnits Thunk
    builder.addCase(fetchDeviceControlUnits.pending, (state, action) => {
      state.loadingDeviceControlUnit[action.meta.arg] = true;
      state.errorDeviceControlUnit[action.meta.arg] = undefined;
    });
    builder.addCase(fetchDeviceControlUnits.fulfilled, (state, action) => {
      // fulfilled are in other action
      state.loadingDeviceControlUnit[action.meta.arg] = false;
      state.errorDeviceControlUnit[action.meta.arg] = undefined;
      if (action.payload)
        state.dictionaryControlUnit[action.meta.arg] = arrayToRecord(
          action.payload.controlunits,
          'id'
        );
    });
    builder.addCase(fetchDeviceControlUnits.rejected, (state, action) => {
      state.loadingDeviceControlUnit[action.meta.arg] = false;
      state.errorDeviceControlUnit[action.meta.arg] =
        action.error as ErrorRestST;
    });
  },
});

export const {
  actions: actionsControlUnit,
  reducer: reducerControlUnit,
  name: nameReducerControlUnit,
} = ControlUnitSlice;
