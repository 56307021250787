import { getAllChildrenGroups } from '../../../../../redux/devices/selectors';
import { Group } from '../../../../../redux/groups/api/group.model';
import { IncidentAPIResponseMVP } from '../../../../../redux/incident/api/Incident.model';
import { Merchandiser } from './Merchandiser.model';

export enum SCC_TYPE {
  REGION = 'REGION',
  STORE = 'STORE',
  CITY = 'CITY',
}

export interface CityDetails {
  store: Group;
  merchandisers: Merchandiser[];
  alarms: IncidentAPIResponseMVP[];
}

export interface RegionDetails {
  city: Group;
  citiesDetails: CityDetails[];
}

/*
 * Filter groups by SCC_TYPE
 */
export function filterBySCCType(type: SCC_TYPE) {
  return (group: Group) => group.attributes['SCC_TYPE'] === type;
}

/*
 * get the Alarms that are related to any of the devices that are in the region
 */
export function getAlarmsInRegion(
  incidents: IncidentAPIResponseMVP[],
  region: Group
) {
  const children = getAllChildrenGroups([region], []);

  return incidents.filter((alarm) =>
    children.find((group) =>
      group.devices.find((device) => atob(device) === alarm.deviceId)
    )
  );
}

function getStoreMerchandisers(
  storesGroups: Group[],
  merchandisers: Merchandiser[]
) {
  const storeGroupIds = new Set(storesGroups.map((group) => group.id));
  return merchandisers.filter((merchandiser) =>
    storeGroupIds.has(merchandiser.id)
  );
}

function getAlarmsForStoreMerchandisers(
  storeMerchandisers: Merchandiser[],
  alarms: IncidentAPIResponseMVP[]
) {
  const merchandiserDeviceIds = new Set(
    storeMerchandisers.flatMap((merchandiser) =>
      merchandiser.devices.map((device) => atob(device))
    )
  );
  return alarms.filter((alarm) => merchandiserDeviceIds.has(alarm.deviceId));
}

/*
 * get the details of cities and merchandisers that are in the region
 */
export function createRegionDetails(
  city: Group,
  stores: Group[],
  merchandisers: Merchandiser[],
  alarms: IncidentAPIResponseMVP[]
) {
  const children = getAllChildrenGroups(city.childGroups, []);
  // Filter the stores of the city that are in the region
  const storesOfCity = children.filter(
    (child) =>
      filterBySCCType(SCC_TYPE.STORE) &&
      stores.some((store) => store.id === child.id)
  );

  const storesList = storesOfCity.map((store) => {
    /* Get all the children groups of the store */
    const storesGroups = getAllChildrenGroups(store.childGroups, []);
    /* Get the merchandisers that are in the store */
    const storeMerchandisers = getStoreMerchandisers(
      storesGroups,
      merchandisers
    );
    /* Get the alarms that are related to the devices that are in the store */
    const alarmsDevices = getAlarmsForStoreMerchandisers(
      storeMerchandisers,
      alarms
    );

    return {
      store,
      merchandisers: storeMerchandisers,
      alarms: alarmsDevices,
    };
  });

  return {
    city,
    citiesDetails: storesList.sort(sortCityDetails),
  };
}

function sortByAlarmsQuantityOrName<T>(
  a: T,
  b: T,
  getAlarms: (item: T) => number,
  getName: (item: T) => string
) {
  const aAlarms = getAlarms(a);
  const bAlarms = getAlarms(b);

  if (aAlarms !== 0 || bAlarms !== 0) {
    return bAlarms - aAlarms;
  } else {
    return getName(a).localeCompare(getName(b));
  }
}

export function sortCityDetails(a: CityDetails, b: CityDetails) {
  return sortByAlarmsQuantityOrName(
    a,
    b,
    (item) => item.alarms.length,
    (item) => item.store.name
  );
}

export function sortRegionDetails(a: RegionDetails, b: RegionDetails) {
  return sortByAlarmsQuantityOrName(
    a,
    b,
    (item) => item.citiesDetails.filter((city) => city.alarms.length).length,
    (item) => item.city.name
  );
}

export function getAllAlarmsFromStores(
  citiesDetails: CityDetails[]
): IncidentAPIResponseMVP[] {
  return citiesDetails.map((city) => city.alarms).flat();
}

export function getAllDeviceIds(
  regionInformation: Map<string, RegionDetails>
): string[] {
  const items = [...regionInformation.values()]
    .map((item) =>
      item.citiesDetails.reduce(
        (prev, curr) =>
          prev.concat(...curr.merchandisers.map((m) => m.devices)),
        [] as string[]
      )
    )
    .flat();
  return Array.from(new Set(items));
}
