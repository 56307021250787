import produce from 'immer';
import { Group } from '../../groups/api/group.model';
import {
  ST_COMPOSABLE_CONSUMPTION_DATA_KEY,
  ST_KILO_WATT_EUR_CONSUMPTION_KEY,
} from '../ComposableDevice.model';
import _ from 'lodash';
import { group } from 'console';

export const getConsumptionData = (group?: Group): Record<string, number> => {
  const data = _.get(
    group,
    `composableDevice.labels.${ST_COMPOSABLE_CONSUMPTION_DATA_KEY}`
  );
  if (data) return JSON.parse(data) as Record<string, number>;
  return {};
};

export const getConsumptionPriceKWh = (group?: Group) => {
  const data = _.get(
    group,
    `composableDevice.labels.${ST_KILO_WATT_EUR_CONSUMPTION_KEY}`
  );
  if (data) return JSON.parse(data) as number;
  return 0;
};

export const setConsumptionPriceKWh = (
  group: Group | undefined,
  value: number | undefined
) =>
  group &&
  produce(group, (draft) => {
    _.set(
      draft,
      `composableDevice.labels.${ST_KILO_WATT_EUR_CONSUMPTION_KEY}`,
      JSON.stringify(value)
    );
  });

export const setConsumption = (
  group: Group | undefined,
  value: Record<string, number | undefined>
) =>
  group &&
  produce(group, (draft) => {
    _.set(
      draft,
      `composableDevice.labels.${ST_COMPOSABLE_CONSUMPTION_DATA_KEY}`,
      JSON.stringify(value)
    );
  });
