import React from 'react';
import { useTheme } from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from 'recharts';
import { Device } from '../../../redux/devices/api/device.model';
import { getColorFromPalette, parseSecondsToTime } from './util/mock/Utils';
import { ITimeFrame } from './util/mock/getMockDataDashboard';
import { getPolyglot } from '../../../i18n';

interface StackedBarChartProps {
  commanders: Device[];
  timeFrame: ITimeFrame;
  data: any[];
}

export default function StackedBarChart({
  commanders,
  timeFrame,
  data,
}: StackedBarChartProps) {
  const theme = useTheme();

  const CustomTooltip = ({ active, payload, label }: any) => {
    const polyglot = getPolyglot();
    if (active && payload) {
      const total = payload.reduce(
        (sum: any, entry: { value: any }) => sum + entry.value,
        0
      );
      return (
        <div
          style={{
            backgroundColor: 'white',
            padding: '20px',
            border: '1px solid #ccc',
          }}
        >
          <p>{`${polyglot.t('general.date')}:  ${new Date(
            label
          ).toLocaleDateString()}`}</p>
          <p>
            <b>{`${polyglot.t(
              'composable_device.dashboard_oven.charts.total'
            )}: ${parseSecondsToTime(total)}`}</b>
          </p>
          {payload.map(
            (
              entry: {
                color: any;
                name: any;
                value: any;
                unit: string;
              },
              index: React.Key | null | undefined
            ) => (
              <p key={index} style={{ color: entry.color }}>
                {`${entry.name ?? ''}: ${parseSecondsToTime(entry.value)}`}
              </p>
            )
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <div style={{ width: '100%', height: '400px' }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 50,
            left: 30,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="timestamp"
            tickFormatter={(unixTime) =>
              timeFrame === 'day'
                ? new Date(unixTime).toLocaleTimeString()
                : new Date(unixTime).toLocaleDateString()
            }
            tickCount={data.length + 2}
            interval={'preserveStartEnd'}
          />
          <YAxis tickFormatter={(value) => parseSecondsToTime(value)} />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          {commanders.map((item, index) => (
            <Bar
              key={`bar-${item.name}-${index}`}
              name={item.name ?? ''}
              dataKey={item.uuid ?? ''}
              stackId="a"
              fill={getColorFromPalette(index, theme)}
            >
              {index === commanders.length - 1 ? (
                <LabelList
                  position="top"
                  valueAccessor={(entry: {
                    payload: any & { unit: string }[];
                  }) => {
                    const { payload } = entry;
                    if (typeof payload === 'object' && payload !== null) {
                      const { timestamp, ...rest } = payload;
                      const total: number = Object.values(rest).reduce(
                        (sum: number, value: any) => sum + value,
                        0
                      );
                      return parseSecondsToTime(total);
                    } else {
                      return `0${entry?.payload?.unit ?? 'h'}`;
                    }
                  }}
                />
              ) : null}
            </Bar>
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
