import React from 'react';
import {
  Box,
  Button,
  IconButton,
  Link,
  Skeleton,
  Typography,
  useTheme,
} from '@mui/material';
import { MercIcon } from '../../../../theme/icons';
import useAllMerchandisers from '../../../../redux/composableDevice/hook/useAllMerchandisers';
import { useAppSelector } from '../../../../../../redux/store.model';
import { getAlarmActiveBySelector } from '../../../../../../redux/incident/selectors/getAlarmActiveBySelector';
import { getPolyglot } from '../../../../../../i18n';
import OverviewCard, {
  OverviewCardItem,
} from '../../../../theme/components/OverviewCard/OverviewCard';
import { useParams } from 'react-router-dom';
import { AlarmIcon } from '../../../../../../theme/icons';

export const SummaryPanel = () => {
  const theme = useTheme();
  const params = useParams();
  const { merchandisers, isLoading } = useAllMerchandisers({
    groupId: params.groupId,
  });
  const polyglot = getPolyglot();

  const { data: alarmsActive, isLoading: isLoadingAlarmsActive } =
    useAppSelector((state) =>
      getAlarmActiveBySelector(state, { groupId: params.groupId })
    );

  return (
    <OverviewCard>
      <OverviewCardItem>
        <Button
          href="/devicemanager"
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-evenly',
          }}
          variant={'text'}
        >
          <IconButton disableRipple={true}>
            <MercIcon
              color={`${theme.palette.primary.main}`}
              height={'4.5rem'}
              width={'100%'}
            />
          </IconButton>
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Typography variant="h4" color="common.black">
              {polyglot.t('freshwatch.dashboard.merchandisers')}
            </Typography>
            <Typography
              variant="h6"
              fontSize={'5rem'}
              lineHeight={'5rem'}
              color="common.black"
            >
              {isLoading ? <Skeleton width={80} /> : merchandisers.length}
            </Typography>
          </Box>
        </Button>
      </OverviewCardItem>
      <OverviewCardItem>
        <Button
          href="/alarms"
          variant="text"
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-evenly',
          }}
        >
          <IconButton disableRipple={true}>
            <AlarmIcon
              color={`${theme.palette.error.main}`}
              height={'4.5rem'}
              width={'100%'}
            />
          </IconButton>
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Typography variant="h4" color="common.black">
              {polyglot.t('freshwatch.dashboard.alarms')}
            </Typography>
            <Typography
              variant="h6"
              fontSize={'5rem'}
              lineHeight={'5rem'}
              color="common.black"
            >
              {isLoadingAlarmsActive ? (
                <Skeleton width={80} />
              ) : (
                alarmsActive.length
              )}
            </Typography>
          </Box>
        </Button>
      </OverviewCardItem>
    </OverviewCard>
  );
};

export default SummaryPanel;
