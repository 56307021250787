import React, { ComponentType } from 'react';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  AreaChart as Chart,
  Area,
  Legend,
} from 'recharts';
import { Device } from '../../../redux/devices/api/device.model';
import { useTheme } from '@mui/material';
import { getColorFromPalette } from './util/mock/Utils';
import { ITimeFrame } from './util/mock/getMockDataDashboard';
import { getPolyglot } from '../../../i18n';

interface StackedAreaChartProps {
  commanders: Device[];
  timeFrame: ITimeFrame;
  data: any[];
  unit?: string;
  CustomTooltip?: ComponentType<any>;
}

export default function StackedAreaChart({
  commanders,
  timeFrame,
  data,
  unit = 'kWh',
  CustomTooltip = ({ active, payload, label }: any) => {
    const polyglot = getPolyglot();

    if (active && payload) {
      const total = payload.reduce(
        (sum: any, entry: { value: any }) => sum + entry.value,
        0
      );
      return (
        <div
          style={{
            backgroundColor: 'white',
            padding: '20px',
            border: '1px solid #ccc',
          }}
        >
          <p>{`${polyglot.t('general.date')}: ${
            timeFrame === 'day' || timeFrame === 'hour'
              ? new Date(label).toLocaleString()
              : new Date(label).toLocaleDateString()
          }`}</p>
          <p>
            <b>{`${polyglot.t(
              'composable_device.dashboard_oven.charts.total'
            )}: ${total.toFixed(2)} ${payload.unit ?? unit}`}</b>
          </p>
          {payload.map(
            (
              entry: { color: any; name: any; value: any },
              index: React.Key | null | undefined
            ) => (
              <p key={index} style={{ color: entry.color }}>
                {`${entry.name}: ${entry.value.toFixed(2)} ${
                  payload?.unit ?? unit
                }`}
              </p>
            )
          )}
        </div>
      );
    }
    return null;
  },
}: StackedAreaChartProps) {
  const theme = useTheme();

  return (
    <div style={{ width: '100%', height: '400px' }}>
      <ResponsiveContainer width="100%" height="100%">
        <Chart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 20,
            right: 50,
            left: 30,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="timestamp"
            domain={['dataMin', 'dataMax']}
            scale="time"
            type="number"
            tickFormatter={(unixTime) =>
              timeFrame === 'day'
                ? new Date(unixTime).toLocaleTimeString()
                : new Date(unixTime).toLocaleDateString()
            }
          />
          <YAxis unit={` ${unit}`} />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          {commanders.map((item, index) => (
            <Area
              key={`area-${item.name}-${index}`}
              type="monotone"
              name={item.name ?? ''}
              dataKey={item.uuid ?? ''}
              stackId="1"
              stroke={getColorFromPalette(index, theme)}
              fill={getColorFromPalette(index, theme)}
            ></Area>
          ))}
        </Chart>
      </ResponsiveContainer>
    </div>
  );
}
