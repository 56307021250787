import React from 'react';
import {
  Accordion,
  AccordionSummary,
  Alert,
  List,
  Typography,
} from '@mui/material';
import { ListEntitiesSearchProps } from '../ListEntitiesSearch';
import { useAppSelector } from '../../../../redux/store.model';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { getPolyglot } from '../../../../i18n';
import { ListItem } from '../../../../theme/components';
import GroupModel from '../../../../redux/groups/api/GroupModel';
import { DeviceSoloIcon } from '../../../../theme/icons';
import { getAllDeviceWithChainGroups } from '../../../../redux/devices/selectors/searchSelectors';
import { useNavigate } from 'react-router-dom';
import { Group } from '../../../../redux/groups/api/group.model';
import _ from 'lodash';
import { Device } from '../../../../redux/devices/api/device.model';

function DeviceSearch(props: ListEntitiesSearchProps) {
  const { searchValue } = props;
  const device = useAppSelector(getAllDeviceWithChainGroups);
  const navigate = useNavigate();
  const deviceResults = device.filter(
    (device) =>
      device.name &&
      searchValue &&
      searchValue?.length &&
      device.name.toLowerCase().includes(searchValue?.toLowerCase())
  );

  const polyglot = getPolyglot();

  const onClick = (device: { chainGroups: Group[] } & Device) => () => {
    const groupId = _.first(device.chainGroups)?.id;
    let url = `/devicemanager/device/${device.uuid}/device-detail`;
    if (groupId) {
      url = `/devicemanager/device/${device.uuid}/group/${groupId}/device-detail`;
    }
    navigate(url);
    close?.();
  };

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary
        id="accordion-search-groups-title"
        aria-controls="accordion-search-groups-title"
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography variant="h5">
          {polyglot.t('group.devices.devices')}
        </Typography>
      </AccordionSummary>
      {deviceResults.length ? (
        <List>
          {deviceResults.map((device, i) => (
            <ListItem
              key={i}
              name={device?.name}
              iconURL={device?.iconURL}
              subtitle={GroupModel.getGroupChainsSubtitles(device?.chainGroups)}
              iconNode={!device?.iconURL && <DeviceSoloIcon />}
              onClick={onClick(device)}
              ItemTextContainerProps={{
                sx: (theme) => ({
                  maxWidth: 'initial',
                  textAlign: 'left',
                  [theme.breakpoints.down('md')]: {
                    maxWidth: 'initial',
                  },
                }),
              }}
              TitleProps={{
                sx: (theme) => ({
                  maxWidth: '500px',
                  [theme.breakpoints.down('md')]: {
                    maxWidth: '500px',
                  },
                }),
              }}
            />
          ))}
        </List>
      ) : (
        <Alert severity="info">
          {polyglot.t('device.search.message_no_results_found')}
        </Alert>
      )}
    </Accordion>
  );
}

export default React.memo(DeviceSearch);
