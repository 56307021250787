import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetReportsQuery } from '../../../../../redux/reports/api/ReportsGenerationAPI';
import { CircularProgress, Grid, Stack } from '@mui/material';
import Breadcrumb from '../../../../../../../theme/components/Breadcrumb/Breadcrumb';
import { getPolyglot } from '../../../../../../../i18n';
import { useGroupById } from '../../../../../../../redux/groups/hooks/useGroupById';
import FilterPanel from '../../Panels/FilterPanel';
import { useDashboardInformation } from '../../../../../redux/dashboardFilters/hooks/useDashboardFilters';
import { reportsMock } from '../../../../../redux/reports/reports.mock';
import ReportsTable from '../../../../../theme/components/ReportsTable/ReportsTable';

const ReportsDetailPage = () => {
  const { cityId } = useParams();
  const [city] = useGroupById(cityId);
  if (!cityId) return null;

  const { regions, stores, merchandisers } = useDashboardInformation();

  const polyglot = getPolyglot();
  const navigate = useNavigate();

  const navigation = [
    {
      content: polyglot.t('nav.menu.dashboard'),
      onClick: () => navigate('/dashboard'),
      href: '/dashboard',
      key: 'dashboard',
    },
    {
      content: polyglot.t('reports.reports'),
      onClick: () => navigate('/dashboard/reports'),
      href: '/dashboard/reports',
      key: 'reports',
    },
    {
      content: city?.name,
      onClick: () => navigate(`detail/${cityId}`),
      href: `detail/${cityId}`,
      key: `detail/${cityId}`,
    },
  ];
  const { data: reports = [], isLoading } = useGetReportsQuery(cityId);

  let content = null;

  if (isLoading) {
    content = (
      <Stack textAlign={'center'} marginTop={'1rem'}>
        <CircularProgress />
      </Stack>
    );
  }

  if (reports.length) {
    content = (
      <Stack textAlign={'center'} marginTop={'1rem'}>
        {polyglot.t('reports.reports_not_found')}
      </Stack>
    );
  } else {
    content = <ReportsTable reports={reports} />;
  }
  // TODO Remove me when API is ready
  content = <ReportsTable reports={reportsMock} />;
  return (
    <>
      <Grid sx={{ backgroundColor: 'common.white' }}>
        <FilterPanel
          regions={regions}
          stores={stores}
          merchandisers={merchandisers}
        />
      </Grid>
      <Grid
        container
        sx={{
          backgroundColor: 'neutral.light',
          padding: '1rem 2rem 0 2rem',
        }}
      >
        <Breadcrumb sections={navigation} />
        <Grid item xs={12}>
          {content}
        </Grid>
      </Grid>
    </>
  );
};

export default ReportsDetailPage;
