import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Box,
  CircularProgress,
  Typography,
  Grid,
  Alert,
  Divider,
} from '@mui/material';
import Util, { getValueLocalStore } from '../../../util/Util';
import BrowserUtil from '../../../util/BrowserUtil';
import SortUtil from '../../../util/SortUtil';
import { PolyglotComponentProps, withPolyglot } from '../../../i18n';
import {
  RightsUserUtilComponentProps,
  withUserRightUtil,
} from '../../../util/rights';
import {
  HandlingErrorWrappedProps,
  withHandlingErrors,
} from '../../../handlingErrors';

import { warmUpGroups } from '../../../redux/groups/actions/thunks';
import {
  getGroups,
  getGroupSelectedByQueryParamSelector,
  isGroupsLoading,
} from '../../../redux/groups/selectors';
import { isAssociatedDevicesLoading } from '../../../redux/devices/selectors';
import { RootState } from '../../../redux/store.model';
import { RouterComponentProps, withRouter } from '../../../util/route-dom';
import AddButtonDeviceManager from '../AddButtonDeviceManager';
import {
  SORT_FIELD,
  SORT_ORDER,
  TopBarMenu,
  VIEW_TYPE,
} from '../../../theme/components';
import MapDevices from './Overview/MapDevices/MapDevices';
import { getDevicesNotRestrictedByQueryParamSelector } from '../../../redux/stoerkID/selectors/StoerkId.selectors';
import { CheckCircleOutline } from '@mui/icons-material';
import ListEntities from '../ListEntities';

const browserUtil = new BrowserUtil();
const util = new Util();
const showViewGroupsAndDevicesKey = util.getShowViewGroupsAndDevicesKey();
const sortGroupsDevicesFieldKey = util.getSortGroupsDeviceFieldKey();
const sortGroupsDevicesDirectiondKey = util.getSortGroupsDeviceDirectionKey();

type Props = ConnectedComponentProps &
  HandlingErrorWrappedProps &
  PolyglotComponentProps &
  RightsUserUtilComponentProps &
  RouterComponentProps<{ groupId: string }>;

interface State {
  view?: VIEW_TYPE;
  sortDirection?: SORT_ORDER;
  sortField?: SORT_FIELD;
  showMessageNoDeviceNoGroupsNoRights: boolean;
  message?: string | null;
  routesGroupsDeviceAlreadyAdded: string[];
}

/**
 * Class group manager
 * this class is responsible for managing the hierarchy of groups and associated devices
 */
export class GroupManager extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.changeView = this.changeView.bind(this);
    this.loadData = this.loadData.bind(this);
    this.loadButtonsToDisplay = this.loadButtonsToDisplay.bind(this);
    this.sort = this.sort.bind(this);

    /* get the sort  used by  the user in the last session */
    const sortField =
      (getValueLocalStore(sortGroupsDevicesFieldKey) as SORT_FIELD) ??
      SORT_FIELD.NAME;

    /* get the sort direction used by the users in the last session
     */
    const sortDirection =
      (getValueLocalStore(sortGroupsDevicesDirectiondKey) as SORT_ORDER) ??
      SORT_ORDER.ASC;

    /* get the view that has used the user */
    const view =
      (getValueLocalStore(showViewGroupsAndDevicesKey) as State['view']) ??
      VIEW_TYPE.LIST;

    this.state = {
      view,
      sortDirection,
      sortField,
      showMessageNoDeviceNoGroupsNoRights: false,
      message: null,
      routesGroupsDeviceAlreadyAdded: [],
    };
  }

  async componentDidMount() {
    const { activatedLoadingGroupsDevices } = this.props;
    if (!activatedLoadingGroupsDevices) {
      await this.loadData();
    }
  }

  /**
   * Load buttons to display
   */
  loadButtonsToDisplay() {
    const { params, rightsUserUtil } = this.props;
    const { groupId } = params;
    let showButtonAddDevice = false;
    let showButtonAddGroup = false;
    if (!groupId) {
      /* If the user are in the high group level we should check in the masterScope if
      the user has the right Authorized User */
      showButtonAddGroup = rightsUserUtil.isAuthorizedUser();
      showButtonAddDevice = showButtonAddGroup;
    } else {
      showButtonAddDevice =
        rightsUserUtil.hasRightsToAssignGroupDevice(groupId);
      showButtonAddGroup = rightsUserUtil.hasRightsToUpdateGroup(groupId);
    }
    return { showButtonAddDevice, showButtonAddGroup };
  }

  /**
   * Load data: this function calls the backend to get the data from groups
   * devices. only when the call are done, the page will be rendered
   */
  async loadData() {
    try {
      this.setState({
        showMessageNoDeviceNoGroupsNoRights: true,
      });
    } catch (error) {
      const { handlingErrorsApi } = this.props;
      handlingErrorsApi(error);
    }
  }

  /**
   * Change view
   * this function is called when the view icons are click
   * @param object event
   * @param string view
   */
  changeView(event: unknown, view: VIEW_TYPE) {
    const { navigate, location } = this.props;
    /* the selected view, will be saved into the localstore */
    localStorage?.setItem(showViewGroupsAndDevicesKey, view);
    this.setState({ view });
    if (view === VIEW_TYPE.MAP && !this.isMap()) {
      return navigate('./map');
    }
    if (this.isMap()) return navigate('../');
  }

  /**
   * Sort field
   * This funciton set the state variables sortField and sortDirections
   * @param string sortField
   */
  sort(sortField: SORT_FIELD, order: SORT_ORDER) {
    /* the selected sort field and sort direction, will be saved into the localstore */
    localStorage?.setItem(sortGroupsDevicesFieldKey, sortField);
    localStorage?.setItem(sortGroupsDevicesDirectiondKey, order);

    this.setState({
      sortField,
      sortDirection: order,
    });
  }

  isMap = () => {
    const { location } = this.props;
    return location.pathname.endsWith('/map');
  };

  render() {
    const {
      sortField,
      sortDirection,
      showMessageNoDeviceNoGroupsNoRights,
      message,
      routesGroupsDeviceAlreadyAdded,
    } = this.state;
    let { view = VIEW_TYPE.LIST } = this.state;
    const { params, loadingGroups, loadingDevices, featureToggle, polyglot } =
      this.props;
    const { groups, group, devices } = this.props;
    const { groupId } = params;

    if (view === VIEW_TYPE.MAP && !this.isMap()) {
      view = VIEW_TYPE.LIST;
    } else if (this.isMap() && view !== VIEW_TYPE.MAP) {
      view = VIEW_TYPE.MAP;
    }

    const { showButtonAddDevice, showButtonAddGroup } =
      this.loadButtonsToDisplay();

    const showAddButton = !!showButtonAddGroup || !!showButtonAddDevice;

    /* if the groupId is not defined, it means that it is showing the groups from the first level */
    let groupsResults = !groupId ? groups : group?.childGroups || [];
    groupsResults = !groupsResults ? [] : groupsResults;

    let devicesResults = devices;

    /* Sort */
    if (sortField !== undefined && sortDirection !== undefined) {
      devicesResults =
        devicesResults.length > 0
          ? SortUtil.multisort(devicesResults, [sortField], [sortDirection])
          : [];
      groupsResults =
        groupsResults.length > 0
          ? SortUtil.multisort(groupsResults, [sortField], [sortDirection])
          : [];
    }
    let content = (
      <ListEntities
        groupsResults={groupsResults}
        devicesResults={devicesResults}
        view={view}
        groupId={groupId}
        group={group}
      />
    );

    if (this.isMap() && featureToggle.map) {
      content = <MapDevices />;
    }

    return (
      <div>
        <TopBarMenu
          showSearch
          showViewMap={featureToggle.map}
          selectedView={view}
          changeView={(newValue) => this.changeView({}, newValue)}
          sortField={sortField}
          sortOrder={sortDirection}
          onChangeSort={this.sort}
          menuAddButton={
            <AddButtonDeviceManager group={group} groupId={groupId} />
          }
        />
        {(message || routesGroupsDeviceAlreadyAdded.length > 0) && (
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <Alert
                icon={<CheckCircleOutline fontSize="inherit" />}
                severity="warning"
              >
                {message}
                <ul>
                  {routesGroupsDeviceAlreadyAdded.map((route, i) => (
                    <Typography key={i} variant="body2" component={'li'}>
                      {route}
                    </Typography>
                  ))}
                </ul>
              </Alert>
            </Grid>
          </Grid>
        )}
        {content}
        {/* Snackbar: brief feedback about an operation through a message at the
          bottom of the screen. */}
        <Box marginTop={2}>
          {(loadingGroups || loadingDevices) && (
            <Grid
              container
              justifyContent="center"
              className="mt-1"
              alignItems="center"
            >
              <Grid item xs={12}>
                <CircularProgress />
                {polyglot.t('group.loading_data_message')}
              </Grid>
            </Grid>
          )}
        </Box>

        {!loadingGroups &&
          !loadingDevices &&
          showMessageNoDeviceNoGroupsNoRights &&
          !showAddButton &&
          devicesResults.length === 0 &&
          groupsResults.length === 0 && (
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                {polyglot.t('group.no_device_no_groups_and_no_rights')}
              </Grid>
            </Grid>
          )}
        {browserUtil.getIsPhone() &&
          !loadingGroups &&
          !loadingDevices &&
          showAddButton &&
          devicesResults.length === 0 &&
          groupsResults.length === 0 && (
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                {polyglot.t('device.add_no_devices_message')}
              </Grid>
            </Grid>
          )}
      </div>
    );
  }
}

const mapStateToProps = (
  state: RootState,
  props: RouterComponentProps<{ groupId?: string | undefined }>
) => ({
  groups: getGroups(state),
  group: getGroupSelectedByQueryParamSelector(state, props),
  devices: getDevicesNotRestrictedByQueryParamSelector(state, props),
  featureToggle: state.featureToggle,
  loadingGroups: isGroupsLoading(state),
  loadingDevices: isAssociatedDevicesLoading(state),
  activatedLoadingGroupsDevices: !!state.groups.activatedLoadingGroupsDevices,
});

const connector = connect(mapStateToProps, {
  warmUpGroups,
});
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default withHandlingErrors(
  withRouter(withPolyglot(withUserRightUtil(connector(GroupManager))))
);
