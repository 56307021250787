import { ToggleComponent } from '../../../featureToggle';

export { default as AddPictureIcon } from './AddPicture.svg?react';
import { default as AlarmIconDefault } from './alarm.svg?react';
import { default as AlarmIconFreshWatch } from '../../../customized/fresh-watch/theme/icons/assets/alarm.svg?react';

export { default as AlarmRedCercleIcon } from './AlarmRedCercle.svg?react';
export { default as AlphabetIcon } from './alphabet.svg?react';
export { default as App1Icon } from './App1.svg?react';
export { default as App2Icon } from './App2.svg?react';
export { default as ArrowBackCircleIcon } from './arrow_back_circle.svg?react';
export { default as ArrowRightCircleIcon } from './arrow-right.svg?react';
export { default as CancelSentRequestIcon } from './CancelSentRequest.svg?react';
export { default as ChartsIcon } from './charts.svg?react';
export { default as CheckGreenCercleIcon } from './CheckGreenCercle.svg?react';
export { default as CheckCercleIcon } from './CheckCercle.svg?react';
export { default as CloudConnectLogo1Icon } from './CloudConnect_logo1.svg?react';
export { default as CloudConnectLogo2Icon } from './CloudConnect_logo2.svg?react';
export { default as CloudDisconnectedIcon } from './CloudDisconnected.svg?react';
export { default as CloudOnIcon } from './CloudON.svg?react';
export { default as CloudUnavailableIcon } from './CloudUnavailable.svg?react';
export { default as CloudUpdatingIcon } from './CloudUpdating.svg?react';
export { default as ConnectFavIcon } from './ConnectFav.svg?react';
export { default as ContactListIcon } from './ContactList.svg?react';
export { default as ContactMailIcon } from './ContactMail.svg?react';
export { default as ContactPhoneIcon } from './ContactPhone.svg?react';
export { default as ContactProfilIcon } from './ContactProfil.svg?react';
export { default as DashboardIcon } from './dashboard.svg?react';
export { default as DeleteIcon } from './delete.svg?react';
export { default as DetailsIcon } from './details.svg?react';
export { default as DeviceGroupIcon } from './device group.svg?react';
export { default as DeviceSoloIcon } from './device solo.svg?react';
export { default as DownloadIcon } from './Download.svg?react';
export { default as EditIcon } from './edit.svg?react';
export { default as EmailIcon } from './Email.svg?react';
export { default as ErrorNotFoundIcon } from './error_1.svg?react';
export { default as ErrorPageBrokenIcon } from './error_2.svg?react';
export { default as FilterListIcon } from './filter list.svg?react';
export { default as GridIcon } from './grid.svg?react';
export { default as HomeIcon } from './home.svg?react';
export { default as InfosIcon } from './infos.svg?react';
export { default as InvoiceIcon } from './Invoice.svg?react';
export { default as ListIcon } from './list.svg?react';
export { default as LoupeIcon } from './loupe.svg?react';
export { default as NotificationsIcon } from './notifications.svg?react';
export { default as PaymentAcceptIcon } from './PaymentAccept.svg?react';
export { default as PaymentOkIcon } from './payment_ok.svg?react';
export { default as PendingIcon } from './Pending.svg?react';
export { default as PinIcon } from './pin.svg?react';
export { default as PlusIcon } from './plus.svg?react';
export { default as PrintIcon } from './Print.svg?react';
export { default as QuotaIcon } from './quota.svg?react';
export { default as ReglerIcon } from './Regler.svg?react';
export { default as RingIcon } from './ring.svg?react';
export { default as SLogoIcon } from './S-logo.svg?react';
export { default as SearchIcon } from './search.svg?react';
export { default as SettingsIcon } from './settings.svg?react';
export { default as ShareIcon } from './Share.svg?react';
export { default as StorkBuildingIcon } from './StorkBuilding.svg?react';
export { default as StorkLogoBlueIcon } from './StorkLogo-blue.svg?react';
export { default as StorkLogoWhiteIcon } from './StorkLogo-white.svg?react';
export { default as SupplierBuildingIcon } from './SupplierBuilding.svg?react';
export { default as TrashIcon } from './trash.svg?react';
export { default as UserIconIcon } from './user-icon.svg?react';
export { default as UserIcon } from './user.svg?react';
export { default as UserInterfaceIcon } from './UserInterface.svg?react';
export { default as ViewIcon } from './View.svg?react';
export { default as GridSmallIcon } from './window.svg?react'; // renamed to avoid confusion

// composable devices
export { default as OvenIcon } from './ComposableDevice/Oven/oven.svg?react';
export { default as PowerIcon } from './ComposableDevice/Oven/power.svg?react';
export { default as LightIcon } from './ComposableDevice/Oven/light.svg?react';
export { default as FanIcon } from './ComposableDevice/Oven/fan.svg?react';
export { default as ClapetIcon } from './ComposableDevice/Oven/clapet.svg?react';
export { default as BurnerIcon } from './ComposableDevice/Oven/burner.svg?react';
export { default as TurboIcon } from './ComposableDevice/Oven/turbo.svg?react';
export { default as SteamIcon } from './ComposableDevice/Oven/steam.svg?react';
export { default as TopTemperatureIcon } from './ComposableDevice/Oven/topTemp.svg?react';
export { default as BottomTemperatureIcon } from './ComposableDevice/Oven/bottomTemp.svg?react';
export { default as FryerIcon } from './ComposableDevice/Fryer/fryer.svg?react';
export { default as OilTemperatureIcon } from './ComposableDevice/Fryer/OilTemp.svg?react';
export { default as WallTemperatureIcon } from './ComposableDevice/Fryer/WallTemp.svg?react';

// Units
export * from './Unit';
// Device UI
export * from './Device';
// ActionButtonIcons
export * from './ActionButtonIcons';
// Dashboard
export * from './Dashboard';

export const AlarmIcon = ToggleComponent(AlarmIconDefault, {
  styledComponentsVariants: {
    'fresh-watch': AlarmIconFreshWatch,
  },
});
