import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
} from '@mui/material';
import React from 'react';
import { getPolyglot } from '../../../../../../i18n';
import {
  StyledHeaderRow,
  StyledRoundedRow,
} from '../../StyledTable/StyledTable';
import { RegionDetails } from '../../../../redux/composableDevice/model/SCCGroup.model';

const StoreOverviewTable = (props: {
  storeWithMerchandisers: Map<string, RegionDetails>;
}) => {
  const { storeWithMerchandisers } = props;
  const polyglot = getPolyglot();

  return (
    <TableContainer
      component={Paper}
      sx={{
        background: 'transparent',
        border: 'none',
      }}
    >
      <Table
        sx={{
          borderCollapse: 'separate',
          borderSpacing: '0 0.2rem',
        }}
        aria-label="Store Overview"
      >
        <TableHead sx={{ bgcolor: 'neutral.dark' }}>
          <StyledHeaderRow>
            <TableCell
              sx={{
                padding: '0 0 0 2rem',
                width: '50%',
                textAlign: 'start!important',
                fontWeight: 'bolder',
              }}
            >
              {polyglot.t('freshwatch.dashboard.detail.location_store')}
            </TableCell>
            <TableCell
              sx={{ width: '25%', fontWeight: 'bolder', padding: '0.5rem' }}
            >
              {polyglot.t('freshwatch.dashboard.merchandisers')}
            </TableCell>
            <TableCell
              sx={{ width: '25%', fontWeight: 'bolder', padding: '0.5rem' }}
            >
              {polyglot.t('freshwatch.dashboard.alarms')}
            </TableCell>
          </StyledHeaderRow>
        </TableHead>
        <TableBody>
          {[...storeWithMerchandisers.keys()].map((storeId: string) => {
            const city = storeWithMerchandisers.get(storeId);
            if (!city) return null;
            const merchandisers = city.citiesDetails
              .map((city) => city.merchandisers)
              .flat();
            const alarms = city.citiesDetails.map((city) => city.alarms).flat();

            return (
              <StyledRoundedRow
                key={storeId}
                sx={{ boxShadow: 2 }}
                error={alarms.length > 0}
              >
                <TableCell
                  sx={{
                    bgcolor: 'primary.main',
                    color: 'primary.contrastText',
                    textAlign: 'start!important',
                    fontWeight: 'bold',
                    pl: 2,
                  }}
                >
                  {city.city.name}
                </TableCell>
                <TableCell sx={{ fontWeight: 'normal' }}>
                  {merchandisers.length}
                </TableCell>
                <TableCell
                  sx={{
                    color: alarms.length > 0 ? 'error' : 'common.black',
                    fontWeight: 'normal',
                  }}
                >
                  {alarms.length}
                </TableCell>
              </StyledRoundedRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default StoreOverviewTable;
