import React from 'react';
import { MouseEvent, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Box, Grid, Typography, CardProps, Button } from '@mui/material';

import MainCard from '../ui-component/cards/MainCard';
import SkeletonWidgetCard from '../ui-component/cards/Skeleton/SkeletonWidgetCard';
import { getPolyglot } from '../../../i18n';

interface CardWrapperProps extends CardProps {
  border: boolean;
  content: string;
}

const CardWrapper = styled(MainCard, {
  shouldForwardProp: (prop: string) => prop !== 'border' && prop !== 'content',
})<CardWrapperProps>(({ theme, border, content }) => ({
  backgroundColor: theme.palette.primary.light,
  color: '#fff',
  overflow: 'hidden',
  position: 'relative',
  border: border ? '1px solid #ccc' : 'none', // Example border styling
  padding: content ? '20px' : '0', // Example content padding
  '&:after': {
    content: '""',
    position: 'absolute',
    width: 210,
    height: 210,
    background: theme.palette.primary.dark,
    borderRadius: '50%',
    top: -85,
    right: -115,
    [theme.breakpoints.down('sm')]: {
      top: -105,
      right: -140,
    },
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    width: 210,
    height: 210,
    background: theme.palette.primary.main,
    borderRadius: '50%',
    top: -125,
    right: -15,
    opacity: 0.5,
    [theme.breakpoints.down('sm')]: {
      top: -155,
      right: -70,
    },
  },
}));

interface WidgetCardProps {
  isLoading: boolean;
  value: string[];
  text: string;
}

const WidgetCard = ({ isLoading, value, text }: WidgetCardProps) => {
  const polyglot = getPolyglot();
  const theme = useTheme();

  const [timeValue, setTimeValue] = useState(2);
  const handleChangeTime = (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    newValue: number | ((prevState: number) => number)
  ) => {
    setTimeValue(newValue);
  };

  return (
    <>
      {isLoading ? (
        <SkeletonWidgetCard />
      ) : (
        <CardWrapper border={false} content={''}>
          <Box>
            <Grid container direction="column">
              <Grid item>
                <Grid container justifyContent="space-between">
                  <Grid item sx={{ zIndex: 2 }}>
                    <Button
                      disableElevation
                      variant={timeValue === 0 ? 'contained' : 'text'}
                      size="small"
                      sx={{ color: 'inherit' }}
                      onClick={(e) => handleChangeTime(e, 0)}
                    >
                      {polyglot.t('general.hour')}
                    </Button>
                    <Button
                      disableElevation
                      variant={timeValue === 1 ? 'contained' : 'text'}
                      size="small"
                      sx={{ color: 'inherit' }}
                      onClick={(e) => handleChangeTime(e, 1)}
                    >
                      {polyglot.t('general.today')}
                    </Button>
                    <Button
                      disableElevation
                      variant={timeValue === 2 ? 'contained' : 'text'}
                      size="small"
                      sx={{ color: 'inherit' }}
                      onClick={(e) => handleChangeTime(e, 2)}
                    >
                      {polyglot.t(
                        'composable_device.dashboard_oven.charts.last_week'
                      )}
                    </Button>
                    <Button
                      disableElevation
                      variant={timeValue === 3 ? 'contained' : 'text'}
                      size="small"
                      sx={{ color: 'inherit' }}
                      onClick={(e) => handleChangeTime(e, 3)}
                    >
                      {polyglot.t(
                        'composable_device.dashboard_oven.charts.last_month'
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sx={{ mx: 0.5 }}>
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography
                      color={theme.palette.common.white}
                      sx={{
                        fontSize: 'xxx-large',
                        fontWeight: 500,
                        mr: 1,
                        py: 0.2,
                      }}
                    >
                      {value[timeValue]}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item sx={{ mb: 0.25 }}>
                  <Typography
                    variant="body2"
                    color={theme.palette.common.white}
                    sx={{
                      fontSize: '1rem',
                      fontWeight: 500,
                    }}
                  >
                    {text}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </CardWrapper>
      )}
    </>
  );
};

export default WidgetCard;
