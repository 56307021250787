import { Alert, List } from '@mui/material';
import React from 'react';
import { getPolyglot } from '../../../i18n';
import GroupsSearch from './GroupsSearch/GroupsSearch';
import DevicesSearch from './DevicesSearch';

export interface ListEntitiesSearchProps {
  searchValue?: string;
  close?: () => void;
}

function ListEntitiesSearch(props: ListEntitiesSearchProps) {
  const { searchValue } = props;
  const polyglot = getPolyglot();
  if (!searchValue || !searchValue?.length) {
    return (
      <Alert severity="info">
        {polyglot.t('device.search.message_type_to_search')}
      </Alert>
    );
  }
  return (
    <List>
      <GroupsSearch {...props} />
      <DevicesSearch {...props} />
    </List>
  );
}

export default ListEntitiesSearch;
