import { IncidentAPIResponseMVP } from '../../../../../../../redux/incident/api/Incident.model';
import { getPolyglot } from '../../../../../../../i18n';
import { useMemo } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import AlarmCard, { SEVERITY } from './AlarmCard';
import React from 'react';
import AlarmTable from '../../../../../theme/components/AlarmTable/AlarmTable';
import { RegionDetails } from '../../../../../redux/composableDevice/model/SCCGroup.model';
import { AlarmIcon } from '../../../../../../../theme/icons';
interface AlarmsOverviewProps {
  gridDisplay: boolean;
  alarmsActive: IncidentAPIResponseMVP[];
  cities: Map<string, RegionDetails>;
}
function AlarmOverview(props: AlarmsOverviewProps) {
  const { gridDisplay, alarmsActive, cities } = props;
  const theme = useTheme();
  const polyglot = getPolyglot();
  const citiesArray = Array.from(cities.values());
  const alarms = useMemo(
    () =>
      alarmsActive.map((alarm) => {
        const alarmCity = citiesArray.find((city) =>
          city.citiesDetails.some((city) =>
            city.alarms.some((cityAlarms) => cityAlarms.uuid === alarm.uuid)
          )
        );
        const alarmStore = alarmCity?.citiesDetails.find((city) =>
          city.alarms.some((cityAlarms) => cityAlarms.uuid === alarm.uuid)
        );
        const alarmMerchandiser = alarmStore?.merchandisers.find(
          (merchandiser) =>
            merchandiser.devices.some(
              (device) => atob(device) === alarm.deviceId
            )
        );
        return {
          title: `${alarmCity?.city.name} - ${alarmStore?.store.name}`,
          storeName: alarmStore?.store.name ?? '',
          productName: alarmMerchandiser?.name ?? '',
          description: alarm.eventTranslation?.longText ?? '',
          timestamp: alarm.timestamp,
          severity: SEVERITY.HIGH,
          uuid: alarm.uuid,
          task: '',
        };
      }),
    [alarmsActive]
  );

  const headerTitles = [
    polyglot.t('freshwatch.dashboard.detail.location_store'),
    polyglot.t('freshwatch.dashboard.detail.product_name'),
    polyglot.t('freshwatch.dashboard.detail.description'),
    polyglot.t('freshwatch.dashboard.detail.created_at'),
  ];

  const content = gridDisplay ? (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      {alarms.length ? (
        alarms.map((alarm) => <AlarmCard key={alarm.title} {...alarm} />)
      ) : (
        <Typography>
          {polyglot.t('device.search.message_no_results_found')}
        </Typography>
      )}
    </Box>
  ) : (
    <AlarmTable data={alarms} headerTitles={headerTitles} displayTask={false} />
  );

  return (
    <>
      <Grid item xs={12}>
        <Box display={'flex'} alignItems={'center'} gap="1rem" padding={'1rem'}>
          <AlarmIcon fontSize="1.5rem" color={theme.palette.common.black} />
          <Typography color="common.black" fontWeight="600">
            {polyglot.t('freshwatch.dashboard.alarms')}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {content}
      </Grid>
    </>
  );
}

export default AlarmOverview;
