import styled from '@emotion/styled';
import { Box, BoxProps } from '@mui/material';
import React from 'react';

const OverviewCard = (props: BoxProps) => {
  const { ...rest } = props;
  return (
    <StyledWrapper {...rest}>
      <StyledContent>{props.children}</StyledContent>
    </StyledWrapper>
  );
};

const StyledWrapper = styled(Box)(
  ({ theme }) => `
        margin: 0 auto;
        padding: 1rem 0;
        background: ${theme.palette.background.paper};
        border-radius: 1.5rem;
        width: 100%;
    `
);

const StyledContent = styled(Box)`
      display: flex;
      justify-content: space-around;
      margin: auto;
      padding: 0 1rem;
      flex-wrap: wrap;    
      width: 100%;  
    }
    
    `;
export const OverviewCardItem = styled(Box)(
  ({ theme }) => `
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1 1 auto;
      &:not(:last-child) {
        border-right: 1px solid ${theme.palette.neutral.dark};
      }
      `
);
export default OverviewCard;
