import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import React from 'react';
import { createTheme } from '@mui/material/styles';
import {
  componentsOverrides,
  defaultThemeOptions,
  overrideGlobalStyles,
} from '../../../theme/ThemeProviderWithTheme';

let themeOptions = defaultThemeOptions;
themeOptions = {
  ...themeOptions,
  palette: {
    primary: {
      main: '#2185d0',
    },
    secondary: {
      // Nav color
      main: '#6f6f6fbf',
    },
  },
  components: componentsOverrides,
};
export const theme = createTheme(themeOptions);

export default function ThemeProviderWithTheme({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <StyledEngineProvider injectFirst>
      {overrideGlobalStyles}
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
}
