import React from 'react';
import { useMemo } from 'react';
import { Alert, Box, CircularProgress, Grid } from '@mui/material';
import WidgetCard from './WidgetCard';
import AreaChartCard from './AreaChartCard';
import BarChartCard from './BarChartCard';
import SmallWidgetCard from './SmallWidgetCard';
import { useParams } from 'react-router-dom';
import { useDevices } from '../../../redux/devices/hooks/useDevices';
import { getPolyglot } from '../../../i18n';
import { useParseErrorMessage } from '../../../handlingErrors';
import { useControlUnitsByIds } from '../../../redux/controlUnit/hooks/useControlUnitsByIds';
import _ from 'lodash';
import { useGroupById } from '../../../redux/groups/hooks/useGroupById';
import useGetConsumptionSummary from './hook/useGetConsumptionSummary';
import useWatchError from '../../../handlingErrors/useWatchError';
import PriceKWAreaChartCard from './PriceKWAreaChartCard';

export default function ComposableDashboardPage() {
  const params = useParams<{ groupId?: string }>();
  const polyglot = getPolyglot();
  const [group] = useGroupById(params.groupId ?? '');
  const composableDevice = group?.composableDevice;
  const parseError = useParseErrorMessage();
  const items = useMemo(() => group?.devices ?? [], [group?.devices]);

  const [
    devicesControlUnitsSource,
    devicesControlUnitsLoading,
    devicesControlUnitsError,
  ] = useControlUnitsByIds();
  useWatchError(devicesControlUnitsError);

  const [commanders, loadingDevices] = useDevices(items);
  const skip = Boolean(
    !composableDevice ||
      !commanders.length ||
      !devicesControlUnitsSource?.length ||
      loadingDevices ||
      devicesControlUnitsLoading
  );

  const { consumptionSummary, error, isFetching } = useGetConsumptionSummary(
    commanders,
    group,
    devicesControlUnitsSource,
    skip
  );

  return devicesControlUnitsLoading || loadingDevices ? (
    <Box textAlign={'center'} margin={2}>
      <CircularProgress />
    </Box>
  ) : (
    <Grid container spacing={2}>
      {!isFetching && error ? (
        <Alert severity="error">{parseError(error)}</Alert>
      ) : null}
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <WidgetCard
              isLoading={isFetching}
              value={[
                consumptionSummary.hour.min ?? '',
                consumptionSummary.day.min ?? '',
                consumptionSummary.week.min ?? '',
                consumptionSummary.month.min ?? '',
              ]}
              text={polyglot.t(
                'composable_device.dashboard_oven.min_consumption'
              )}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <WidgetCard
              isLoading={isFetching}
              value={[
                consumptionSummary.hour.max ?? '',
                consumptionSummary.day.max ?? '',
                consumptionSummary.week.max ?? '',
                consumptionSummary.month.max ?? '',
              ]}
              text={polyglot.t(
                'composable_device.dashboard_oven.max_consumption'
              )}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <WidgetCard
              isLoading={isFetching}
              value={[
                consumptionSummary.hour.avg ?? '',
                consumptionSummary.day.avg ?? '',
                consumptionSummary.week.avg ?? '',
                consumptionSummary.month.avg ?? '',
              ]}
              text={polyglot.t(
                'composable_device.dashboard_oven.average_consumption'
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12} gap={2}>
        <Grid item container spacing={2}>
          <Grid item xs={12} md={8}>
            <PriceKWAreaChartCard
              commanders={commanders}
              group={group}
              devicesControlUnitsSource={devicesControlUnitsSource}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={1.5}>
              <Grid item xs={12}>
                <WidgetCard
                  isLoading={isFetching}
                  value={[
                    consumptionSummary.hour.sum_pkwh ?? '',
                    consumptionSummary.day.sum_pkwh ?? '',
                    consumptionSummary.week.sum_pkwh ?? '',
                    consumptionSummary.month.sum_pkwh ?? '',
                  ]}
                  text={polyglot.t(
                    'composable_device.dashboard_oven.total_consumption_price'
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <SmallWidgetCard
                  isLoading={isFetching}
                  text={polyglot.t(
                    'composable_device.dashboard_oven.current_consumption'
                  )}
                  value={consumptionSummary.hour.min}
                />
              </Grid>
              <Grid item xs={12}>
                <SmallWidgetCard
                  isLoading={isFetching}
                  text={polyglot.t(
                    'composable_device.dashboard_oven.current_active_alarms'
                  )}
                  value={'0'}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} md={8}>
            <BarChartCard
              group={group}
              commanders={commanders}
              devicesControlUnitsSource={devicesControlUnitsSource}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Grid container spacing={1.5}>
              <Grid item xs={12}>
                <SmallWidgetCard
                  isLoading={isFetching}
                  text={polyglot.t(
                    'composable_device.dashboard_oven.current_active_decks'
                  )}
                  value={'2'}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* <Grid item xs={12} md={4}>
            <Grid container spacing={1.5}>
              <Grid item xs={12}>
                <SmallWidgetCard
                  isLoading={isLoading}
                  text={polyglot.t(
                    'composable_device.dashboard_oven.current_working_time'
                  )}
                  value={'6h'}
                />
              </Grid>
                     <Grid item xs={12}>
                <SmallWidgetCard
                  isLoading={isLoading}
                  text={polyglot.t(
                    'composable_device.dashboard_oven.consumption_to_heat_up'
                  )}
                  value={widgetConsumptionSummary.heatUp}
                />
              </Grid>
              <Grid item xs={12}>
                <SmallWidgetCard
                  isLoading={isLoading}
                  text={polyglot.t(
                    'composable_device.dashboard_oven.number_recipe_active'
                  )}
                  value={'22'}
                />
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
        {/* <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <HorizontalBarChartCard
              isLoading={isLoading}
              commanders={commanders}
              composableDevice={composableDevice}
              devicesControlUnitsSource={devicesControlUnitsSource}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={1.5}>
              <Grid item xs={12}>
                <SmallWidgetCard
                  isLoading={isLoading}
                  text={polyglot.t(
                    'composable_device.dashboard_oven.max_distance_recipe'
                  )}
                  value={'32min'}
                />
              </Grid>
              <Grid item xs={12}>
                <SmallWidgetCard
                  isLoading={isLoading}
                  text={polyglot.t(
                    'composable_device.dashboard_oven.consumption_per_recipe_average'
                  )}
                  value={widgetConsumptionSummary.recipesAvg}
                />
              </Grid>
              <Grid item xs={12}>
                <SmallWidgetCard
                  isLoading={isLoading}
                  text={polyglot.t(
                    'composable_device.dashboard_oven.average_recipe_per_hour'
                  )}
                  value={widgetConsumptionSummary.recipesAvg}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
    </Grid>
  );
}
