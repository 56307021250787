import { Box, Stack } from '@mui/material';
import React from 'react';
import AuthLayoutHeader from './AuthLayoutHeader';
import AuthLayoutFooter from './AuthLayoutFooter';
import { ImageLoginDesktop } from '../../../assets/auth';

export interface AuthLayoutProps {
  children: React.ReactNode;
  imageRight?: string;
}

export function AuthLayout(props: AuthLayoutProps) {
  const { imageRight = ImageLoginDesktop } = props;
  return (
    <Stack
      direction={'row'}
      display="grid"
      justifyContent="center"
      alignItems="center"
      gridTemplateColumns={{ xs: '1fr', md: '800px 1fr' }}
      bgcolor="background.paper"
      height="100%"
      width="100%"
    >
      <Stack
        sx={{
          flexGrow: 1,
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ width: '100%' }}>
          <AuthLayoutHeader />
        </Box>
        <Box
          sx={{
            width: '100%',
            maxWidth: { xs: '100%', md: 620 },
            minWidth: { xs: 'initial', md: 620 },
            marginX: 2,
          }}
        >
          {props.children}
        </Box>
        <Box sx={{ margin: 2 }}>
          <AuthLayoutFooter />
        </Box>
      </Stack>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: { xs: 'none', md: 'inherit' },
          backgroundImage: { xs: `url(${imageRight})` },
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }}
      >
        {' '}
      </Box>
    </Stack>
  );
}

export default AuthLayout;
