import React from 'react';
import { useAppSelector } from '../../../../redux/store.model';
import OperatorDashboard from './Operator/OperatorDashboard';
import RegionalManagerDashboard from './RegionalManager/RegionalManagerDashboard';
import { getDashboardRightsSelector } from '../../../../redux/groups/selectors/groupRightsSelectors';
import { BoxProps, CircularProgress, Grid, Typography } from '@mui/material';
import { getPolyglot } from '../../../../i18n';
import { useGroups } from '../../../../redux/groups/hooks/useGroups';

const DASHBOARDS = {
  SCCOperatorDashboard: <OperatorDashboard />,
  SCCRegionalDashboard: <RegionalManagerDashboard />,
};

const DashboardWrapper = () => {
  useGroups();
  const {
    canViewOperatorDashboard,
    canViewRegionalManagerDashboard,
    isFirstLoad,
  } = useAppSelector(getDashboardRightsSelector);

  const polyglot = getPolyglot();

  const loadingContent = (
    <WrapperContent>
      <CircularProgress />
    </WrapperContent>
  );

  if (isFirstLoad) {
    return loadingContent;
  }

  /* Display the dashboard assigned to that role */
  if (canViewRegionalManagerDashboard) {
    return DASHBOARDS.SCCRegionalDashboard;
  } else if (canViewOperatorDashboard) {
    return DASHBOARDS.SCCOperatorDashboard;
  } else {
    return (
      <WrapperContent>
        <Typography variant="h1" color="common.black">
          {polyglot.t('freshwatch.dashboard.no_permission')}
        </Typography>
      </WrapperContent>
    );
  }
};

export default DashboardWrapper;

const WrapperContent = (props: BoxProps) => (
  <Grid
    sx={{
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      p: '4rem',
    }}
  >
    {props.children}
  </Grid>
);
