import React from 'react';
import { useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import {
  Avatar,
  Grid,
  Menu,
  MenuItem,
  TextField,
  Typography,
  useTheme,
  Box,
  Alert,
} from '@mui/material';

import SkeletonAreaChart from '../ui-component/cards/Skeleton/SkeletonAreaChart';
import MainCard from '../ui-component/cards/MainCard';
import StackedAreaChart from './StackedAreaChart';
import { Device } from '../../../redux/devices/api/device.model';
import { ITimeFrame } from './util/mock/getMockDataDashboard';
import { TIMEFRAME_LIST } from './util/mock/constants';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { getPolyglot } from '../../../i18n';
import { ZipDeviceControlUnit } from '../../../redux/controlUnit/controlUnit.model';
import {
  generateStartTimeFormattedDate,
  getColumnUnitsBySelectedTimeframe,
  getUnitsBySelectedTimeframe,
  getTimestamps,
} from './util/mock/Utils';
import { useParseErrorMessage } from '../../../handlingErrors';
import { Group } from '../../../redux/groups/api/group.model';
import ReportRealTimeAPI from '../../../redux/reports/api/ReportRealTimeAPI';
import {
  getConsumptionData,
  getConsumptionPriceKWh,
} from '../../../redux/composableDevice/util/consumption';
import { getConsumptionsMetrics } from './util/getConsumptionsMetrics';
interface PriceKWAreaChartCardProps {
  commanders: Device[];
  group: Group | undefined;
  devicesControlUnitsSource: ZipDeviceControlUnit[];
}
const UNIT = '€';
const PriceKWAreaChartCard = ({
  commanders,
  group,
  devicesControlUnitsSource,
}: PriceKWAreaChartCardProps) => {
  const polyglot = getPolyglot();
  const [timeFrame, setTimeframe] = useState<ITimeFrame>('week');
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);

  const composableDeviceConsumptions = getConsumptionData(group);
  const consumptionPriceKWh = getConsumptionPriceKWh(group);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const consumptionMetrics = useMemo(
    () =>
      getConsumptionsMetrics(
        devicesControlUnitsSource,
        commanders,
        composableDeviceConsumptions
      ),
    [commanders, devicesControlUnitsSource, composableDeviceConsumptions]
  );

  const {
    data: consumptionDataValues,
    isFetching: isFetchingReport,
    error: errorReport,
  } = ReportRealTimeAPI.useGetReportsByGroupIdQuery(
    {
      encodedGroupId: group?.id ?? '',
      spec: {
        outputFormat: 'JSON',
        startTime: generateStartTimeFormattedDate(timeFrame),
        columnCount: getUnitsBySelectedTimeframe(timeFrame),
        columnDuration: 1,
        columnUnit: getColumnUnitsBySelectedTimeframe(timeFrame),
        metrics: consumptionMetrics,
      },
    },
    {
      skip:
        !devicesControlUnitsSource || devicesControlUnitsSource.length === 0,
    }
  );

  const data = useMemo(() => {
    const timestamps = getTimestamps(timeFrame);

    return timestamps.map((timestamp, dayIndex) => {
      const entry: Record<string, number | string> = { timestamp };

      consumptionDataValues?.rows.forEach((consumption, index) => {
        const key = commanders[index].uuid;
        entry[key] = consumption.values[dayIndex] * consumptionPriceKWh;
      });

      return entry;
    });
  }, [consumptionDataValues, commanders, timeFrame]);

  const csvData = useMemo(() => {
    const exportData = [];
    const header = Object.keys(data[0] ?? []);
    exportData.push(header);
    data.forEach((d) => exportData.push(Object.values(d)));
    return exportData;
  }, [data]);

  const parseError = useParseErrorMessage();

  const CustomTooltip = ({ active, payload, label }: any) => {
    const polyglot = getPolyglot();

    if (active && payload) {
      const total = payload.reduce(
        (sum: any, entry: { value: any }) => sum + entry.value,
        0
      );
      return (
        <div
          style={{
            backgroundColor: 'white',
            padding: '20px',
            border: '1px solid #ccc',
          }}
        >
          <p>{`${polyglot.t('general.date')}: ${label}${
            timeFrame === 'day' || timeFrame === 'hour'
              ? new Date(label).toLocaleString()
              : new Date(label).toLocaleDateString()
          }`}</p>
          <p>
            <b>{`${polyglot.t(
              'composable_device.dashboard_oven.charts.total'
            )}: ${total.toFixed(2)} ${payload.unit ?? UNIT} (${(
              total / consumptionPriceKWh
            ).toFixed(2)} kWh)`}</b>
          </p>
          {payload.map(
            (
              entry: { color: any; name: any; value: any },
              index: React.Key | null | undefined
            ) => (
              <p key={index} style={{ color: entry.color }}>
                {`${entry.name}: ${entry.value.toFixed(2)} ${
                  payload?.unit ?? UNIT
                } (${(entry.value / consumptionPriceKWh).toFixed(2)} kWh)`}
              </p>
            )
          )}
        </div>
      );
    }
    return null;
  };
  return (
    <>
      {!isFetchingReport && errorReport && (
        <Alert severity="error">{parseError(errorReport)}</Alert>
      )}
      {isFetchingReport ? (
        <SkeletonAreaChart />
      ) : (
        <MainCard>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                direction="row"
              >
                <Grid item>
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <Typography variant="h2">
                        {polyglot.t(
                          'composable_device.dashboard_oven.total_consumption_price'
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    justifyContent="space-between"
                    spacing={1}
                    alignItems="center"
                  >
                    <Grid item>
                      <TextField
                        id="standard-select-timeframe-consumptions"
                        select
                        value={timeFrame}
                        onChange={(e) =>
                          setTimeframe(e.target.value as ITimeFrame)
                        }
                      >
                        {TIMEFRAME_LIST.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {polyglot.t(option.label)}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item>
                      <Avatar
                        variant="rounded"
                        sx={{
                          backgroundColor: theme.palette.primary.main,
                          color: theme.palette.background.default,
                          zIndex: 1,
                        }}
                        aria-controls="menu-consumptions-card"
                        aria-haspopup="true"
                        onClick={handleClick}
                      >
                        <MoreHorizIcon fontSize="inherit" />
                      </Avatar>
                      <Menu
                        id="menu-consumptions-card"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        variant="selectedMenu"
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      >
                        <MenuItem onClick={handleClose}>
                          <CSVLink
                            data={csvData}
                            filename={'total-consumptions-export.csv'}
                            target="_blank"
                          >
                            <Box display="flex" alignItems="center">
                              <FileDownloadIcon sx={{ mr: 1 }} />
                              {polyglot.t(
                                'composable_device.dashboard_oven.actions.export'
                              )}
                            </Box>
                          </CSVLink>
                        </MenuItem>
                      </Menu>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <StackedAreaChart
                commanders={commanders}
                timeFrame={timeFrame}
                data={data}
                unit={UNIT}
                CustomTooltip={CustomTooltip}
              />
            </Grid>
          </Grid>
        </MainCard>
      )}
    </>
  );
};

export default PriceKWAreaChartCard;
