import React from 'react';
import { CardItem } from '../../../../../theme/components';
import { useAppSelector } from '../../../../../redux/store.model';
import { getAlarmActiveBySelector } from '../../../../../redux/incident/selectors/getAlarmActiveBySelector';
import GroupModel from '../../../../../redux/groups/api/GroupModel';
import { getPolyglot } from '../../../../../i18n';
import { DeviceGroupIcon } from '../../../../../theme/icons';
import { useNavigate } from 'react-router-dom';
import { GroupActionsGrid } from './GroupActions/GroupActions';
import { Group } from '../../../../../redux/groups/api/group.model';

interface GroupCardGridProps {
  group: Group;
  showImage?: boolean;
  moduleSelected: string | '';
}
function GroupCardGrid(props: GroupCardGridProps) {
  const { group, showImage = true } = props;
  const polyglot = getPolyglot();
  const navigate = useNavigate();
  const { data: alarmsActive } = useAppSelector((state) =>
    getAlarmActiveBySelector(state, {
      groupId: group.id,
    })
  );
  const openDeviceDetail = () => {
    const url = `/devicemanager/group/${group.id}`;
    navigate(url);
  };
  return (
    <CardItem
      key={group.id}
      name={group.name}
      // subTitle={GroupModel.getGroupSubtitles(group)}
      altIcon={polyglot.t('group.icon')}
      iconURL={group.iconURL ?? undefined}
      defaultIcon={<DeviceGroupIcon height={'150px'} />}
      onClick={openDeviceDetail}
      error={alarmsActive.length > 0}
      buttonsActions={<GroupActionsGrid group={group} />}
      showImage={showImage}
    />
  );
}

export default GroupCardGrid;
