import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { PolyglotComponentProps, withPolyglot } from '../../i18n';
import { getGroups } from '../../redux/groups/selectors';
import GroupModel from '../../redux/groups/api/GroupModel';
import { RootState } from '../../redux/store.model';
import RecipeManager from './RecipeManager';
import { Box, Container, Paper } from '@mui/material';
import { RouterComponentProps, withRouter } from '../../util/route-dom';
import NavBackButton from '../DeviceManager/navigations/NavBackButton';

type Props = {} & ConnectedComponentProps &
  PolyglotComponentProps &
  RouterComponentProps<{ groupId: string }>;

export function RecipeManagerPage(props: Props) {
  const { groups, params, polyglot, navigate } = props;
  const { groupId } = params;
  const group = groups.filter((g) => g.id === groupId)[0];
  /**
   * Navigation
   * if the groups are loaded then the breadcrumb will have a link to the devices manager
   */
  let parentId: string | null | undefined;
  if (groups.length > 0) {
    parentId = GroupModel.getMainParentGroupId(groupId, groups);
  }
  const sectionsNavigation = [
    {
      content:
        parentId !== undefined
          ? polyglot.t('nav.menu.devicemanager')
          : polyglot.t('button.back'),
      onClick:
        parentId !== undefined
          ? () =>
              navigate(
                `/devicemanager/group/${parentId !== null ? parentId : ''}`
              )
          : () => navigate(-1),
    },
    {
      content: `${polyglot.t('recipe_manager.title')} ${
        group && group.name !== undefined ? group.name : ''
      }`,
    },
  ];
  return (
    <Container
      maxWidth="xl"
      disableGutters
      sx={{
        height: '100%',
      }}
    >
      {sectionsNavigation.length > 1 && (
        <Box sx={{ mx: { xs: 1, md: 3 } }}>
          <NavBackButton path={sectionsNavigation} />
        </Box>
      )}
      <Container maxWidth="lg" disableGutters sx={{ py: 2 }}>
        <RecipeManager />
      </Container>
    </Container>
  );
}
const mapStateToProps = (state: RootState) => ({
  groups: getGroups(state),
});

const connector = connect(mapStateToProps);
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connector(withPolyglot(withRouter(RecipeManagerPage)));
