import { useEffect, useMemo } from 'react';
import { getControlUnitIdsActiveById } from '../../controlUnit/selectors';
import { warmUpControlUnitsTranslations } from '../action/thunks';
import {
  getCUTranslationFromDevice,
  getLoadingCUTranslationFromDevice,
} from '../selectors';
import { RootState, useAppDispatch, useAppSelector } from '../../store.model';
import { getTranslateFunction } from '../api/util/getTranslateFunction';
import { useControlUnitById } from '../../controlUnit/hooks/useControlUnitById';

export const useCUTranslate = (deviceId: string, controlUnitId?: string) => {
  const [, isLoadingControlUnit] = useControlUnitById(
    deviceId,
    controlUnitId ?? '',
    () => {
      // do nothing
    }
  );

  const controlUnitIds = useAppSelector((state: RootState) =>
    getControlUnitIdsActiveById(state, { encodedDeviceId: deviceId })
  );
  const loading = useAppSelector((state: RootState) =>
    getLoadingCUTranslationFromDevice(state, { deviceId })
  );
  const translations = useAppSelector((state: RootState) =>
    getCUTranslationFromDevice(state, { deviceId })
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (controlUnitIds) {
      dispatch(warmUpControlUnitsTranslations({ controlUnitIds, deviceId }));
    }
  }, [controlUnitIds, deviceId, dispatch]);

  const CUTranslate = useMemo(
    () => getTranslateFunction(translations, controlUnitId),
    [translations, controlUnitId]
  );
  return [CUTranslate, loading || isLoadingControlUnit] as const;
};
