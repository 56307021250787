import React from 'react';
import axiosBaseQuery from '../../../axios/axiosBaseQuery';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  DigitalEventsControlUnits,
  SensordataNewest,
} from '../../monitoring/api/monitoring.model';
import { Base64 } from '../../../util/Base64';
import { CU_POLLING_INTERVAL } from '../../CUActions/api/CUActions.constants';
import { arrayToRecord } from '../../../util/ArrayUtil';
import {
  ControlUnitAPIResponse,
  LinkedDigitalEvent,
  LinkedDigitalSensorData,
} from './ControlUnit.model';
import _ from 'lodash';
import { RecordLVL2 } from '../../../util/types/Records';

const ControlUnitAPI = createApi({
  reducerPath: 'ControlUnitAPI',
  baseQuery: axiosBaseQuery({ baseUrl: '/api/' }),
  tagTypes: ['control-unit', 'commander'],
  keepUnusedDataFor: CU_POLLING_INTERVAL,
  endpoints(build) {
    return {
      getCurrentSensorDataByCommanderID: build.query<
        LinkedDigitalSensorData,
        string
      >({
        query: (deviceId) => ({
          url: `sensordata/${Base64.encode(deviceId)}/newest`,
        }),
        transformResponse: (response: SensordataNewest[]) => {
          return response.reduce((acc, currentItem) => {
            _.setWith(
              acc,
              [currentItem.cuId, currentItem.channel],
              currentItem,
              Object
            );
            return acc;
          }, {}) as LinkedDigitalSensorData;
        },
        providesTags: (result, error, arg) => [
          { type: 'commander' as const, id: arg },
        ],
      }),
      getCurrentEventsByCommanderID: build.query<LinkedDigitalEvent, string>({
        query: (deviceId) => ({
          url: `event/${Base64.encode(deviceId)}/newest`,
        }),
        providesTags: (result, error, arg) => [
          { type: 'commander' as const, id: arg },
        ],
        transformResponse: (response: DigitalEventsControlUnits[]) => {
          return arrayToRecord(response, 'idCU', (cuEvents) =>
            arrayToRecord(cuEvents.events, 'eventID')
          );
        },
      }),
    };
  },
});
export default ControlUnitAPI;
