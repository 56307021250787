import React, { useRef } from 'react';
import { InfoModalProps } from '../../../theme/components';
import {
  AppBar,
  Box,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  TextFieldProps,
  Toolbar,
} from '@mui/material';
import ElevationScroll from '../../../theme/components/Modal/ElevationScroll';
import { Close as CloseIcon } from '@mui/icons-material';
import { getPolyglot } from '../../../i18n';

export interface SearchModalProps extends InfoModalProps {
  TextFieldProps?: TextFieldProps;
}
function SearchModal(props: SearchModalProps) {
  const {
    disabledClose,
    onClose,
    children,
    open,
    TextFieldProps,
    ...dialogProps
  } = props;
  const polyglot = getPolyglot();
  const contentRef = useRef(null);
  const inputFieldRef = useRef<HTMLInputElement | null>(null);

  return (
    <Dialog
      onClose={!disabledClose ? onClose : undefined}
      {...props}
      aria-labelledby="search-dialog-title"
      maxWidth="md"
      fullWidth
    >
      <ElevationScroll target={contentRef.current}>
        <AppBar
          position="static"
          variant="elevation"
          sx={{ zIndex: 1 }}
          color="transparent"
        >
          <Toolbar
            variant="regular"
            disableGutters
            sx={{ padding: 1, height: '100%' }}
          >
            <TextField
              id="searchbar"
              name="searchbar"
              variant="outlined"
              fullWidth
              InputProps={{
                inputRef: inputFieldRef,
                endAdornment: (
                  <IconButton
                    edge="end"
                    color="inherit"
                    aria-label="close"
                    disabled={disabledClose}
                    onClick={onClose}
                  >
                    <CloseIcon sx={{ fill: '#757575' }} />
                  </IconButton>
                ),
              }}
              {...TextFieldProps}
              label={polyglot.t('general.search')}
            />
            <Box sx={{ flexGrow: 1 }} />
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <DialogContent ref={contentRef}>{children}</DialogContent>
    </Dialog>
  );
}

export default SearchModal;
