import { IconButton, styled } from '@mui/material';

export const StyledActionButton = styled(IconButton)(
  ({ theme }) => `
  background-color: ${theme.palette.primary.contrastText};
  border-radius: 0.7rem;
  padding: 0.8rem;
  margin: 1rem 0.5rem;
  box-shadow: 0px 3px 6px #00000029;  
  width: 3.5rem;
  &:hover {
    background-color: ${theme.palette.secondary.light};
  }
`
);
