import { RootState, useAppSelector } from '../../store.model';
import ControlUnitAPI from '../api/ControlUnitAPI';
import { CU_POLLING_INTERVAL } from '../../CUActions/api/CUActions.constants';
import { getMixedSensordataFromId } from '../selectors/sensordata';
import { Base64 } from '../../../util/Base64';

/**
 * Get and fetch control unit from specified device
 * @param deviceId
 * @returns [currentAlarms, loading, error]
 * 
 * @usage
´´´ts
const [currentAlarms, loading, error] = useCurrentSensorDataControlUnitById("UUUID_DEVICE")
´´´
 */
export function useCurrentSensorDataControlUnitById(
  deviceId: string,
  controlUnitId: string
) {
  const controlUnitSensorDataResponse =
    ControlUnitAPI.useGetCurrentSensorDataByCommanderIDQuery(deviceId, {
      skip: !deviceId,
      pollingInterval: CU_POLLING_INTERVAL,
    });
  const mixedControlUnitCurrentSensorData = useAppSelector((state: RootState) =>
    getMixedSensordataFromId(state, {
      deviceId,
      encodedDeviceId: Base64.encode(deviceId),
      controlUnitId,
    })
  );

  return {
    ...controlUnitSensorDataResponse,
    data: mixedControlUnitCurrentSensorData,
  };
}
