import { CheckCircleOutline } from '@mui/icons-material';
import {
  Alert,
  Box,
  CircularProgress,
  Container,
  Typography,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { getPolyglot } from '../../../i18n';
import useParseErrorMessage from '../../../handlingErrors/useParseErrorMessage';
import StoerkIdAPI from '../../../redux/stoerkID/StoerkIdAPI';
import StoerkIdDetails from '../StoerkIdDetails';
import MyStoerkIdActions from './MyStoerkIdActions';
import StoerkIdRequestSuccessfulModal from '../StoerkIdRequest/StoerkIdRequestSuccesufullModal';
import { first } from 'lodash';
import useWatchError from '../../../handlingErrors/useWatchError';
import { WorkspaceAPI } from '../../../redux/stoerkID/StoerkIdAPI.model';
import MyStoerkIdDisabled from './MyStoerkIdDisabled';
import { useUserRightUtil } from '../../../util/rights';

export default function MyStoerkId() {
  const polyglot = getPolyglot();
  const {
    data: workspaces,
    error: errorWorkspace,
    isLoading: isLoadingWorkspace,
    isError: isErrorWorkspace,
  } = StoerkIdAPI.useGetMyWorkspacesQuery(undefined);
  const [workspaceIdSelected, setWorkspaceId] = useState(first(workspaces)?.id);
  const setWorkspace = (workspace: WorkspaceAPI) => {
    setWorkspaceId(workspace.id);
  };
  const workspace = useMemo(
    () => workspaces?.find((workspace) => workspace.id === workspaceIdSelected),
    [workspaces, workspaceIdSelected]
  );
  const userRightUtil = useUserRightUtil();
  const showAskToDeleteWorkspace = userRightUtil.canAskToDeleteWorkspace(
    workspace?.id ?? ''
  );

  const {
    data: requests = [],
    error: errorRequests,
    isLoading: isLoadingRequests,
    isError: isErrorRequests,
  } = StoerkIdAPI.useGetMyRequestsQuery(undefined);
  const parseErrorMessage = useParseErrorMessage();
  const isLoading = isLoadingWorkspace || isLoadingRequests;
  const isError = isErrorWorkspace || isErrorRequests;
  const error = errorWorkspace || errorRequests;

  useWatchError(error);

  const requestActive = first(requests);
  const requestsOrdered = useMemo(() => requests.slice().reverse(), [requests]);

  const showWorkspaceInfo = userRightUtil.canViewWorkspace(workspace?.id ?? '');

  return (
    <Container maxWidth="xl" sx={{ padding: 3, height: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <Typography variant="h1">
            {polyglot.t('stoerk_id.detail_page.title')}
          </Typography>
          {showWorkspaceInfo ? (
            <Typography>{polyglot.t('stoerk_id.detail_page.body')}</Typography>
          ) : (
            <Typography>
              {polyglot.t('stoerk_id.detail_page.body_as_team')}{' '}
              <strong>{workspace?.name}</strong>
            </Typography>
          )}
        </Box>

        <Box sx={{}}>
          <MyStoerkIdActions request={requestActive} workspace={workspace} />
        </Box>
      </Box>

      <Container maxWidth="lg" disableGutters>
        {isLoading ? (
          <Box sx={{ textAlign: 'center' }}>
            <CircularProgress />
          </Box>
        ) : isError ? (
          <Box sx={{ textAlign: 'center' }}>
            <Alert
              icon={<CheckCircleOutline fontSize="inherit" />}
              severity="error"
            >
              {parseErrorMessage(error)}
            </Alert>
          </Box>
        ) : workspace || requestsOrdered?.length ? (
          <StoerkIdDetails
            workspace={workspace}
            workspaces={workspaces}
            requests={requestsOrdered}
            requestActive={requestActive}
            onChangeWorkspace={setWorkspace}
            showAccountData={false}
            showPolicies
            showRequestInfo={
              requestActive?.requestStatus &&
              requestActive?.requestStatus !== 'ACTIVE'
            }
            showBillingInfo={
              requestActive?.requestStatus &&
              requestActive?.requestStatus !== 'ACTIVE'
            }
          />
        ) : null}
        {!!workspace && showAskToDeleteWorkspace && (
          <MyStoerkIdDisabled workspace={workspace} />
        )}
        {/* FIXME: define process to disable her account*/}
      </Container>
      <StoerkIdRequestSuccessfulModal />
    </Container>
  );
}
