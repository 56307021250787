import React from 'react';
import {
  Accordion,
  AccordionSummary,
  Alert,
  List,
  Typography,
} from '@mui/material';
import { ListEntitiesSearchProps } from '../ListEntitiesSearch';
import { useAppSelector } from '../../../../redux/store.model';
import { getAllGroupsWithChainGroups } from '../../../../redux/groups/selectors/searchSelectors';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { getPolyglot } from '../../../../i18n';
import { ListItem } from '../../../../theme/components';
import GroupModel from '../../../../redux/groups/api/GroupModel';
import { DeviceGroupIcon } from '../../../../theme/icons';
import { useNavigate } from 'react-router-dom';

function GroupsSearch(props: ListEntitiesSearchProps) {
  const { searchValue, close } = props;
  const groups = useAppSelector(getAllGroupsWithChainGroups);
  const navigate = useNavigate();
  const groupsResults = groups.filter(
    (group) =>
      group.name &&
      searchValue &&
      searchValue?.length &&
      group.name.toLowerCase().includes(searchValue?.toLowerCase())
  );
  const polyglot = getPolyglot();

  const onClick = (groupId: string) => () => {
    const url = `/devicemanager/group/${groupId}`;
    navigate(url);
    close?.();
  };

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary
        id="accordion-search-groups-title"
        aria-controls="accordion-search-groups-title"
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography variant="h5">{polyglot.t('group.groups')}</Typography>
      </AccordionSummary>
      {groupsResults.length ? (
        <List>
          {groupsResults.map((group, i) => (
            <ListItem
              key={i}
              name={group?.name}
              iconURL={group?.iconURL}
              subtitle={GroupModel.getGroupChainsSubtitles(group?.chainGroups)}
              iconNode={!group?.iconURL && <DeviceGroupIcon />}
              className={GroupModel.getClassesNamesFromDevice(group)}
              onClick={onClick(group.id)}
              ItemTextContainerProps={{
                sx: (theme) => ({
                  maxWidth: 'initial',
                  textAlign: 'left',
                  [theme.breakpoints.down('md')]: {
                    maxWidth: 'initial',
                  },
                }),
              }}
              TitleProps={{
                sx: (theme) => ({
                  maxWidth: '500px',
                  [theme.breakpoints.down('md')]: {
                    maxWidth: '200px',
                  },
                }),
              }}
            />
          ))}
        </List>
      ) : (
        <Alert severity="info">
          {polyglot.t('device.search.message_no_results_found')}
        </Alert>
      )}
    </Accordion>
  );
}

export default React.memo(GroupsSearch);
