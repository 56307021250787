import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  alpha,
  styled,
  useTheme,
} from '@mui/material';
import React from 'react';
import { StyledHeaderRow, StyledTableRow } from '../StyledTable/StyledTable';
import { getPolyglot } from '../../../../../i18n';
import { useNavigate } from 'react-router-dom';
import { RegionDetails } from '../../../redux/composableDevice/model/SCCGroup.model';

const CityStoresTable = (props: { cities: Map<string, RegionDetails> }) => {
  const { cities } = props;

  const theme = useTheme();
  const polyglot = getPolyglot();
  const navigate = useNavigate();

  return (
    <TableContainer
      component={Paper}
      sx={{
        background: 'transparent',
        border: 'none',
      }}
    >
      <Table
        sx={{
          borderCollapse: 'separate',
          padding: '0.2rem 0 0.5rem 0.5rem',
        }}
        aria-label="Store Overview"
      >
        <TableHead sx={{ bgcolor: 'neutral.dark' }}>
          <StyledHeaderRow>
            <TableCell
              sx={{
                width: '50%',
                textAlign: 'start!important',
                padding: '0 0 0 2rem',
                fontWeight: 'bolder',
              }}
            >
              {polyglot.t('freshwatch.dashboard.detail.location_store')}
            </TableCell>
            <TableCell
              sx={{ width: '25%', fontWeight: 'bolder', padding: '0.5rem' }}
            >
              {polyglot.t('freshwatch.dashboard.merchandisers')}
            </TableCell>
            <TableCell
              sx={{ width: '25%', fontWeight: 'bolder', padding: '0.5rem' }}
            >
              {polyglot.t('freshwatch.dashboard.alarms')}
            </TableCell>
          </StyledHeaderRow>
        </TableHead>
        <TableBody>
          {[...cities.keys()].map((cityValue) => {
            const city = cities.get(cityValue);

            if (!city) return null;

            const summaryStores = city.citiesDetails.length;
            const summaryMerchandisers = city.citiesDetails.reduce(
              (acc, city) => acc + city.merchandisers.length,
              0
            );
            const summaryAlarms = city.citiesDetails.reduce(
              (acc, city) => acc + city.alarms.length,
              0
            );

            const alarms = city.citiesDetails.map((city) => city.alarms).flat();

            return (
              <>
                <TableRow
                  sx={{
                    background:
                      alarms.length > 0
                        ? theme.palette.error.main
                        : theme.palette.primary.main,
                  }}
                >
                  <TableCell
                    sx={{
                      color: 'common.white',
                      textAlign: 'start',
                      pl: 2,
                      fontWeight: 'bold',
                    }}
                    colSpan={3}
                  >
                    {city.city.name}
                  </TableCell>
                </TableRow>
                {city.citiesDetails.length === 0 ? (
                  <StyledTableRow error={false} hover={false}>
                    <TableCell
                      colSpan={3}
                      sx={{
                        padding: 0,
                      }}
                    >
                      <Typography
                        padding={'1rem 2rem'}
                        color="common.black"
                        fontSize={'1rem'}
                        fontWeight={'normal'}
                      >
                        {polyglot.t('device.search.message_no_results_found')}
                      </Typography>
                    </TableCell>
                  </StyledTableRow>
                ) : (
                  city.citiesDetails.map((city) => {
                    const alarms = city.alarms;
                    const merchandisers = city.merchandisers;
                    const error = alarms.length > 0;

                    return (
                      <StyledTableRow
                        onClick={() => navigate(`store/${city.store.id}`)}
                        error={error}
                        key={city.store.id}
                        sx={{
                          cursor: 'pointer',
                        }}
                      >
                        <TableCell
                          sx={{
                            color: 'inherit',
                            fontWeight: 'normal',
                            pl: 2,
                          }}
                        >
                          {city.store.name}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: 'inherit',
                            fontWeight: 'normal',
                            textAlign: 'center',
                          }}
                        >
                          {merchandisers.length}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: 'inherit',
                            textAlign: 'center',
                          }}
                        >
                          {alarms.length}
                        </TableCell>
                      </StyledTableRow>
                    );
                  })
                )}
                <StyledTableRow error={false} hover={false}>
                  <TableCell colSpan={3}>
                    <Typography
                      component={'p'}
                      textAlign={'end'}
                      variant="caption"
                      fontSize={'0.8rem'}
                    >
                      {`${polyglot.t(
                        'freshwatch.dashboard.stores'
                      )}: ${summaryStores} - ${polyglot.t(
                        'freshwatch.dashboard.merchandisers'
                      )}: ${summaryMerchandisers} - ${polyglot.t(
                        'freshwatch.dashboard.alarms'
                      )}: ${summaryAlarms}`}
                    </Typography>
                  </TableCell>
                </StyledTableRow>
              </>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CityStoresTable;
