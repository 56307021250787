import { Box, CircularProgress, Container, Grid } from '@mui/material';
import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { PolyglotComponentProps, withPolyglot } from '../../../../i18n';
import { Group } from '../../../../redux/groups/api/group.model';
import { warmUpGroups } from '../../../../redux/groups/actions/thunks';
import { getGroups } from '../../../../redux/devices/selectors';
import { RootState } from '../../../../redux/store.model';
import { isGroupsFirstLoad } from '../../../../redux/groups/selectors';
import { RouterComponentProps, withRouter } from '../../../../util/route-dom';
import withHandlingErrors, {
  HandlingErrorWrappedProps,
} from '../../../../handlingErrors';
import { connect, ConnectedProps } from 'react-redux';
import { useNavigationPathMerchandiser } from '../Merchandiser/useNavigationPathMerchandiser';
import Breadcrumb from '../../../../theme/components/Breadcrumb/Breadcrumb';

interface DashboardLayoutProps
  extends ConnectedComponentProps,
    PolyglotComponentProps,
    HandlingErrorWrappedProps,
    RouterComponentProps<{ groupId?: string | undefined }> {
  isGroupFirstLoad: boolean;
  groups: Group[];
}
export function DashboardLayout(props: DashboardLayoutProps) {
  const params = useParams();
  const isFirstLoad = props.isGroupsFirstLoad;
  const navigation = useNavigationPathMerchandiser();
  return (
    <Container
      maxWidth="xl"
      disableGutters
      sx={{
        height: '100%',
        backgroundColor: 'background.container',
      }}
    >
      {(params.groupId || params['*']) && (
        <Box
          sx={{
            px: 3,
            pt: 1,
            backgroundColor: 'background.container',
          }}
        >
          <Breadcrumb sections={navigation} />
        </Box>
      )}
      <Box
        py={1}
        sx={{
          backgroundColor: 'background.container',
        }}
      >
        {isFirstLoad ? (
          <Grid
            sx={{
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              p: '4rem',
            }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <Outlet />
        )}
      </Box>
    </Container>
  );
}

const mapStateToProps = (state: RootState) => ({
  groups: getGroups(state),
  isGroupsFirstLoad: isGroupsFirstLoad(state),
});
const connector = connect(mapStateToProps, { warmUpGroups });
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default withHandlingErrors(
  withPolyglot(withRouter(connector(DashboardLayout)))
);
