import { ZipDeviceControlUnit } from '../../../../redux/controlUnit/controlUnit.model';
import { Device } from '../../../../redux/devices/api/device.model';
import { Base64 } from '../../../../util/Base64';
import { CHANNELS_METRICS } from './mock/constants';

export const getConsumptionsMetrics = (
  devicesControlUnitsSource: ZipDeviceControlUnit[],
  commanders: Device[],
  composableDeviceConsumptions: Record<string, number>
) => {
  return commanders.map((commander, index) => {
    return {
      name: `consumptions-${commander.uuid}`,
      channels: devicesControlUnitsSource[index].controlUnits
        ?.filter((item) => item.id !== '1002')
        .map((item) => {
          return {
            deviceID: Base64.decode(commander.uuid),
            cuID: item.id,
            channelID: 'On',
          };
        }),
      metric: CHANNELS_METRICS.ON_COST,
      options: {
        defaultCostPerSecond:
          composableDeviceConsumptions[commander.uuid] / 3600,
      },
    } as ReportRealTimeSpec['metrics'][number];
  });
};
