export const TIMEFRAME_LIST = [
  {
    value: 'day',
    label: 'composable_device.dashboard_oven.charts.today',
  },
  {
    value: 'week',
    label: 'composable_device.dashboard_oven.charts.last_week',
  },
  {
    value: 'month',
    label: 'composable_device.dashboard_oven.charts.last_month',
  },
  // {
  //   value: 'year',
  //   label: 'general.composable_device.dashboard_oven.charts.last_year',
  // },
];

export enum CHANNELS_METRICS {
  //DIGITAL CHANNELS
  TIME_ON = 'TIME_ON', //Calculate time in seconds for which this digital channel's value was greater than zero
  TIME_OFF = 'TIME_OFF', //Calculate time in seconds for which this digital channel's value was zero

  CONTINUOUS_TIME_ON = 'CONTINUOUS_TIME_ON', //Calculate the longest continuous interval for which this digital channel's value was greater than zero
  CONTINUOUS_TIME_OFF = 'CONTINUOUS_TIME_OFF', //Calculate the longest continuous interval for which this digital channel's value was zero

  ON_COST = 'ON_COST', //Calculate time in seconds for which this digital channel's value was greater than zero, then multiply  by cost

  //ANALOGUE CHANNELS
  MINIMUM_VALUE = 'MINIMUM_VALUE', //Find the lowest value in the interval
  MAXIMUM_VALUE = 'MAXIMUM_VALUE', //Find the highest value in the interval
  AVERAGE_VALUE = 'AVERAGE_VALUE', //Find the average value in the interval

  DELTA = 'DELTA', //Calculate the difference between the beginnoing and the end of the interval
  SUM = 'SUM', //Integrate over time
  TIME_ABOVE_THRESHOLD = 'TIME_ABOVE_THRESHOLD', //Calculate the total time above a certain value
  TIME_BELOW_THRESHOLD = 'TIME_BELOW_THRESHOLD', //Calculate the total time below a certain value
  CONTINUOUS_TIMEA_ABOVE_THRESHOLD = 'CONTINUOUS_TIMEA_ABOVE_THRESHOLD', //Calculate the longest continuous interval above a certain value
  CONTINUOUS_TIMEA_BELOW_THRESHOLD = 'CONTINUOUS_TIMEA_BELOW_THRESHOLD', //Calculate the longest continuous interval below a certain value
  MAXIMUM_CHANGE = 'MAXIMUM_CHANGE', //Find the biggest delta between two values
}

export enum COLUMN_UNITS {
  SECOND = 'second',
  MINUTE = 'minute',
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  //YEAR = 'YEAR', // actually not allowed because of performance
}
