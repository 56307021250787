import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import { Alarm, SEVERITY } from '../AlarmCard/AlarmCard';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { getPolyglot } from '../../../../../i18n';

const AlarmTable = (props: {
  data: Alarm[];
  headerTitles: string[];
  displayTask?: boolean;
}) => {
  const { data, headerTitles, displayTask = true } = props;
  const navigate = useNavigate();
  const polyglot = getPolyglot();

  const onClick = ({ uuid }: Alarm) => {
    navigate(`/alarm/${uuid}`);
  };
  if (!data.length) {
    return (
      <Typography>
        {polyglot.t('device.search.message_no_results_found')}
      </Typography>
    );
  }
  return (
    <TableContainer
      component={Paper}
      sx={{
        background: 'transparent',
        border: 'none',
      }}
    >
      <Table
        sx={{
          borderCollapse: 'separate',
          borderSpacing: '0 0.8rem',
          padding: '0.5rem',
        }}
        aria-label="Merchandise items"
      >
        <TableHead sx={{ bgcolor: 'neutral.dark' }}>
          <StyledHeaderRow>
            {headerTitles.map((title) => (
              <TableCell
                sx={{ width: '20%', fontWeight: 'bolder', padding: '0.5rem' }}
                key={title}
              >
                {title}
              </TableCell>
            ))}
          </StyledHeaderRow>
        </TableHead>
        <TableBody>
          {data.map((row: Alarm) => {
            return (
              <StyledTableRow
                key={row.title}
                sx={{ boxShadow: 2 }}
                error={row.severity}
                /* TODO: change with component Link when updating mui/material to v16 */
                onClick={() => onClick(row)}
                style={{ cursor: 'pointer' }}
              >
                <TableCell
                  sx={{
                    bgcolor: `${row.severity ? 'error.main' : 'warning.main'}`,
                    color: 'primary.contrastText',
                    fontWeight: 'bold',
                  }}
                >
                  {row.title}
                </TableCell>
                {!displayTask && (
                  <TableCell sx={{ fontWeight: 'normal' }}>
                    {row.productName}
                  </TableCell>
                )}
                <TableCell sx={{ fontWeight: 'normal' }}>
                  {row.description}
                </TableCell>
                <TableCell sx={{ fontWeight: 'normal' }}>
                  {moment(row.timestamp).format('l LTS')}
                </TableCell>
                {displayTask && (
                  <TableCell sx={{ fontWeight: 'normal' }}>
                    <Typography
                      textAlign={'start'}
                      fontSize={'1rem'}
                      lineHeight={'1.4rem'}
                      color={`${row.severity ? 'error.main' : 'warning.main'}`}
                    >
                      {row.task}
                    </Typography>
                  </TableCell>
                )}
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const StyledHeaderRow = styled(TableRow)`
  & .MuiTableCell-root {
    text-align: center;
    font-weight: 600;
  }
`;
const StyledTableRow = styled(TableRow)<{ error?: SEVERITY }>(
  ({ error, theme }) => `
  border-radius: 1rem;
  color: ${theme.palette.warning.main};
  background: ${theme.palette.warning.contrastText};
  ${
    error &&
    `
     color: ${theme.palette.error.main};
    background: ${theme.palette.error.contrastText};
    `
  }
  padding: 0.5rem;
  &:hover {
    background: ${theme.palette.warning.light};
    ${
      error &&
      `
      background: ${theme.palette.error.light}; 
      color: ${theme.palette.error.light}; 
    `
    }
    }
  
  & .MuiTableCell-root {
    border: none;
    text-align: center;
    color: ${error ? theme.palette.error.main : theme.palette.warning.main};


    &:first-of-type {
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;
      background: ${
        error ? theme.palette.error.main : theme.palette.warning.main
      };
      color: ${theme.palette.common.white};

    }

    &:last-child {
      border-top-right-radius: 1rem;
      border-bottom-right-radius: 1rem;
    }
  }
`
);

export default AlarmTable;
