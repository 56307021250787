import LinksFooterFW from '../../../../customized/fresh-watch/components/App/footer/LinksFooter/LinksFooter';
import LinksFooterFF from '../../../../customized/fours-fringand-fr/components/App/footer/LinksFooter/LinksFooter';
import { ToggleComponent } from '../../../../featureToggle';
import LinksFooter from './LinksFooter';
export * from './LinksFooter';

export default ToggleComponent(LinksFooter, {
  styledComponentsVariants: {
    'fresh-watch': LinksFooterFW,
    'fours-fringand-fr': LinksFooterFF,
  },
});
