import moment from 'moment';
import { Report } from './report.model';

export const reportsMock: Report[] = [
  {
    id: '1',
    createdDate: moment().subtract(1, 'days').valueOf(),
    store: 'Store 1',
    merchandisers: ['All'],
    city: 'City 1',
    region: 'Region 1',
    url: 'https://www.url-to-get-pdf.com',
  },
  {
    id: '2',
    createdDate: moment().subtract(1, 'month').valueOf(),
    store: 'Store 2',
    merchandisers: ['All'],
    city: 'City 2',
    region: 'Region 2',
    url: 'https://www.url-to-get-pdf.com',
  },
  {
    id: '3',
    createdDate: new Date().getTime(),
    store: 'Store 3',
    merchandisers: ['All'],
    city: 'City 3',
    region: 'Region 3',
    url: 'https://www.url-to-get-pdf.com',
  },
  {
    id: '4',
    createdDate: moment().subtract(3, 'hours').valueOf(),
    store: 'Store 4',
    merchandisers: ['MErchandiser 4'],
    city: 'City 4',
    region: 'Region 4',
    url: 'https://www.url-to-get-pdf.com',
  },
  {
    id: '5',
    createdDate: moment().subtract(1, 'year').subtract(4, 'months').valueOf(),
    store: 'Store 5',
    merchandisers: ['MErchandiser 5'],
    city: 'City 5',
    region: 'Region 5',
    url: 'https://www.url-to-get-pdf.com',
  },
  {
    id: '6',
    createdDate: moment().subtract(10, 'days').valueOf(),
    store: 'Store 6',
    merchandisers: ['MErchandiser 6'],
    city: 'City 6',
    region: 'Region 6',
    url: 'https://www.url-to-get-pdf.com',
  },
  {
    id: '7',
    createdDate: new Date().getTime(),
    store: 'Store 5',
    merchandisers: ['MErchandiser 7'],
    city: 'City 7',
    region: 'Region 7',
    url: 'https://www.url-to-get-pdf.com',
  },
  {
    id: '8',
    createdDate: moment().subtract(1, 'days').valueOf(),
    store: 'Store 6',
    merchandisers: ['MErchandiser 8'],
    city: 'City 8',
    region: 'Region 8',
    url: 'https://www.url-to-get-pdf.com',
  },
];
