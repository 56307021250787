import {
  Box,
  BoxProps,
  Button,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import { AppearanceIcon, GridIcon, ListIcon } from '../../icons';
import { getPolyglot } from '../../../../../i18n';
import styled from '@emotion/styled';

interface AppearanceSelectProps extends BoxProps {
  gridDisplay: boolean;
  onChangeAppearance: (displayGrid: boolean) => void;
}
const AppearanceSelect = (props: AppearanceSelectProps) => {
  const { gridDisplay, onChangeAppearance, ...rest } = props;
  const theme = useTheme();
  const changeAppearance = (displayGrid: boolean) => {
    onChangeAppearance(displayGrid);
  };
  const polyglot = getPolyglot();

  return (
    <Box {...rest}>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        gap="1rem"
        padding={'0 0.5rem'}
      >
        <Button
          variant="text"
          disableRipple={true}
          disableFocusRipple={true}
          sx={{
            color: theme.palette.common.black,
            cursor: 'default',
            '&:hover': {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
            minWidth: 'fit-content',
          }}
          startIcon={<AppearanceIcon fontSize="1rem" />}
        >
          <Typography color="common.black" fontSize={'1rem'}>
            {polyglot.t('freshwatch.dashboard.appareance')}
          </Typography>
        </Button>
      </Box>
      <StyledBackgroundBox display={'flex'} active={gridDisplay}>
        <SelectedIconButton
          size="large"
          active={gridDisplay}
          onClick={() => changeAppearance(true)}
        >
          <GridIcon color="inherit" />
        </SelectedIconButton>
        <SelectedIconButton
          active={!gridDisplay}
          size="large"
          onClick={() => changeAppearance(false)}
        >
          <ListIcon color="inherit" />
        </SelectedIconButton>
      </StyledBackgroundBox>
    </Box>
  );
};

export default AppearanceSelect;

const StyledBackgroundBox = styled(Box)<{ active: boolean }>(
  ({ theme, active }) => ({
    background: theme.palette.neutral.dark,
    width: 'fit-content',
    margin: '0',
    borderRadius: '3rem',
    padding: active ? '0 0.5rem 0 0' : '0 0 0 0.5rem',
    gap: '0.2rem',
  })
);
const SelectedIconButton = styled(IconButton)<{ active: boolean }>(
  ({ theme, active }) => ({
    backgroundColor: `${active ? theme.palette.primary.main : 'transparent'}`,
    color: `${
      active ? theme.palette.common.white : theme.palette.primary.main
    }`,
    height: '3.5rem',
    width: '3.5rem',
    '&:hover': {
      backgroundColor: active
        ? theme.palette.primary.main
        : theme.palette.neutral.main,
      color: active ? theme.palette.common.white : theme.palette.primary.main,
    },
    borderRadius: '100%',
    padding: '1rem',
    boxShadow: 'none',
  })
);
