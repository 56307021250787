import React from 'react';
import { Group } from '../../../../../../redux/groups/api/group.model';
import { Box, alpha } from '@mui/material';
import GroupContactAction from './GroupContactAction';
import GroupHistoryAction from './GroupHistoryAction';
import GroupRecipesAction from './GroupRecipesAction';
import GroupCamerasAction from './GroupCamerasAction';
import ActionsIcon from '../../../../../../theme/icons/ActionsIcon';
import { ButtonIcon, MenuPrimary } from '../../../../../../theme/components';
import GroupSettingsAction from './GroupSettingsAction';
import GroupAlarmsAction from './GroupAlarmsAction';
import GroupComposableDeviceAction from './GroupComposableDeviceAction';
import { GroupComposableDashboardAction } from './GroupComposableDashboardAction';
import GroupDashboardAction from './GroupDashboardAction';
import isComposableDevice from '../../../../../../redux/composableDevice/util/isComposableDevice';

export interface GroupActionsProps {
  group?: Group;
  variant?: 'icon' | 'menuItem';
}
export function GroupActionsList(props: GroupActionsProps) {
  const { group } = props;
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'flex-end',
        fontSize: '1.8em',
        gap: '1.4em',
        color: (theme) => theme.typography.body1.color,
      })}
    >
      <GroupDashboardAction group={group} />
      <GroupContactAction group={group} />
      <GroupSettingsAction group={group} />
      <GroupHistoryAction group={group} />
      <GroupRecipesAction group={group} />
      <GroupCamerasAction group={group} />
      <GroupComposableDeviceAction group={group} />
      <GroupComposableDashboardAction group={group} />
      <GroupAlarmsAction group={group} />
    </Box>
  );
}

export function GroupActionsGrid(props: GroupActionsProps) {
  const { group } = props;
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        alignContent: 'center',
        fontSize: '1.6em',
        color: (theme) => theme.typography.body1.color,
      }}
      onClick={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <GroupDashboardAction group={group} />
      <GroupContactAction group={group} />
      <GroupSettingsAction group={group} />
      <GroupHistoryAction group={group} />
      <GroupRecipesAction group={group} />
      <GroupCamerasAction group={group} />
      <GroupAlarmsAction group={group} />
      <GroupActionsMenu group={group} />
    </Box>
  );
}

export function GroupActionsMenu(props: GroupActionsProps) {
  const { group } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const showMenu = isComposableDevice(group);
  if (!showMenu) return null;
  return (
    <>
      <ButtonIcon
        onClick={handleClick}
        aria-controls={open ? 'actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        sx={{
          bgcolor: (theme) =>
            open ? alpha(theme.palette.primary.main, 0.1) : undefined,
        }}
      >
        <ActionsIcon />
      </ButtonIcon>

      <MenuPrimary
        anchorEl={anchorEl}
        id="actions-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        <GroupComposableDeviceAction group={group} variant="menuItem" />
        <GroupComposableDashboardAction group={group} variant="menuItem" />
      </MenuPrimary>
    </>
  );
}
