import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import {
  DashboardFilters,
  dashboardFiltersModelReducer,
} from '../dashboardFilters.model';

export const initialState: DashboardFilters = {
  regions: [],
  stores: [],
  merchandisers: [],
  timePeriod: {
    initial: moment().subtract(1, 'week').valueOf(),
    end: new Date().getTime(),
  },
};

export const DashboardFiltersSlice = createSlice({
  name: dashboardFiltersModelReducer,
  initialState,
  reducers: {
    updateFilters: (state, action) => {
      Object.assign(state, action.payload);
    },
    resetFilters: () => initialState,
  },
});

export const {
  actions: dashboardFiltersActions,
  reducer: reducerDashboardFilters,
  name: nameReducerDashboardFilters,
} = DashboardFiltersSlice;
