import { ZipDeviceControlUnit } from '../../../../redux/controlUnit/controlUnit.model';
import { Device } from '../../../../redux/devices/api/device.model';
import { Base64 } from '../../../../util/Base64';
import { CHANNELS_METRICS } from './mock/constants';

export const getWorkingTimeMetrics = (
  devicesControlUnitsSource: ZipDeviceControlUnit[],
  commanders: Device[]
) => {
  return commanders.map((commander, index) => {
    return {
      name: `working-time-${commander.uuid}`,
      channels:
        devicesControlUnitsSource[index].controlUnits
          ?.filter((item) => item.id !== '1002')
          .map((item) => {
            return {
              deviceID: Base64.decode(commander.uuid),
              cuID: item.id,
              channelID: 'On',
            };
          }) ?? [],
      metric: CHANNELS_METRICS.TIME_ON,
      options: {},
    } as ReportRealTimeSpec['metrics'][0];
  });
};
