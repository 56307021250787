import React from 'react';
import {
  CardActionArea,
  CardActionAreaProps,
  Tooltip,
  styled,
} from '@mui/material';

export interface InfoCardProps extends CardActionAreaProps {
  infoTitle: React.ReactNode;
  subtitle: React.ReactNode;
  variant?: 'small' | 'big';
  error?: boolean;
  children?: React.ReactNode;
  href?: string;
}
const InfoCard = (props: InfoCardProps) => {
  const { infoTitle, subtitle, error, variant, children, ...rest } = props;
  return (
    <Wrapper
      sx={{
        width: '100%',
        maxWidth: variant === 'big' ? '466px' : '225px',
      }}
      {...rest}
    >
      <Tooltip title={infoTitle}>
        <Title error={error}>{infoTitle}</Title>
      </Tooltip>
      {typeof subtitle === 'string' ? (
        <Subtitle error={error}>{subtitle}</Subtitle>
      ) : (
        subtitle
      )}
      {children}
    </Wrapper>
  );
};

const Wrapper = styled(CardActionArea)`
  text-align: center;
  border-radius: 1.5rem;
  margin: 0.5rem;
  overflow: hidden;
  .MuiCardActionArea-focusHighlight {
    background: transparent;
  }
`;
const Title = styled('div')<{ error?: boolean }>(
  ({ error, theme }) => `
  font-size: 1rem;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
  padding: 0.5rem 1rem;
  color: ${theme.palette.primary.contrastText};
  background: ${theme.palette.primary.main};
  border-radius: 1.5rem 1.5rem 0 0;
  ${
    error &&
    `
    background: ${theme.palette.error.main};
    `
  }
`
);

const Subtitle = styled('div')<{ error?: boolean }>(
  ({ error, theme }) => `
  font-size: 0.9rem;
  color: ${theme.palette.primary.contrastText};
  background: ${theme.palette.primary.dark};
  padding: 0.24rem;
  ${
    error &&
    `
    background: ${theme.palette.error.dark};
    `
  }
`
);

export default InfoCard;
