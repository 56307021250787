import { Group } from '../../../../redux/groups/api/group.model';
import { Merchandiser } from '../composableDevice/model/Merchandiser.model';

export interface DashboardFilters {
  regions: Group[];
  stores: Group[];
  merchandisers: Merchandiser[];
  timePeriod: {
    initial: number;
    end: number;
  };
}
export const dashboardFiltersModelReducer = 'dashboardFilters';
