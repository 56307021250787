import React, { useCallback, useMemo } from 'react';

import { getPolyglot } from '../../../i18n';

import { GridColDef } from '@mui/x-data-grid';
import { Link as LinkRouter } from 'react-router-dom';
import { ButtonBase, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Base64 } from '../../../util/Base64';
import { PolicyAPIResponse } from '../../../redux/incident/api/Policy.model';
import { Device } from '../../../redux/devices/api/device.model';
import { useAppSelector } from '../../../redux/store.model';
import { getControlUnitByIdGetterSelector } from '../../../redux/controlUnit/selectors/table';
import AlarmsListItem from '../AlarmBox/AlarmsListItem';
import { AlarmRedCercleIcon, CheckGreenCercleIcon } from '../../../theme/icons';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import ControlUnitText from '../../DeviceManager/ControlUnit/ControlUnitPage/ControlUnitText/ControlUnitText';

interface IncidentListProps {
  workspaceId: string;
  policiesData: PolicyAPIResponse[] | undefined;
  devices: Device[];
}
export function useIncidentsDefDataGrid(props: IncidentListProps) {
  const { workspaceId, policiesData, devices } = props;
  const polyglot = getPolyglot();
  const getControlUnit = useAppSelector(getControlUnitByIdGetterSelector);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const initialState: GridInitialStateCommunity = {};
  const rowHeight = isMobile ? 200 : undefined;
  const getRowHeight = useCallback(() => rowHeight, [rowHeight]);

  const columns: GridColDef[] = useMemo(
    () =>
      isMobile
        ? [
            // Use timestamp as principal cell to render the row
            // to have a sortable behavior on timestamp
            {
              field: 'timestamp',
              align: 'left',
              flex: 1,
              headerName: polyglot.t('incident.table_header_title'),
              sortable: false,
              renderCell: (params) => {
                const item = params.row;
                return (
                  <ButtonBase
                    component={LinkRouter}
                    to={`/alarm/${item.uuid}`}
                    sx={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <AlarmsListItem
                      deviceId={item.deviceId}
                      device={
                        Object.values(devices).find(
                          (device) =>
                            Base64.decode(device.uuid) === item.deviceId
                        )?.name ?? ''
                      }
                      resolutionTimestamp={item.resolutionTimestamp}
                      creationTimestamp={item.timestamp}
                      error={!item.resolutionTimestamp}
                      title={
                        item.eventTranslation?.shortText ||
                        item.event ||
                        item.summary
                      }
                      controlUnit={item.cu}
                    />
                  </ButtonBase>
                );
              },
            },
          ]
        : [
            {
              field: 'status',
              align: 'center',
              headerName: polyglot.t('incident.status'),
              sortable: false,

              renderCell: (params) => {
                const item = params.row;
                return item && item?.resolutionTimestamp ? (
                  <CheckGreenCercleIcon height={'2em'} />
                ) : (
                  <AlarmRedCercleIcon height={'2em'} />
                );
              },
            },
            {
              field: 'device',
              align: 'left',
              flex: 1.5,
              headerName: polyglot.t('incident.device'),
              valueGetter: (params) => {
                const item = params.row;
                return Object.values(devices).find(
                  (device) => Base64.decode(device.uuid) === item.deviceId
                )?.name;
              },
            },
            {
              field: 'cu',
              align: 'left',
              headerName: polyglot.t('incident.cu'),
              sortable: false,
              flex: 0.7,
              valueGetter: (params) => {
                const item = params.row;
                const cu = getControlUnit({
                  deviceId: Base64.encode(item.deviceId),
                  controlUnitId: item.cu,
                });

                return cu?.name || item.cu;
              },
              renderCell: (params) => {
                const item = params.row;
                return (
                  <ControlUnitText
                    deviceId={item?.deviceId}
                    controlUnitId={item?.cu}
                  />
                );
              },
            },
            {
              field: 'title',
              align: 'left',
              headerName: polyglot.t('incident.summary'),
              sortable: false,
              flex: 2.5,
              valueGetter: (params) => {
                const item = params.row;
                const title = item.eventTranslation?.shortText;
                return title || item.event || item.summary;
              },
            },
            {
              field: 'timestamp',
              align: 'left',
              headerName: polyglot.t('incident.created_at'),
              sortable: false,
              width: 220,
              renderCell: (params) => (
                <Typography
                  variant="h6"
                  textAlign={'left'}
                  sx={{ whiteSpace: 'break-spaces' }}
                >
                  {new Date(params.value).toLocaleString()}
                </Typography>
              ),
            },
          ],
    [isMobile, devices, getControlUnit, policiesData, polyglot, workspaceId]
  );
  return { columns, disableColumnMenu: isMobile, initialState, getRowHeight };
}
