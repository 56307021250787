import React from 'react';
import { DialogModal, DialogModalProps } from '../../../../../theme/components';
import { useFormik } from 'formik';
import {
  FormControl,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { getPolyglot } from '../../../../../i18n';
import { Group } from '../../../../../redux/groups/api/group.model';
import { Merchandiser } from '../../../redux/composableDevice/model/Merchandiser.model';
import StyledButton from '../StyledButton/StyledButton';
import MultiSelectAutocomplete from '../../../../../theme/components/Forms/MultiSelectAutocomplete';
import { getMerchandisersOfStores } from '../../../redux/dashboardFilters/hooks/useDashboardFilters';
import * as Yup from 'yup';
import moment from 'moment';
import { ErrorText } from '../../../../../theme/components/Forms';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  ReportCreateForm,
  useLazyGenerateReportQuery,
} from '../../../redux/reports/api/ReportsGenerationAPI';
import InformationModal from '../InformationModal/InformationModal';
import useStateBoolean from '../../../../../util/hooks/useStateBoolean';
import { DateTimePicker, PickersActionBarProps } from '@mui/x-date-pickers';
import { CalendarIcon } from '../../icons';

const initialValues: ReportCreateForm = {
  stores: [],
  initialPeriod: moment().toISOString(),
  endPeriod: moment().toISOString(),
  merchandisers: [],
  topics: [],
  formats: [],
};
const validationSchema = Yup.object().shape({
  stores: Yup.array().min(1, 'reports.errors.select_stores'),
  merchandisers: Yup.array().min(1, 'reports.errors.select_merchandisers'),
  topics: Yup.array().min(1, 'reports.errors.select_topics'),
  formats: Yup.array().min(1, 'reports.errors.select_formats'),
  initialPeriod: Yup.date()
    .max(Yup.ref('endPeriod'), 'reports.errors.initial_period_after_end_period')
    .required('reports.errors.select_initial_period'),
  endPeriod: Yup.lazy(() =>
    Yup.date()
      .min(
        Yup.ref('initialPeriod'),
        'reports.errors.end_period_before_initial_period'
      )
      .required('reports.errors.select_end_period')
  ),
});
enum Topic {
  'OverTemperature' = 'OverTemperature',
  'Sales' = 'Sales',
  'Stock' = 'Stock',
  'Performance' = 'Performance',
}
enum Format {
  'pdf' = 'pdf',
  'csv' = 'csv',
}
interface NewReportModalProps extends DialogModalProps {
  city: Group | null;
  stores: Group[];
  merchandisers: Merchandiser[];
}
const NewReportModal = (props: NewReportModalProps) => {
  const { city, onClose: closeModal, ...modalProps } = props;
  const polyglot = getPolyglot();

  const [trigger, { isLoading, isError, isSuccess }] =
    useLazyGenerateReportQuery();
  const [isOpenResultDialog, openResultDialog, closeResultDialog] =
    useStateBoolean(false);

  const topics = Object.values(Topic);
  const formats = Object.values(Format);
  const formik = useFormik<ReportCreateForm>({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers) => {
      helpers.setSubmitting(true);
      await trigger({ ...values });
      openResultDialog();
    },
  });

  const updateFormikValue = (field: string, value: any) => {
    formik.setFieldTouched(field, true);
    formik.setFieldValue(field, value);
  };

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <DialogModal
        fullWidth
        PaperProps={{
          sx: {
            maxWidth: smallScreen ? '90%' : '60%',
            minWidth: smallScreen ? '90%' : '60%',
          },
        }}
        loading={formik.isSubmitting}
        onClose={closeModal}
        buttonActions={
          <>
            <StyledButton
              disabled={formik.isSubmitting}
              variant="outlined"
              color="primary"
              onClick={closeModal}
            >
              {polyglot.t('button.cancel')}
            </StyledButton>
            <StyledButton
              disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
              variant="contained"
              color="primary"
              type="submit"
              onClick={formik.submitForm}
              fullWidth
            >
              {polyglot.t('button.create')}
            </StyledButton>
          </>
        }
        {...modalProps}
      >
        <Typography variant="body1">
          {city?.name} - {polyglot.t('reports.create_report')}
        </Typography>
        {!formik.isSubmitting ? (
          <form onSubmit={formik.handleSubmit} noValidate>
            <Stack
              spacing={2}
              mt={2}
              display={'grid'}
              gridTemplateColumns={smallScreen ? '1fr' : '1fr 1fr'}
              gap={2}
            >
              <FormControlStyled>
                <MultiSelectAutocomplete
                  required
                  placeholder={polyglot.t('freshwatch.dashboard.select_store')}
                  allOptionsSelectedLabel={polyglot.t(
                    'freshwatch.dashboard.all_stores'
                  )}
                  label={polyglot.t('freshwatch.dashboard.stores')}
                  options={props.stores}
                  displayResultFn={(option) => option.name}
                  labelFn={(option) => option.name}
                  equalFn={(option, value) => option.id === value.id}
                  onSelectionChange={(values) =>
                    updateFormikValue('stores', values)
                  }
                />
                {formik.errors.stores && (
                  <ErrorText>
                    {polyglot.t(formik.errors.stores as string)}
                  </ErrorText>
                )}
              </FormControlStyled>
              <FormControlStyled>
                <MultiSelectAutocomplete
                  required
                  placeholder={polyglot.t(
                    'freshwatch.dashboard.select_merchandiser'
                  )}
                  allOptionsSelectedLabel={polyglot.t(
                    'freshwatch.dashboard.all_merchandisers'
                  )}
                  label={polyglot.t('freshwatch.dashboard.merchandisers')}
                  options={getMerchandisersOfStores(
                    formik.values.stores,
                    props.merchandisers
                  )}
                  onSelectionChange={(values) =>
                    updateFormikValue('merchandisers', values)
                  }
                  displayResultFn={(option) => option.name}
                  labelFn={(option) => option.name}
                  equalFn={(option, value) => option.id === value.id}
                />
                {formik.touched.merchandisers &&
                  formik.errors.merchandisers && (
                    <ErrorText>
                      {polyglot.t(formik.errors.merchandisers as string)}
                    </ErrorText>
                  )}
              </FormControlStyled>

              <FormControlStyled>
                <MultiSelectAutocomplete
                  required
                  label={polyglot.t('reports.topics')}
                  placeholder={polyglot.t('reports.select_topic')}
                  allOptionsSelectedLabel={polyglot.t('reports.all_topics')}
                  options={topics}
                  onSelectionChange={(values) =>
                    updateFormikValue('topics', values)
                  }
                  displayResultFn={(option) => option}
                  labelFn={(option) => option}
                />
                {formik.touched.topics && formik.errors.topics && (
                  <ErrorText>
                    {polyglot.t(formik.errors.topics as string)}
                  </ErrorText>
                )}
              </FormControlStyled>
              <FormControlStyled>
                <MultiSelectAutocomplete
                  required
                  placeholder={polyglot.t('reports.select_formats')}
                  allOptionsSelectedLabel={polyglot.t('reports.all_formats')}
                  label={polyglot.t('reports.formats')}
                  options={formats}
                  onSelectionChange={(values) =>
                    updateFormikValue('formats', values)
                  }
                  displayResultFn={(option) => option}
                  labelFn={(option) => option}
                />
                {formik.touched.formats && formik.errors.formats && (
                  <ErrorText>
                    {polyglot.t(formik.errors.formats as string)}
                  </ErrorText>
                )}
              </FormControlStyled>

              <FormControlStyled>
                <DateTimePicker
                  value={moment(formik.values.initialPeriod)}
                  onChange={(value) => {
                    updateFormikValue('initialPeriod', value?.toISOString());
                  }}
                  label={polyglot.t('reports.initial_period')}
                  disableFuture
                  slots={{
                    openPickerIcon: CalendarIcon,
                    actionBar: ActionList,
                  }}
                  sx={{
                    svg: { height: '1.2rem', marginRight: '0.5rem' },
                  }}
                />
                {formik.touched.initialPeriod &&
                  formik.errors.initialPeriod && (
                    <ErrorText>
                      {polyglot.t(formik.errors.initialPeriod as string)}
                    </ErrorText>
                  )}
              </FormControlStyled>
              <FormControlStyled>
                <DateTimePicker
                  value={moment(formik.values.endPeriod)}
                  onChange={(value) => {
                    updateFormikValue('endPeriod', value?.toISOString());
                  }}
                  label={polyglot.t('reports.final_period')}
                  disableFuture
                  minDate={moment(formik.values.initialPeriod)}
                  slots={{
                    openPickerIcon: CalendarIcon,
                    actionBar: ActionList,
                  }}
                  sx={{
                    svg: { height: '1.2rem', marginRight: '0.5rem' },
                  }}
                />
                {formik.touched?.endPeriod && formik.errors?.endPeriod && (
                  <ErrorText>
                    {polyglot.t(formik.errors.endPeriod as string)}
                  </ErrorText>
                )}
              </FormControlStyled>
            </Stack>
          </form>
        ) : (
          <Stack display={'flex'} alignItems={'center'}>
            <LoadingButton color="primary" loading={formik.isSubmitting} />
            <Typography variant={'body2'} color="common.gray">
              {polyglot.t('reports.creating_report')}
            </Typography>
          </Stack>
        )}
      </DialogModal>
      <InformationModal
        open={isOpenResultDialog}
        isError={isError}
        isLoading={isLoading}
        isSuccess={isSuccess}
        onClose={() => {
          closeResultDialog();
          if (isSuccess) {
            closeModal && closeModal();
          }
        }}
      />
    </>
  );
};

export default NewReportModal;

const FormControlStyled = styled(FormControl)`
  width: 100%;
  align-self: start;
  margin-top: 0 !important;
  padding: 1rem 0;
`;
function ActionList(props: PickersActionBarProps) {
  const { onAccept, className } = props;
  const polyglot = getPolyglot();
  const actions = [{ text: polyglot.t('button.ok'), method: onAccept }];
  return (
    <Stack
      direction="row"
      spacing={1}
      padding={'0.5rem'}
      className={className}
      justifyContent={'end'}
    >
      {actions.map((action) => (
        <StyledButton key={action.text} onClick={action.method}>
          {action.text}
        </StyledButton>
      ))}
    </Stack>
  );
}
