import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store.model';
import ControlUnitAPI from '../api/ControlUnitAPI';
import produce from 'immer';
import { forEachLevel2 } from '../../../util/types/Records';
import { SensordataNewestMixed } from '../../monitoring/api/monitoring.model';
import { filterNotEmpty } from '../../../util/ArrayUtil';
import { Base64 } from '../../../util/Base64';
import { getControlUnitById } from '.';

export const getControlUnitsSensorDataByCommanderId = (
  state: RootState,
  props: { deviceId: string; controlUnitId: string }
) =>
  ControlUnitAPI.endpoints.getCurrentSensorDataByCommanderID.select(
    props.deviceId
  )(state).data;

export const getControlUnitsSensorDataByControlUnitId = (
  state: RootState,
  props: { deviceId: string; controlUnitId: string }
) =>
  getControlUnitsSensorDataByCommanderId(state, props)?.[props.controlUnitId];

export const getSensorDataMetadataSelector = createSelector(
  [getControlUnitById],
  (controlUnit) => controlUnit?.channels
);

export const getMixedSensordataFromId = createSelector(
  [getControlUnitsSensorDataByControlUnitId, getSensorDataMetadataSelector],
  (sensorData = {}, channels) =>
    produce(sensorData, (draft) => {
      Object.values(draft)
        .filter(filterNotEmpty)
        .forEach((currentValue: SensordataNewestMixed) => {
          if (currentValue)
            currentValue.metadata = channels?.find(
              (channel) => channel.id === currentValue.channel
            );
        });
    }) as Record<string, SensordataNewestMixed>
);
