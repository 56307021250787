import { Group, GroupCreateAPI } from '../api/group.model';
import GroupsCalls from '../api/GroupCalls';
import GroupsModel from '../api/GroupModel';

import { AppDispatch, AppStore } from '../../store.model';
import { fetchUsers } from '../../users/actions';
import {
  fetchDevicesByIds,
  loadDevicesAssociated,
} from '../../devices/actions/thunks';

import { IconUpload } from '../groups.model';
import { getGroups } from '../selectors';
import { actionsGroups } from '../slice/groupsSlice';
import { initializeLoadGroupsDevicesPeriodically } from '..';
import GroupModel from '../api/GroupModel';
import { fetchGroup, fetchGroups } from './thunks-creator';
import { UserService } from '../../users/api/UserService';
import fetchOwnRights from '../../rights/actions';
import { getMyUserId } from '../../auth/selectors';

// Simple thunks
/**
 * warmUpGroups:
 * All the thing that groups need to show te information
 * @returns
 */
export const warmUpGroups =
  () => async (dispatch: AppDispatch, getState: AppStore['getState']) => {
    if (getState().groups.activatedLoadingGroupsDevices) return;
    await dispatch(actionsGroups.setIsFirstLoad(true));
    await dispatch(actionsGroups.activatedLoadingGroupsDevices(true));
    await dispatch(loadGroupsDevices());
    await dispatch(actionsGroups.setIsFirstLoad(false));
    initializeLoadGroupsDevicesPeriodically();
  };

/**
 * Load groups
 * Fetch groups, devices and users
 */
export const loadGroups = () => async (dispatch: AppDispatch) => {
  const groups: Group[] = await dispatch<any>(fetchGroups()).unwrap();
  if (Array.isArray(groups)) {
    // get Ids
    const devicesIds = GroupModel.getAllDevicesIdFromGroups(groups);
    const usersIds = GroupModel.getAllUsersIdFromGroups(groups);

    // fetch devices and user, but not wait for the response
    dispatch(fetchDevicesByIds(devicesIds));
    dispatch(fetchUsers(usersIds));
  }
};

/**
 * Load group
 * Fetch group, devices and users
 */
export const loadGroup = (groupId: string) => async (dispatch: AppDispatch) => {
  const group: Group = await dispatch<any>(fetchGroup({ groupId })).unwrap();
  if (group) {
    // get Ids
    const devicesIds = GroupModel.getAllDevicesIdFromGroups([], group);
    const usersIds = GroupModel.getAllUsersIdFromGroups([], group);

    // fetch devices and user, but not wait for the response
    dispatch(fetchDevicesByIds(devicesIds));
    dispatch(fetchUsers(usersIds));
  }
};

/**
 * Load groups and devices
 */
export const loadGroupsDevices = () => async (dispatch: AppDispatch) => {
  return await Promise.all([
    dispatch(loadGroups()),
    dispatch<any>(loadDevicesAssociated()).unwrap(),
  ]);
};

/**
 * Get group
 * this function returns the group where and incidentId is located. if the
 * array groups is empty, then the groups will be loaded
 * @param string incidentId
 */
export const fetchGroupByIncidentId =
  (incidentId: string) =>
  async (dispatch: AppDispatch, getState: AppStore['getState']) => {
    let groups = getGroups(getState());
    if (groups.length === 0) {
      await dispatch(loadGroupsDevices());
      groups = getGroups(getState());
    }
  };

/**
 * Post group: call the backend to add a new group
 * @param object groupData
 * @param object iconUpload
 * @param string parentGroupID
 */
export const postGroup =
  (
    groupData: GroupCreateAPI,
    iconUpload: IconUpload | undefined,
    parentGroupID?: string
  ) =>
  async (dispatch: AppDispatch, getState: AppStore['getState']) => {
    const groupsCalls = new GroupsCalls();
    const groupsModel = new GroupsModel();
    const users = [getMyUserId(getState())];
    const groupResponse = await groupsCalls.postGroup(
      groupData,
      users,
      iconUpload
    );
    const group = groupsModel.parseFromAPI([groupResponse])[0];
    // fetchOwnRights because rights of the new group need be downloaded
    await dispatch(fetchOwnRights());

    return dispatch(actionsGroups.addGroup({ parentGroupID, group }));
  };

/**
 * Put group: call the backend to update a group
 * @param string groupId
 * @param object groupData
 * @param object iconUpload
 */
export const putGroup =
  (
    groupId: string,
    groupData: Partial<GroupCreateAPI>,
    iconUpload?: IconUpload
  ) =>
  async (dispatch: AppDispatch) => {
    const groupsCalls = new GroupsCalls();
    const groupResponse = await groupsCalls.putGroup(
      groupId,
      groupData,
      iconUpload
    );

    dispatch(
      actionsGroups.updateGroup({ groupId, partialGroup: groupResponse })
    );
  };

/**
 * Put group users with roles: call the backend to update a group adding a users with roles
 */
export const putGroupUsersWithRoles =
  (groupId: string, usersIds: string[], rolesIds: string[]) =>
  async (dispatch: AppDispatch) => {
    const userService = new UserService();
    await userService.putRolesGroupUsers(usersIds, rolesIds, groupId);
    dispatch(fetchUsers(usersIds));
    dispatch(actionsGroups.addGroupUser({ groupId, usersIds }));
  };
/**
 * Put group users: call the backend to update a group adding a users
 * @param string groupId
 * @param array usersId
 */
export const putGroupUsers =
  (groupId: string, usersIds: string[]) => async (dispatch: AppDispatch) => {
    const groupsCalls = new GroupsCalls();
    await groupsCalls.putGroupUsers(groupId, usersIds);
    dispatch(fetchUsers(usersIds));
    dispatch(actionsGroups.addGroupUser({ groupId, usersIds }));
  };

/**
 * Put group users: call the backend to update a group adding a users
 * @param string groupId
 * @param array emails
 */
export const putGroupUsersByEmail =
  (groupId: string, emails: string[]) => async (dispatch: AppDispatch) => {
    const groupsCalls = new GroupsCalls();
    await groupsCalls.putGroupUsers(groupId, emails);
    const usersIds = await groupsCalls.getUsersByGroupId(groupId);
    dispatch(fetchUsers(usersIds));
    dispatch(actionsGroups.addGroupUser({ groupId, usersIds }));
  };

/**
 * Delete group: call the backend to delete a  group
 * @param string groupId
 */
export const deleteGroup =
  (groupId: string) => async (dispatch: AppDispatch) => {
    const groupsCalls = new GroupsCalls();
    await groupsCalls.deleteGroup(groupId);
    dispatch(actionsGroups.deleteGroup(groupId));
    await dispatch(loadGroups());
  };

/**
 * Delete group icon : call the backend to delete group's icon
 * @param string groupId
 */
export const deleteGroupIcon =
  (groupId: string) => async (dispatch: AppDispatch) => {
    const groupsCalls = new GroupsCalls();
    await groupsCalls.deleteGroupIcon(groupId);
    dispatch(
      actionsGroups.updateGroup({ groupId, partialGroup: { iconURL: null } })
    );
  };

/**
 * Delete group user : call the backend to delete group's user
 * @param string groupId
 * @param string userId
 */
export const deleteGroupUser =
  (groupId: string, userId: string) => async (dispatch: AppDispatch) => {
    const groupsCalls = new GroupsCalls();

    await groupsCalls.deleteGroupUser(groupId, userId);
    dispatch(actionsGroups.deleteGroupUser({ groupId, userId }));
  };

/**
 * Delete group device : call the backend to delete group's device
 * @param string groupId
 * @param string deviceId
 */
export const deleteGroupDevice =
  (groupId: string, deviceId: string) =>
  async (dispatch: AppDispatch, getState: AppStore['getState']) => {
    const groupsCalls = new GroupsCalls();
    await groupsCalls.deleteGroupDevice(groupId, deviceId);
    await dispatch(actionsGroups.deleteGroupDevice({ groupId, deviceId }));
    /**
     * if the device is not associated with another group, then we should add it to
     * the associated devices
     * FIXME: not confired if this is the right place to do it, maybe backend would do it
     */
    // const groups = getGroupsByDeviceIdByPropsSelector(getState(), { deviceId });
    // if (groups.length === 0) {
    //   await dispatch(actionsDevices.addAssociateDevices(deviceId));
    // }
  };
