import React from 'react';
import { Box, Button, styled, Typography, useTheme } from '@mui/material';
import { getPolyglot } from '../../../../../i18n';
import { RegionDetails } from '../../../redux/composableDevice/model/SCCGroup.model';
import InfoCard from '../InfoCard/InfoCard';

const CityStoresCard = (props: RegionDetails) => {
  const { city, citiesDetails } = props;

  const polyglot = getPolyglot();
  const theme = useTheme();

  const summaryStores = citiesDetails.length;
  const summaryMerchandisers = citiesDetails.reduce(
    (acc, city) => acc + city.merchandisers.length,
    0
  );
  const summaryAlarms = citiesDetails.reduce(
    (acc, city) => acc + city.alarms.length,
    0
  );

  const subheader = (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '4fr 1fr 1fr',
        padding: `0.5rem ${
          summaryStores > 5 ? '2rem' : '0.8rem'
        } 0.5rem 0.5rem`,
        background: theme.palette.neutral.main,
        color: theme.palette.common.black,
      }}
    >
      <Typography
        color="common.black"
        padding={'0 0 0 1.5rem'}
        textAlign="start"
        fontSize={'1rem'}
      >
        {polyglot.t('freshwatch.dashboard.stores')}
      </Typography>
      <Typography fontSize={'1rem'} color="common.black">
        {polyglot.t('freshwatch.dashboard.merchandisers')}
      </Typography>
      <Typography fontSize={'1rem'} color="common.black">
        {polyglot.t('freshwatch.dashboard.alarms')}
      </Typography>
    </Box>
  );

  return (
    <InfoCard
      sx={{
        width: '100%',
        maxWidth: '100%',
        margin: 0,
        height: '100%',
        cursor: 'default',
      }}
      infoTitle={city.name}
      subtitle={subheader}
      disableRipple={true}
      error={citiesDetails.some((city) => city.alarms.length > 0)}
    >
      <Box
        sx={{
          height: 'calc(100% - 105px)',
          background: theme.palette.common.white,
          overflow: 'auto',
        }}
      >
        {citiesDetails.length === 0 ? (
          <Typography
            color="common.black"
            component={'p'}
            textAlign="start"
            padding={'1.5rem'}
          >
            {polyglot.t('device.search.message_no_results_found')}
          </Typography>
        ) : (
          citiesDetails.map((city) => {
            return (
              <StoreItem
                href={`store/${city.store.id}`}
                key={city.store.id}
                error={city.alarms.length > 0}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'grid',
                    gridTemplateColumns: '4fr 1fr 1fr',
                  }}
                >
                  <Typography
                    variant="h6"
                    textAlign="start"
                    padding={'0 0 0 1rem'}
                  >
                    {city.store.name}
                  </Typography>
                  <Typography variant="h6">
                    {city.merchandisers.length}
                  </Typography>
                  <Typography variant="h6">{city.alarms.length}</Typography>
                </Box>
              </StoreItem>
            );
          })
        )}
      </Box>
      <Box
        sx={{
          background: theme.palette.common.white,
          textAlign: 'end',
          padding: '0.2rem 1rem',
          borderTop: `2px solid ${theme.palette.neutral.main}`,
        }}
      >
        <Typography variant="caption" fontSize={'0.8rem'}>
          {`${polyglot.t('freshwatch.dashboard.stores')}: ${summaryStores} -
          ${polyglot.t('freshwatch.dashboard.merchandisers')}:
          ${summaryMerchandisers} - ${polyglot.t(
            'freshwatch.dashboard.alarms'
          )}: ${summaryAlarms}`}
        </Typography>
      </Box>
    </InfoCard>
  );
};

const StoreItem = styled(Button)<{ error?: boolean }>(
  ({ error, theme }) => `
  width:100%;
  border-radius: 0;
  color: ${theme.palette.common.black};
  background: ${theme.palette.common.white};
  border-bottom: 2px solid ${theme.palette.neutral.main};
  font-weight: 400;
  :hover {
    background: ${theme.palette.secondary.light};
    color: ${theme.palette.primary.main};
  }
  ${
    error &&
    `
    background: ${theme.palette.error.contrastText};
    color: ${theme.palette.error.main};
   :hover {
      background: ${theme.palette.error.light};
      color: ${theme.palette.error.main};
  }
  `
  }
`
);

export default CityStoresCard;
