import { createSelector } from '@reduxjs/toolkit';
import { Device } from '../../devices/api/device.model';
import { Group } from '../api/group.model';
import GroupModel from '../api/GroupModel';
import { getControlUnitQueryParam } from '../../controlUnit/selectors';
import {
  getAllDevicesIds,
  getAssociatedDevices,
  getDeviceIdFromQueryParam,
  getDevicesByIds,
} from '../../devices/selectors';
import { RootState } from '../../store.model';
import { getLevelOfView } from './getLevelOfView';
import { RouterComponentProps } from '../../../util/route-dom';

export type RouterComponentPropsGroup = Pick<
  RouterComponentProps<{
    groupId?: string;
    merchandiserId?: string;
  }>,
  'params'
>;
/**
 * Get devices group
 * recursive function to find all devices that belong to the group, including the
 * devices of the child groups
 * @param array groups
 * @return array devices
 */
export const getDevicesGroup = (
  state: RootState,
  groups: Group[],
  individualGroup?: Group | undefined
): Device[] => {
  const devicesIds = GroupModel.getAllDevicesIdFromGroups(
    groups,
    individualGroup
  );
  return getDevicesByIds(state, { devicesIds });
};

export const getGroups = (state: RootState) => state.groups.treeGroups;
export const getIndividualGroup = (state: RootState) =>
  state.groups.individualGroup;
export const isGroupsLoading = (state: RootState) => state.groups.loading;
export const isGroupsFirstLoad = (state: RootState) => state.groups.firstLoad;
export const getDeviceIdFromProps = (
  _: RootState,
  props: { deviceId: string }
) => props.deviceId;

export const getGroupIdQueryParam = (
  _: RootState,
  props: RouterComponentPropsGroup
) => props.params.groupId || props.params.merchandiserId;
export const getGroupIdProps = (
  _: RootState,
  props: {
    groupId?: string;
  }
) => props.groupId;

export const getGroupsByDeviceIdByPropsSelector = createSelector(
  [getDeviceIdFromProps, getGroups, getIndividualGroup],
  GroupModel.getGroupsByDeviceId
);

export const getGroupSelectedByQueryParamSelector = createSelector(
  [getGroupIdQueryParam, getGroups],
  GroupModel.getGroupByGroupId
);

export const getDevicesFromGroupSelectedByQueryParamSelector = (
  state: RootState,
  props: RouterComponentPropsGroup
): Device[] => {
  const group = getGroupSelectedByQueryParamSelector(state, props);
  if (group) {
    return getDevicesByIds(state, { devicesIds: group.devices });
  }
  return getDevicesByIds(state, { devicesIds: getAssociatedDevices(state) });
};

export const getGroupByPropGroupIdSelector = createSelector(
  [getGroupIdProps, getGroups],
  GroupModel.getGroupByGroupId
);

export const getPathToGroupByPropGroupIdSelector = createSelector(
  [getGroupByPropGroupIdSelector, getGroups],
  GroupModel.getPathToGroup
);

export const getPathGroupIdToGroupByPropGroupIdSelector = (
  state: RootState,
  props: {
    groupId?: string;
  }
) => getPathToGroupByPropGroupIdSelector(state, props).map((path) => path.id);

export const getAllUsersIdOnGroupsSelector = createSelector(
  [getGroups, getIndividualGroup],
  GroupModel.getAllUsersIdFromGroups
);

export const getAllDevicesIdsOnGroupsSelector = createSelector(
  [getGroups, getIndividualGroup],
  GroupModel.getAllDevicesIdFromGroups
);

const emptyGroup: Group[] = [];
const getEmptyGroup = () => emptyGroup;
export const getAllDevicesIdsOnGroupsByGroupIdQueryParamSelector =
  createSelector(
    [getEmptyGroup, getGroupSelectedByQueryParamSelector],
    GroupModel.getAllDevicesIdFromGroups
  );

export const getAllDevicesIdsOnGroupsByGroupIdPropSelector = createSelector(
  [getEmptyGroup, getGroupByPropGroupIdSelector],
  GroupModel.getAllDevicesIdFromGroups
);

export const getAllDevicesIdsByMode = createSelector(
  [
    getEmptyGroup,
    getGroupSelectedByQueryParamSelector,
    getGroups,
    getGroupIdQueryParam,
    getDeviceIdFromQueryParam,
    getControlUnitQueryParam,
  ],
  (emptyGroup, groupSelected, treeGroups, groupId, deviceId, controlUnitId) => {
    const level = getLevelOfView({ controlUnitId, deviceId, groupId });
    switch (level) {
      case 'CONTROL_UNIT':
        return [deviceId as string];
      case 'DEVICE':
        return [deviceId as string];
      case 'GROUP':
        return GroupModel.getAllDevicesIdFromGroups(emptyGroup, groupSelected);
      case 'ALL_GROUPS':
        return GroupModel.getAllDevicesIdFromGroups(treeGroups);
      default:
        return [];
    }
  }
);

export const getAllavailableDevicesIdsToAddByGroupIdSelector = createSelector(
  [getGroupByPropGroupIdSelector, getAllDevicesIds],
  (group, allDevicesIds) =>
    allDevicesIds.filter((id) => !group?.devices.includes(id))
);

/**
 * getAllavailableDevicesToAddByGroupIdSelector
 * @return allDevices - devicesGroup
 * Child are memoized, so it no need be memoized, because too much cache
 *
 * This function replace functionality of Set Options:
 * Set Options:
 * This function uses the arrays:
 * - devicesGroups (all the existing devices in the groups )
 * - devicesGroup (all the existing devices that belong to the group)
 * - devices (all the devices that are not associated to any group but with the user)
 * Returns an array with the devices options ( = devicesGroups + devices - devicesGroup)
 * This options are available devices to be associated to the group
 */
export const getAllAvailableDevicesToAddByGroupIdSelector = (
  state: RootState,
  props: { groupId?: string }
) => {
  const devicesIds = getAllavailableDevicesIdsToAddByGroupIdSelector(
    state,
    props
  );
  return getDevicesByIds(state, { devicesIds });
};

/**
 * getUnAssignedDevices
 * @return associatedDevices - allDevicesGroups
 * Child are memoized,so it no need be memoized, because too much cache
 */
export const getUnAssignedDevices = (state: RootState) => {
  const devicesIdsGroups = getAllDevicesIdsOnGroupsSelector(state);
  const associatedDevicesId = getAssociatedDevices(state);

  const devicesIds = associatedDevicesId.filter(
    (id) => !devicesIdsGroups.includes(id)
  );
  return getDevicesByIds(state, { devicesIds });
};
