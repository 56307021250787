import React, { ChangeEvent, useState } from 'react';
import {
  DialogModal,
  DialogModalProps,
} from '../../../../../../theme/components';
import {
  Box,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { getPolyglot } from '../../../../../../i18n';
import StyledButton from '../../StyledButton/StyledButton';

interface SelectFormatModalProps extends DialogModalProps {
  onFormatSelected: (format: AvailableFormats[]) => void;
}
export enum AvailableFormats {
  PDF = 'pdf',
  CSV = 'csv',
}

const SelectFormatModal = (props: SelectFormatModalProps) => {
  const { open, onFormatSelected, onClose } = props;

  const [selectedFormats, setSelectedFormats] = useState<AvailableFormats[]>(
    []
  );

  const onSelect = (_event: ChangeEvent<HTMLInputElement>, value: string) => {
    if (value === 'all') {
      setSelectedFormats(Object.values(AvailableFormats));
      return;
    }

    setSelectedFormats([value as AvailableFormats]);
  };

  const closeFormatDialog = () => {
    onFormatSelected(selectedFormats);
  };
  const polyglot = getPolyglot();

  return (
    <DialogModal
      {...props}
      open={open}
      PaperProps={{ sx: { borderRadius: '1.5rem' } }}
      buttonActions={
        <>
          <StyledButton
            variant="outlined"
            color="primary"
            onClick={onClose}
            sx={{ paddingX: '3rem' }}
          >
            {polyglot.t('button.cancel')}
          </StyledButton>
          <StyledButton
            variant="contained"
            color="primary"
            onClick={closeFormatDialog}
            sx={{ paddingX: '3rem' }}
          >
            {polyglot.t('button.download')}
          </StyledButton>
        </>
      }
    >
      <Stack spacing={1} minWidth={300} px={2}>
        <Typography variant="h2">
          {polyglot.t('reports.select_formats')}
        </Typography>
        <Typography>
          {polyglot.t('reports.select_formats_description')}
        </Typography>
        <Box m={1}>
          <Stack spacing={1}>
            <FormGroup>
              <RadioGroup
                aria-labelledby="radio-buttons-group-select-format-to-download"
                defaultValue="all"
                name="radio-buttons-download-format-group"
                onChange={onSelect}
              >
                <FormControlLabel
                  control={
                    <Radio
                      color="primary"
                      defaultChecked
                      value="all"
                      inputProps={{
                        'aria-label': 'select all formats',
                      }}
                    />
                  }
                  label={polyglot.t('reports.all_formats')}
                />
                {Object.values(AvailableFormats).map((format) => (
                  <FormControlLabel
                    key={format}
                    label={polyglot.t(`reports.${format}`)}
                    control={
                      <Radio
                        value={format}
                        inputProps={{
                          'aria-label': `select ${format}`,
                        }}
                      />
                    }
                  />
                ))}
              </RadioGroup>
            </FormGroup>
          </Stack>
        </Box>
      </Stack>
    </DialogModal>
  );
};

export default SelectFormatModal;
