/* eslint-disable prettier/prettier */
import React from 'react';
import {
  ChartsIcon,
  DashBoardIcon,
  MercIcon,
  PowerIcon,
  SettingsIcon,
} from '../../../../theme/icons';
import { Box, IconButton, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AlarmIcon } from '../../../../../../theme/icons';

const useButtons = () => {
  const navigate = useNavigate();
  return [
    // { icon: <DashBoardIcon />, view: 'dashboard' , onClick: () => navigate('dashboard')},
    { icon: <MercIcon />, view: 'detail' , onClick: () => navigate('detail')},
    { icon: <SettingsIcon />, view: 'settings', onClick: () => navigate('settings') },
    { icon: <ChartsIcon />, view: 'history', onClick: () => navigate('history') },
    { icon: <AlarmIcon />, view: 'alarms' , onClick: () => navigate('alarms') },
    // { icon: <PowerIcon />, view: 'power', onClick: () => alert('Action not supported') },
  ];
};

const StyledIconButton = styled(IconButton)<{ active: boolean }>(
  ({ theme, active }) => `
       background-color: ${
         active
           ? theme.palette.secondary.main
           : theme.palette.primary.contrastText
       };
      border-radius: 1rem;
      padding: 1rem;
      margin: 0.5rem;
      width: 4rem;
      `
);

interface MerchandiserActionsProps {
  moduleSelected?: string;
}
function MerchandiserActions(props: MerchandiserActionsProps) {
  const { moduleSelected } = props;
  const buttons = useButtons();
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'flex-end',
        fontSize: '1.8em',
        color: (theme) => theme.typography.body1.color,
      })}
      onClick={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
      onMouseEnter={(e) => e.stopPropagation()}
      onMouseLeave={(e) => e.stopPropagation()}
    >
      {buttons.map((button) => (
        <StyledIconButton
          sx={{ boxShadow: 2 }}
          key={button.view}
          active={moduleSelected === button.view}
          onClick={button.onClick}
          color='primary'
        >
          {button.icon}
        </StyledIconButton>
      ))}
    </Box>
  );
}

export default MerchandiserActions;
