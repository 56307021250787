import React from 'react';
import {
  Stack,
  Typography,
  TextField,
  CircularProgress,
  Box,
  Divider,
} from '@mui/material';
import { ErrorText } from '../../../theme/components/Forms';
import { useShowErrorMessage, useShowMessage } from '../../../util/hooks';
import { useAppDispatch, useAppSelector } from '../../../redux/store.model';
import { getPolyglot } from '../../../i18n';
import { Group, GroupCreateAPI } from '../../../redux/groups/api/group.model';
import { useFormik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { useDevices } from '../../../redux/devices/hooks/useDevices';
import {
  ST_COMPOSABLE_CONSUMPTION_DATA_KEY,
  ST_KILO_WATT_EUR_CONSUMPTION_KEY,
} from '../../../redux/composableDevice/ComposableDevice.model';
import {
  loadGroupsDevices,
  putGroup,
} from '../../../redux/groups/actions/thunks';
import {
  getConsumptionData,
  getConsumptionPriceKWh,
  setConsumption,
  setConsumptionPriceKWh,
} from '../../../redux/composableDevice/util/consumption';

interface ConsumptionFormProps {
  group?: Group;
}

interface IValuesConsumptionForm {
  [ST_COMPOSABLE_CONSUMPTION_DATA_KEY]: Record<string, number | undefined>;
  [ST_KILO_WATT_EUR_CONSUMPTION_KEY]: number | undefined;
}

export default function ConsumptionForm({ group }: ConsumptionFormProps) {
  const polyglot = getPolyglot();
  const dispatch = useAppDispatch();

  const showError = useShowErrorMessage();
  const showSuccess = useShowMessage();

  const [devices, isLoadingDevices] = useDevices(group?.devices ?? []);

  const initialValues: IValuesConsumptionForm = {
    [ST_COMPOSABLE_CONSUMPTION_DATA_KEY]: getConsumptionData(group),
    [ST_KILO_WATT_EUR_CONSUMPTION_KEY]: getConsumptionPriceKWh(group),
  };

  const formik = useFormik({
    initialValues: initialValues,

    onSubmit: async (values, helpers) => {
      try {
        let editedGroup = setConsumptionPriceKWh(
          group,
          values[ST_KILO_WATT_EUR_CONSUMPTION_KEY]
        );

        editedGroup = setConsumption(
          editedGroup,
          values[ST_COMPOSABLE_CONSUMPTION_DATA_KEY]
        );

        const groupData: GroupCreateAPI = {
          name: group?.name ?? '',
          composableDevice: editedGroup?.composableDevice,
        };

        await dispatch(putGroup(group?.id ?? '', groupData));
        await dispatch(loadGroupsDevices());
        showSuccess(polyglot.t('composable_device.save_successful_message'));
      } catch (error: any) {
        showError(polyglot.t('composable_device.failed_to_update'));
      }
    },
  });

  return (
    <Stack spacing={2}>
      {isLoadingDevices ? (
        <Box display={'flex'} justifyContent={'center'}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography variant="h2">
            {polyglot.t('composable_device.dashboard_consumption_setting')}
          </Typography>
          <Typography>
            {polyglot.t(
              'composable_device.dashboard_consumption_setting_description'
            )}
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2}>
              {devices.map((item) => (
                <TextField
                  key={`${ST_COMPOSABLE_CONSUMPTION_DATA_KEY}.${item.uuid}`}
                  name={`${ST_COMPOSABLE_CONSUMPTION_DATA_KEY}.${item.uuid}`}
                  type="number"
                  label={item.name?.concat(
                    ` ${polyglot.t(
                      'composable_device.dashboard_consumption_setting'
                    )}`
                  )}
                  onChange={formik.handleChange}
                  value={
                    formik.values[ST_COMPOSABLE_CONSUMPTION_DATA_KEY][item.uuid]
                  }
                  defaultValue={
                    formik.initialValues[ST_COMPOSABLE_CONSUMPTION_DATA_KEY][
                      item.uuid
                    ]
                  }
                  required
                  error={
                    formik.touched[ST_COMPOSABLE_CONSUMPTION_DATA_KEY]?.[
                      item.uuid
                    ] &&
                    Boolean(
                      formik.errors[ST_COMPOSABLE_CONSUMPTION_DATA_KEY]?.[
                        item.uuid
                      ]
                    )
                  }
                  helperText={
                    formik.touched[ST_COMPOSABLE_CONSUMPTION_DATA_KEY]?.[
                      item.uuid
                    ] &&
                    formik.errors[ST_COMPOSABLE_CONSUMPTION_DATA_KEY]?.[
                      item.uuid
                    ] && (
                      <ErrorText>
                        {polyglot.t(
                          formik.errors[ST_COMPOSABLE_CONSUMPTION_DATA_KEY]?.[
                            item.uuid
                          ] ?? ''
                        )}
                      </ErrorText>
                    )
                  }
                />
              ))}
              <Divider />
              <TextField
                key={ST_KILO_WATT_EUR_CONSUMPTION_KEY}
                name={ST_KILO_WATT_EUR_CONSUMPTION_KEY}
                type="number"
                label={polyglot.t(
                  'composable_device.dashboard_consumption_setting_kwh_price'
                )}
                onChange={formik.handleChange}
                value={formik.values[ST_KILO_WATT_EUR_CONSUMPTION_KEY]}
                defaultValue={
                  formik.initialValues[ST_KILO_WATT_EUR_CONSUMPTION_KEY]
                }
                required
                error={
                  formik.touched[ST_KILO_WATT_EUR_CONSUMPTION_KEY] &&
                  Boolean(formik.errors[ST_KILO_WATT_EUR_CONSUMPTION_KEY])
                }
                helperText={
                  formik.touched[ST_KILO_WATT_EUR_CONSUMPTION_KEY] &&
                  formik.errors[ST_KILO_WATT_EUR_CONSUMPTION_KEY] && (
                    <ErrorText>
                      {polyglot.t(
                        formik.errors[ST_KILO_WATT_EUR_CONSUMPTION_KEY] ?? ''
                      )}
                    </ErrorText>
                  )
                }
              />
              <LoadingButton
                color="primary"
                variant="contained"
                size="medium"
                type="submit"
                loading={formik.isSubmitting}
                onClick={formik.submitForm}
                startIcon={<SaveIcon />}
              >
                {polyglot.t('button.save')}
              </LoadingButton>
            </Stack>
          </form>
        </>
      )}
    </Stack>
  );
}
