import { useMemo } from 'react';
import { ZipDeviceControlUnit } from '../../../../redux/controlUnit/controlUnit.model';
import { Device } from '../../../../redux/devices/api/device.model';
import { Group } from '../../../../redux/groups/api/group.model';
import ReportRealTimeAPI from '../../../../redux/reports/api/ReportRealTimeAPI';
import {
  generateStartTimeFormattedDate,
  getColumnUnitsBySelectedTimeframe,
  getUnitsBySelectedTimeframe,
} from '../util/mock/Utils';
import _, { sum } from 'lodash';
import { getConsumptionsMetrics } from '../util/getConsumptionsMetrics';
import { getWorkingTimeMetrics } from '../util/getWorkingTimeMetrics';
import {
  getConsumptionData,
  getConsumptionPriceKWh,
} from '../../../../redux/composableDevice/util/consumption';

const emptyData = {
  hour: {
    max: '',
    min: '',
    avg: '',
    sum_pkwh: '',
  },
  day: {
    max: '',
    min: '',
    avg: '',
    sum_pkwh: '',
  },
  week: {
    max: '',
    min: '',
    avg: '',
    sum_pkwh: '',
  },
  month: {
    max: '',
    min: '',
    avg: '',
    sum_pkwh: '',
  },
  heatUp: '',
  recipesAvg: '',
};
export const useGetConsumptionSummary = (
  commanders: Device[],
  group: Group | undefined,
  devicesControlUnitsSource: ZipDeviceControlUnit[],
  skip: boolean
) => {
  const numberDecks = commanders.length;
  const composableDeviceConsumptions = getConsumptionData(group);
  const consumptionPriceKWh = getConsumptionPriceKWh(group);

  const consumptionMetrics = useMemo(
    () =>
      getConsumptionsMetrics(
        devicesControlUnitsSource,
        commanders,
        composableDeviceConsumptions
      ),
    [commanders, devicesControlUnitsSource, composableDeviceConsumptions]
  );

  const workingTimeMetrics = useMemo(
    () => getWorkingTimeMetrics(devicesControlUnitsSource, commanders),
    [commanders, devicesControlUnitsSource]
  );

  const {
    data: consumptionHourDataValues,
    isLoading: isLoadingHourReport,
    isFetching: isFetchingHourReport,
    error: errorHourReport,
  } = ReportRealTimeAPI.useGetReportsByGroupIdQuery(
    {
      encodedGroupId: group?.id ?? '',
      spec: {
        outputFormat: 'JSON',
        startTime: generateStartTimeFormattedDate('hour'),
        columnCount: getUnitsBySelectedTimeframe('hour'),
        columnDuration: 1,
        columnUnit: getColumnUnitsBySelectedTimeframe('hour'),
        metrics: consumptionMetrics,
      },
    },
    {
      skip,
    }
  );

  const {
    data: workingHourTimeDataValues,
    isLoading: isLoadingHourWorkingTime,
    isFetching: isFetchingHourWorkingTime,
    error: errorHourWorkingTime,
  } = ReportRealTimeAPI.useGetReportsByGroupIdQuery(
    {
      encodedGroupId: group?.id ?? '',
      spec: {
        outputFormat: 'JSON',
        startTime: generateStartTimeFormattedDate('hour'),
        columnCount: getUnitsBySelectedTimeframe('hour'),
        columnDuration: 1,
        columnUnit: getColumnUnitsBySelectedTimeframe('hour'),
        metrics: workingTimeMetrics,
      },
    },
    {
      skip,
    }
  );

  const {
    data: consumptionDayDataValues,
    isLoading: isLoadingDayReport,
    isFetching: isFetchingDayReport,
    error: errorDayReport,
  } = ReportRealTimeAPI.useGetReportsByGroupIdQuery(
    {
      encodedGroupId: group?.id ?? '',
      spec: {
        outputFormat: 'JSON',
        startTime: generateStartTimeFormattedDate('day'),
        columnCount: getUnitsBySelectedTimeframe('day'),
        columnDuration: 1,
        columnUnit: getColumnUnitsBySelectedTimeframe('day'),
        metrics: consumptionMetrics,
      },
    },
    {
      skip,
    }
  );

  const {
    data: workingDayTimeDataValues,
    isLoading: isLoadingWorkingTimeDay,
    isFetching: isFetchingWorkingTimeDay,
    error: errorWorkingTimeDay,
  } = ReportRealTimeAPI.useGetReportsByGroupIdQuery(
    {
      encodedGroupId: group?.id ?? '',
      spec: {
        outputFormat: 'JSON',
        startTime: generateStartTimeFormattedDate('day'),
        columnCount: getUnitsBySelectedTimeframe('day'),
        columnDuration: 1,
        columnUnit: getColumnUnitsBySelectedTimeframe('day'),
        metrics: workingTimeMetrics,
      },
    },
    {
      skip,
    }
  );

  //week
  const {
    data: consumptionWeekDataValues,
    isLoading: isLoadingWeekReport,
    isFetching: isFetchingWeekReport,
    error: errorWeekReport,
  } = ReportRealTimeAPI.useGetReportsByGroupIdQuery(
    {
      encodedGroupId: group?.id ?? '',
      spec: {
        outputFormat: 'JSON',
        startTime: generateStartTimeFormattedDate('week'),
        columnCount: getUnitsBySelectedTimeframe('week'),
        columnDuration: 1,
        columnUnit: getColumnUnitsBySelectedTimeframe('week'),
        metrics: consumptionMetrics,
      },
    },
    {
      skip,
    }
  );

  const {
    data: workingWeekTimeDataValues,
    isLoading: isLoadingWorkingTimeWeek,
    isFetching: isFetchingWorkingTimeWeek,
    error: errorWorkingTimeWeek,
  } = ReportRealTimeAPI.useGetReportsByGroupIdQuery(
    {
      encodedGroupId: group?.id ?? '',
      spec: {
        outputFormat: 'JSON',
        startTime: generateStartTimeFormattedDate('week'),
        columnCount: getUnitsBySelectedTimeframe('week'),
        columnDuration: 1,
        columnUnit: getColumnUnitsBySelectedTimeframe('week'),
        metrics: workingTimeMetrics,
      },
    },
    {
      skip,
    }
  );

  const {
    data: consumptionMonthDataValues,
    isLoading: isLoadingMonthReport,
    isFetching: isFetchingMonthReport,
    error: errorMonthReport,
  } = ReportRealTimeAPI.useGetReportsByGroupIdQuery(
    {
      encodedGroupId: group?.id ?? '',
      spec: {
        outputFormat: 'JSON',
        startTime: generateStartTimeFormattedDate('month'),
        columnCount: getUnitsBySelectedTimeframe('month'),
        columnDuration: 1,
        columnUnit: getColumnUnitsBySelectedTimeframe('month'),
        metrics: consumptionMetrics,
      },
    },
    {
      skip,
    }
  );

  const {
    data: workingMonthTimeDataValues,
    isLoading: isLoadingWorkingTimeMonth,
    isFetching: isFetchingWorkingTimeMonth,
    error: errorWorkingTimeMonth,
  } = ReportRealTimeAPI.useGetReportsByGroupIdQuery(
    {
      encodedGroupId: group?.id ?? '',
      spec: {
        outputFormat: 'JSON',
        startTime: generateStartTimeFormattedDate('month'),
        columnCount: getUnitsBySelectedTimeframe('month'),
        columnDuration: 1,
        columnUnit: getColumnUnitsBySelectedTimeframe('month'),
        metrics: workingTimeMetrics,
      },
    },
    {
      skip,
    }
  );

  const isLoading =
    isLoadingHourReport ||
    isLoadingDayReport ||
    isLoadingWeekReport ||
    isLoadingMonthReport ||
    isLoadingHourWorkingTime ||
    isLoadingWorkingTimeDay ||
    isLoadingWorkingTimeWeek;
  isLoadingWorkingTimeMonth;
  const error =
    errorHourReport ||
    errorDayReport ||
    errorWeekReport ||
    errorMonthReport ||
    errorHourWorkingTime ||
    errorWorkingTimeDay ||
    errorWorkingTimeWeek;
  errorWorkingTimeMonth;

  const isFetching =
    isFetchingHourReport ||
    isFetchingDayReport ||
    isFetchingWeekReport ||
    isFetchingMonthReport ||
    isFetchingHourWorkingTime ||
    isFetchingWorkingTimeDay ||
    isFetchingWorkingTimeWeek;
  isFetchingWorkingTimeMonth;

  const consumptionSummary = useMemo(() => {
    if (!isLoading && !commanders.length && !devicesControlUnitsSource.length) {
      return emptyData;
    }
    const hourItems = _.unzipWith(
      consumptionHourDataValues?.rows.map(
        (item: { values: number[] }) => item.values
      ),
      _.add
    );

    const dayItems = _.unzipWith(
      consumptionDayDataValues?.rows.map(
        (item: { values: number[] }) => item.values
      ),
      _.add
    );

    const weekItems = _.unzipWith(
      consumptionWeekDataValues?.rows.map(
        (item: { values: number[] }) => item.values
      ),
      _.add
    );

    const monthItems = _.unzipWith(
      consumptionMonthDataValues?.rows.map(
        (item: { values: number[] }) => item.values
      ),
      _.add
    );

    const hourWorkItems = _.unzipWith(
      workingHourTimeDataValues?.rows.map(
        (item: { values: number[] }) => item.values
      ),
      _.add
    );

    const dayWorkItems = _.unzipWith(
      workingDayTimeDataValues?.rows.map(
        (item: { values: number[] }) => item.values
      ),
      _.add
    );

    const weekWorkItems = _.unzipWith(
      workingWeekTimeDataValues?.rows.map(
        (item: { values: number[] }) => item.values
      ),
      _.add
    );

    const monthWorkItems = _.unzipWith(
      workingMonthTimeDataValues?.rows.map(
        (item: { values: number[] }) => item.values
      ),
      _.add
    );

    const sumHours = _.sum(hourItems);
    const sumDays = _.sum(dayItems);
    const sumWeeks = _.sum(weekItems);
    const sumMonths = _.sum(monthItems);

    const consumptionSummary = {
      hour: {
        max: `${(_.max(hourItems) ?? 0).toFixed(2)} kWh`,
        min: `${(_.min(hourItems) ?? 0).toFixed(2)} kWh`,
        avg: `${(sumHours / (_.sum(hourWorkItems) / 3600)).toFixed(2)} kWh`,
        sum_pkwh: `${(sumHours * consumptionPriceKWh).toFixed(2)}`,
      },
      day: {
        max: `${(_.max(dayItems) ?? 0).toFixed(2)} kWh`,
        min: `${(_.min(dayItems) ?? 0).toFixed(2)} kWh`,
        avg: `${(sumDays / (_.sum(dayWorkItems) / 3600)).toFixed(2)} kWh`,
        sum: `${sumDays.toFixed(2)} kWh`,
        sum_pkwh: `${(sumDays * consumptionPriceKWh).toFixed(2)}`,
      },
      week: {
        max: `${(_.max(weekItems) ?? 0).toFixed(2)} kWh`,
        min: `${(_.min(weekItems) ?? 0).toFixed(2)} kWh`,
        avg: `${(sumWeeks / (_.sum(weekWorkItems) / 3600)).toFixed(2)} kWh`,
        sum_pkwh: `${(sumWeeks * consumptionPriceKWh).toFixed(2)}`,
      },
      month: {
        max: `${(_.max(monthItems) ?? 0).toFixed(2)} kWh`,
        min: `${(_.min(monthItems) ?? 0).toFixed(2)} kWh`,
        avg: `${(sumMonths / (_.sum(monthWorkItems) / 3600)).toFixed(2)} kWh`,
        sum_pkwh: `${(sumMonths * consumptionPriceKWh).toFixed(2)}`,
      },
      heatUp: `${((8.66 / 4 / 4) * numberDecks).toFixed(2)} kWh`,
      recipesAvg: `${(12 * numberDecks).toFixed(0)} recipes`,
    };
    return consumptionSummary;
  }, [
    consumptionHourDataValues,
    consumptionDayDataValues,
    consumptionMonthDataValues,
    consumptionWeekDataValues,
    workingHourTimeDataValues,
    workingDayTimeDataValues,
    workingWeekTimeDataValues,
    workingMonthTimeDataValues,
    numberDecks,
  ]);
  return { consumptionSummary, error, isLoading, isFetching } as const;
};

export default useGetConsumptionSummary;
