import { TextField } from '@mui/material';
import React from 'react';
import { LoupeIcon } from '../../../theme/icons';
import { getPolyglot } from '../../../i18n';
import SearchEntitiesModal from './SearchEntitiesModal';
import useStateBoolean from '../../../util/hooks/useStateBoolean';

function SearchEntities() {
  const polyglot = getPolyglot();
  const [isOpen, open, close] = useStateBoolean(false);

  return (
    <>
      <TextField
        id="searchbar"
        name="searchbar"
        variant="outlined"
        fullWidth
        onClick={open}
        onKeyDown={open}
        onMouseDown={(e) => e.preventDefault()} // Prevent default behavior
        InputProps={{
          endAdornment: <LoupeIcon height={'1.5em'} />,
        }}
        label={polyglot.t('general.search')}
      />
      <SearchEntitiesModal open={isOpen} onClose={close} />
    </>
  );
}

export default SearchEntities;
