import React from 'react';
import { Box, Breadcrumbs, Link, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { ButtonBack } from '../../../theme/components';
import { useNavigate } from 'react-router-dom';

export interface BreadcrumbSection {
  key?: string | number;
  content: React.ReactNode;
  href: string;
  onClick?: () => void;
}

export default function Breadcrumb({
  sections,
}: {
  sections: BreadcrumbSection[];
}) {
  const renderSection = (section: BreadcrumbSection, index: number) => {
    if (index === sections.length - 1) {
      return (
        <Typography color="textPrimary" key={section.key ? section.key : index}>
          {section.content}
        </Typography>
      );
    }
    return (
      <Link
        color="inherit"
        href={section.href || '#'}
        onClick={section.onClick}
        key={section.key ? section.key : index}
      >
        {section.content}
      </Link>
    );
  };

  const navigate = useNavigate();
  return (
    <Box display={'flex'} alignItems={'center'}>
      <ButtonBack onClick={() => navigate(-1)} sx={{ minWidth: 'auto' }} />
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className="navigation-breadcrumb-new"
      >
        {sections.map((section, index) => renderSection(section, index))}
      </Breadcrumbs>
    </Box>
  );
}
