import React from 'react';
import { Grid, TextField } from '@mui/material';
import {
  AlphabetIcon,
  FilterListIcon,
  GridIcon,
  GridSmallIcon,
  ListIcon,
  LoupeIcon,
  PinIcon,
} from '../../icons';
import { ButtonIcon } from '../ButtonIcon';
import { getPolyglot } from '../../../i18n';
import SearchEntities from '../../../components/DeviceManager/SearchEntities';

export const VIEW_GRID = 'viewGrid';
export const VIEW_LIST = 'viewList';
export const VIEW_GRID_SMALL = 'viewGridSmall';
export const VIEW_MAP = 'viewMap';
export const SORT_FIELD_NAME = 'name';
export const SORT_FIELD_STATUS = 'statusValue';
export const ASC = 'ASC';
export const DESC = 'DESC';

export enum VIEW_TYPE {
  LIST = 'viewList',
  GRID = 'viewGrid',
  GRID_SMALL = 'viewGridSmall',
  MAP = 'viewMap',
}

export enum SORT_FIELD {
  NAME = 'name',
  STATUS = 'statusValue',
}

export enum SORT_ORDER {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface TopBarMenuProps {
  showSearch?: boolean;
  showViewMap?: boolean;
  showViewGridSmall?: boolean;
  showSortByStatus?: boolean;
  onSearchChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedView?: VIEW_TYPE;
  changeView: (mode: VIEW_TYPE) => void;
  onChangeSort: (sort: SORT_FIELD, order: SORT_ORDER) => void;
  sortField?: SORT_FIELD;
  sortOrder?: SORT_ORDER;
  buttons?: React.ReactNode[];
  menuAddButton?: React.ReactNode;
}
/**
 * TopBarMenu
 * This component shows a top bar menu with the following components:
 * * Search
 * * Button view list
 * * Button view grid
 * * Button view grid small
 * * Button map
 * * Button sort by name
 * * Button sort by status
 * * Button add
 *
 * The elements: search, button map,  button sort by status and button add are optional
 */
export function TopBarMenu(props: TopBarMenuProps) {
  const polyglot = getPolyglot();

  const {
    showSearch = true,
    showViewMap = true,
    showSortByStatus = true,
    showViewGridSmall = true,
    onSearchChange,
    changeView,
    selectedView,
    onChangeSort,
    sortField,
    sortOrder,
    buttons = [],
    menuAddButton,
  } = props;

  const getChangeView = (view: VIEW_TYPE) => () => {
    changeView(view);
  };

  const getChangeSort = (newSortField: SORT_FIELD) => () => {
    onChangeSort?.(
      newSortField,
      newSortField === sortField && sortOrder === SORT_ORDER.ASC
        ? SORT_ORDER.DESC
        : SORT_ORDER.ASC
    );
  };
  return (
    <Grid container spacing={1} mb={1} alignItems={'center'}>
      {/* Search button */}
      <Grid
        item
        container
        xs={12}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        {/* View list and grid */}
        {menuAddButton && (
          <Grid
            item
            container
            xs={2}
            md={6}
            justifyContent={{ xs: 'flex-end', md: 'flex-start' }}
            alignItems={'center'}
          >
            <div>{menuAddButton}</div>
          </Grid>
        )}
        {showSearch && (
          <Grid item xs={10} md={6}>
            <SearchEntities />
          </Grid>
        )}
      </Grid>

      <Grid
        item
        xs={12}
        alignItems={'center'}
        justifyContent={'flex-end'}
        flexWrap="wrap"
        flexGrow={1}
        display={'flex'}
        color={'primary.main'}
        sx={{ '& .MuiButton-root': { fontSize: '1.5rem' } }}
      >
        <ButtonIcon
          onClick={getChangeView(VIEW_TYPE.LIST)}
          selected={selectedView === VIEW_LIST}
          id="ButtonViewList"
        >
          <ListIcon />
        </ButtonIcon>
        <ButtonIcon
          onClick={getChangeView(VIEW_TYPE.GRID)}
          selected={selectedView === VIEW_GRID}
          id="ButtonViewGrid"
        >
          <GridIcon />
        </ButtonIcon>
        {showViewGridSmall && (
          <ButtonIcon
            onClick={getChangeView(VIEW_TYPE.GRID_SMALL)}
            selected={selectedView === VIEW_GRID_SMALL}
            id="ButtonViewGridSmall"
          >
            <GridSmallIcon />
          </ButtonIcon>
        )}
        {showViewMap && (
          <ButtonIcon
            onClick={getChangeView(VIEW_TYPE.MAP)}
            selected={selectedView === VIEW_MAP}
            id="ButtonViewMap"
          >
            <PinIcon />
          </ButtonIcon>
        )}

        <ButtonIcon
          onClick={getChangeSort(SORT_FIELD.NAME)}
          selected={sortField === SORT_FIELD.NAME}
          id="ButtonSortByName"
        >
          <AlphabetIcon />
        </ButtonIcon>
        {showSortByStatus && (
          <ButtonIcon
            onClick={getChangeSort(SORT_FIELD.STATUS)}
            selected={sortField === SORT_FIELD.STATUS}
            id="ButtonSortByStatus"
          >
            <FilterListIcon />
          </ButtonIcon>
        )}

        {buttons?.length > 0 && ' '}
        {buttons?.length > 0 &&
          buttons.map((button) => (
            // eslint-disable-next-line react/jsx-key
            <div
              className="top-bar-menu"
              style={{ display: 'inline-flex', flexDirection: 'row' }}
            >
              {button}{' '}
            </div>
          ))}
      </Grid>
    </Grid>
  );
}

export default TopBarMenu;
