import React, { useState } from 'react';
import { Report } from '../../../redux/reports/report.model';
import { Box, Stack } from '@mui/material';
import {
  LightTable,
  LightTableColumDef,
  Order,
  getRowValueDefault,
} from '../../../../../theme/components';
import moment from 'moment';
import StyledButton from '../StyledButton/StyledButton';
import { getPolyglot } from '../../../../../i18n';
import SelectFormatModal, {
  AvailableFormats,
} from './SelectFormatModal/SelectFormatModal';
import { useLazyDownloadReportsQuery } from '../../../redux/reports/api/ReportsGenerationAPI';
import InformationModal from '../InformationModal/InformationModal';
import useStateBoolean from '../../../../../util/hooks/useStateBoolean';

interface ReportsTableProps {
  reports: Report[];
}

function getComparatorDefault<RowModel>(
  getRowValue: (row: RowModel, field: keyof RowModel) => any
) {
  return (order: Order, orderBy: any) => (rowA: any, rowB: any) => {
    const valueA = getRowValue(rowA, orderBy);
    const valueB = getRowValue(rowB, orderBy);

    if (orderBy === 'created_date') {
      return order === 'desc'
        ? moment(valueA).diff(valueB)
        : -moment(valueA).diff(valueB);
    }

    return order === 'desc'
      ? valueA.localeCompare(valueB)
      : valueB.localeCompare(valueA);
  };
}

const ReportsTable = (props: ReportsTableProps) => {
  const { reports } = props;
  const [selectedItems, setSelectedItems] = useState<readonly unknown[]>([]);
  const [selectedReports, setSelectedReports] = useState<string[]>([]);
  const [isOpenFormatDialog, openFormatDialog, closeFormatDialog] =
    useStateBoolean(false);
  const [isOpenResultDialog, openResultDialog, closeResultDialog] =
    useStateBoolean(false);

  const [trigger, { isLoading, isError, isSuccess }] =
    useLazyDownloadReportsQuery();
  const polyglot = getPolyglot();

  const rows = reports.map((report) => ({
    id: report.id,
    created_date: new Date(report.createdDate).toLocaleDateString(),
    merchandisers: report.merchandisers.join(', '),
    store: report.store,
  }));

  const onDownloadReports = (ids: readonly unknown[]) => {
    setSelectedReports(ids as string[]);
    openFormatDialog();
  };

  const onSelectFormats = async (formats: AvailableFormats[]) => {
    closeFormatDialog();
    openResultDialog();
    await trigger({ reports: selectedReports, formats });
  };

  const columns: LightTableColumDef[] = [
    {
      field: 'created_date',
      headerName: polyglot.t('freshwatch.dashboard.created_date'),
      orderable: true,
    },
    {
      field: 'merchandisers',
      headerName: polyglot.t('freshwatch.dashboard.merchandisers'),
      orderable: true,
    },
    {
      field: 'store',
      headerName: polyglot.t('freshwatch.dashboard.stores'),
      orderable: true,
    },
  ];

  return (
    <>
      <Box maxHeight={'45vh'} sx={{ overflowY: 'auto' }}>
        <LightTable
          getComparator={getComparatorDefault(getRowValueDefault)}
          orderByDefault="created_date"
          columns={columns}
          rows={rows}
          displaySelect={true}
          onSelectChange={setSelectedItems}
        />
      </Box>
      <Stack
        direction="row"
        spacing={2}
        alignItems="baseline"
        justifyContent="flex-end"
        padding={'1rem 0 0 0'}
      >
        <StyledButton
          disabled={selectedItems.length === 0}
          variant="contained"
          color="primary"
          onClick={() => onDownloadReports(selectedItems)}
          sx={{ paddingX: '3rem' }}
        >
          {polyglot.t('button.download')}
        </StyledButton>
      </Stack>
      <SelectFormatModal
        onFormatSelected={onSelectFormats}
        open={isOpenFormatDialog}
        onClose={closeFormatDialog}
      />
      <InformationModal
        open={isOpenResultDialog}
        isError={isError}
        isLoading={isLoading}
        isSuccess={isSuccess}
        onClose={closeResultDialog}
      />
    </>
  );
};

export default ReportsTable;
