import { Box, Grid, Skeleton, Typography, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { useAppSelector } from '../../../../../../redux/store.model';
import { getAlarmActiveBySelector } from '../../../../../../redux/incident/selectors/getAlarmActiveBySelector';
import AlarmCard, {
  SEVERITY,
} from '../../../../theme/components/AlarmCard/AlarmCard';
import AlarmTable from '../../../../theme/components/AlarmTable/AlarmTable';
import { getPolyglot } from '../../../../../../i18n';
import { useParams } from 'react-router-dom';
import { AlarmIcon } from '../../../../../../theme/icons';
interface MerchandiseOverviewProps {
  gridDisplay: boolean;
}
function AlarmsOverview(props: MerchandiseOverviewProps) {
  const { gridDisplay } = props;
  const theme = useTheme();
  const polyglot = getPolyglot();
  const params = useParams<{ groupId: string }>();

  const { data: alarmsActive, isLoading: isLoadingAlarmsActive } =
    useAppSelector((state) => {
      return getAlarmActiveBySelector(state, { groupId: params.groupId });
    });

  const alarms = useMemo(
    () =>
      alarmsActive.map((alarm) => ({
        title:
          alarm.eventTranslation?.shortText ??
          alarm.event ??
          alarm.summary ??
          '',
        description: alarm.eventTranslation?.longText ?? '',
        timestamp: alarm.timestamp,
        task: `${alarm.event}.task_to_solve`,
        severity: SEVERITY.HIGH,
        uuid: alarm.uuid,
      })),
    [alarmsActive]
  );
  const headerTitles = [
    polyglot.t('freshwatch.dashboard.detail.product_name'),
    polyglot.t('freshwatch.dashboard.detail.description'),
    polyglot.t('freshwatch.dashboard.detail.created_at'),
    polyglot.t('freshwatch.dashboard.detail.solve_task'),
  ];

  let content = gridDisplay ? (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      {alarms.length ? (
        alarms.map((alarm) => <AlarmCard key={alarm.title} {...alarm} />)
      ) : (
        <Typography>
          {polyglot.t('device.search.message_no_results_found')}
        </Typography>
      )}
    </Box>
  ) : (
    <AlarmTable data={alarms} headerTitles={headerTitles} />
  );
  if (isLoadingAlarmsActive) {
    content = <Skeleton variant="rectangular" height={200} width={'100%'} />;
  }
  return (
    <>
      <Grid item xs={12}>
        <Box display={'flex'} alignItems={'center'} gap="1rem" padding={'1rem'}>
          <AlarmIcon fontSize="1.5rem" color={theme.palette.common.black} />
          <Typography color="common.black" fontWeight="600">
            {polyglot.t('freshwatch.dashboard.alarms')}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {content}
      </Grid>
    </>
  );
}

export default AlarmsOverview;
