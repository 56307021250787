import React from 'react';
import { getDevices } from '../../devices/selectors';
import { createSelector } from '@reduxjs/toolkit';
import { getAllGroupsWithChainGroups } from '../../groups/selectors/searchSelectors';
import { filterNotEmpty } from '../../../util/ArrayUtil';

export const getAllDeviceWithChainGroups = createSelector(
  [getAllGroupsWithChainGroups, getDevices],
  (allGroups, devices) => {
    return allGroups.flatMap((group) => {
      const chainGroups = [...group.chainGroups, group];
      return group.devices
        .map((deviceId) => devices.find((device) => device.uuid === deviceId))
        .filter(filterNotEmpty)
        .map((device) => ({
          ...device,
          chainGroups,
        }));
    });
  }
);
