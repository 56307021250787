import React from 'react';
import { Badge, ListItemIcon } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AlarmIcon } from '../../../../../../theme/icons';
import { getPolyglot } from '../../../../../../i18n';
import {
  ButtonIcon,
  MenuItemPrimary,
} from '../../../../../../theme/components';
import { GroupActionsProps } from './GroupActions';
import { useAppSelector } from '../../../../../../redux/store.model';
import { getAlarmActiveBySelector } from '../../../../../../redux/incident/selectors/getAlarmActiveBySelector';
import showAlarmsSelector from '../../../../../../redux/incident/selectors/showAlarmsSelector';
import useModuleSelected from '../../../../navigations/useModuleSelected';

const iconId = 'alarms';
const icon = <AlarmIcon height={'1em'} />;
/**
 * Show the current numbers of alarms active in the device
 * Open all alarms of the device
 */
export function GroupAlarmsAction(props: GroupActionsProps) {
  const { group, variant } = props;
  const groupId = group?.id;
  const polyglot = getPolyglot();
  const navigate = useNavigate();

  const { data: alarmsActive, isLoading: isLoadingAlarmsActive } =
    useAppSelector((state) =>
      getAlarmActiveBySelector(state, {
        groupId: groupId,
      })
    );

  const showAlarms = useAppSelector(showAlarmsSelector);

  const tooltipContent = polyglot.t('incident.all_alarms');
  const onClick: React.MouseEventHandler<any> = (e) => {
    const url = `/devicemanager/group/${group?.id}/alarms`;
    navigate(url);
  };

  const moduleSelected = useModuleSelected();
  const selected = moduleSelected === 'alarms';

  if (!group) return null;
  if (!showAlarms) return null;

  return (
    <React.Fragment>
      {variant === 'menuItem' ? (
        <MenuItemPrimary
          onClick={onClick}
          className={`icon-${iconId} group-${groupId}`}
          selected={selected}
        >
          <ListItemIcon sx={{ color: 'inherit' }}>
            <Badge badgeContent={alarmsActive.length} color="error">
              {icon}
            </Badge>
          </ListItemIcon>
          {tooltipContent}
        </MenuItemPrimary>
      ) : (
        <ButtonIcon
          className={`icon-${iconId} group-${groupId}`}
          tooltip={tooltipContent}
          onClick={onClick}
          loading={isLoadingAlarmsActive}
          badge={alarmsActive.length}
          selected={selected}
          BadgeProps={{
            color: 'error',
          }}
        >
          {icon}
        </ButtonIcon>
      )}
    </React.Fragment>
  );
}

export default GroupAlarmsAction;
