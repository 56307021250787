import React, { useState } from 'react';
import { AddIcon } from '../../../theme/icons';
import { MenuItemPrimary, MenuPrimary } from '../../../theme/components';
import { getPolyglot } from '../../../i18n';
import { Group } from '../../../redux/groups/api/group.model';
import { useUserRightUtil } from '../../../util/rights';
import GroupAdd from '../Group/GroupAdd/GroupAdd';
import DeviceAdd from '../Device/DeviceAdd/DeviceAdd';
import GroupDeviceAssign from '../Group/GroupDeviceAssign/GroupDeviceAssign';
import { ConnectedInviteUserModal } from '../Device/InviteUser';
import { useShowMessage } from '../../../util/hooks';
import { IconButton } from '@mui/material';
import ComposableDeviceAdd from '../../ComposableDevice/ComposableDeviceAdd';
import { useAppSelector } from '../../../redux/store.model';
import { getFeatureToggle } from '../../../featureToggle';

interface AddButtonDeviceManagerProps {
  groupId?: string;
  group?: Group;
}
/**
 * Button menu to add
 * users
 * group
 * device
 */
function AddButtonDeviceManager(props: AddButtonDeviceManagerProps) {
  const { groupId, group } = props;
  const polyglot = getPolyglot();
  const showMessage = useShowMessage();

  const featureToggle = useAppSelector(getFeatureToggle);
  const showComposableDevice = featureToggle?.composableDevice;

  let showButtonAddDevice = false;
  let showButtonAddGroup = false;
  const rightsUserUtil = useUserRightUtil();
  if (!groupId) {
    /* If the user are in the high group level we should check in the masterScope if
    the user has the right Authorized User */
    showButtonAddGroup = rightsUserUtil.isAuthorizedUser();
    showButtonAddDevice = showButtonAddGroup;
  } else {
    showButtonAddDevice = rightsUserUtil.hasRightsToAssignGroupDevice(groupId);
    showButtonAddGroup = rightsUserUtil.hasRightsToUpdateGroup(groupId);
  }
  const [showAddUser, setShowAddUser] = useState(false);
  const [showAddGroup, setShowAddGroup] = useState(false);
  const [showAddDevice, setShowAddDevice] = useState(false);
  const [showGroupDeviceAssign, setShowGroupDeviceAssign] = useState(false);
  const [showAddComposableDevice, setShowAddComposableDevice] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  let handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddDevice = () => {
    return groupId ? setShowGroupDeviceAssign(true) : setShowAddDevice(true);
  };

  const menuButtonAdd = [];

  if (showButtonAddDevice) {
    menuButtonAdd.push({
      key: 'device.add',
      text: polyglot.t('device.add'),
      onClick: handleAddDevice,
    });
  }
  if (showComposableDevice) {
    menuButtonAdd.push({
      key: 'composable.device.add',
      text: polyglot.t('composable_device.add_composable_device'),
      onClick: () => setShowAddComposableDevice(true),
    });
  }
  if (showButtonAddGroup) {
    menuButtonAdd.push({
      key: 'group.add',
      text: polyglot.t('group.add'),
      onClick: () => setShowAddGroup(true),
    });
  }

  if (featureToggle?.inviteUser && showButtonAddGroup) {
    menuButtonAdd.push({
      key: 'group.users.assign',
      text: polyglot.t('group.users.invite_user_title'),
      onClick: () => setShowAddUser(true),
    });
  }

  if (menuButtonAdd.length === 0) {
    return null;
  }

  if (menuButtonAdd.length === 1) {
    handleClick = menuButtonAdd[0].onClick;
  }

  const onSuccessAddUser = () => {
    showMessage(polyglot.t('group.users.assign_several_successful_message'));
    setShowAddUser(false);
  };

  return (
    <>
      {showButtonAddGroup && (
        <GroupAdd
          groupIdParent={groupId}
          groupNameParent={group?.name}
          showAddGroup={showAddGroup}
          closeAddGroup={() => setShowAddGroup(false)}
        />
      )}
      {showAddComposableDevice && (
        <ComposableDeviceAdd
          showAddComposableDevice={showAddComposableDevice}
          closeAddComposableDevice={() => setShowAddComposableDevice(false)}
          groupNameParent={group?.name}
          groupIdParent={groupId}
        />
      )}
      {showButtonAddDevice && !groupId ? (
        <DeviceAdd
          showAddDevice={showAddDevice}
          closeAddDevice={() => setShowAddDevice(false)}
        />
      ) : (
        showButtonAddDevice && (
          <GroupDeviceAssign
            groupId={groupId}
            showGroupDeviceAssign={showGroupDeviceAssign}
            closeGroupDeviceAssign={() => setShowGroupDeviceAssign(false)}
          />
        )
      )}
      {showAddUser && (
        <ConnectedInviteUserModal
          open={showAddUser}
          onClose={() => setShowAddUser(false)}
          onSuccess={onSuccessAddUser}
        />
      )}
      <IconButton
        onClick={handleClick}
        aria-controls={open ? 'actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <AddIcon />
      </IconButton>

      <MenuPrimary
        anchorEl={anchorEl}
        id="actions-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        {menuButtonAdd.map((item) => (
          <MenuItemPrimary key={item.key} onClick={item.onClick}>
            {item.text}
          </MenuItemPrimary>
        ))}
      </MenuPrimary>
    </>
  );
}

export default AddButtonDeviceManager;
