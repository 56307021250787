import { Box, Grid, Typography, useTheme } from '@mui/material';
import StoreOverviewCard from '../StoreOverviewCard/StoreOverviewCard';
import React from 'react';
import StoreOverviewTable from '../StoreOverviewTable/StoreOverviewTable';
import { RegionDetails } from '../../../../redux/composableDevice/model/SCCGroup.model';
import { StoreIcon } from '../../../icons';
import { getPolyglot } from '../../../../../../i18n';

const StoreOverviewWrapper = (props: {
  storeWithMerchandisers: Map<string, RegionDetails>;
  gridDisplay: boolean;
}) => {
  const { storeWithMerchandisers, gridDisplay } = props;
  const theme = useTheme();
  const polyglot = getPolyglot();
  return (
    <>
      <Grid
        item
        xs={12}
        display={'flex'}
        alignItems={'center'}
        gap="1rem"
        padding={'0 1rem 1rem 1rem'}
      >
        <StoreIcon fontSize="1.5rem" color={theme.palette.common.black} />
        <Typography color="common.black" fontWeight={'600'}>
          {polyglot.t('freshwatch.dashboard.location_overview')}
        </Typography>
      </Grid>
      {gridDisplay ? (
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          {[...storeWithMerchandisers.keys()].map((storeId) => {
            const city = storeWithMerchandisers.get(storeId);
            if (!city) return null;
            const merchandisers = city.citiesDetails
              .map((city) => city.merchandisers)
              .flat();
            const alarms = city.citiesDetails.map((city) => city.alarms).flat();
            return (
              <StoreOverviewCard
                merchandisers={merchandisers}
                city={city.city}
                alarms={alarms}
                key={storeId}
              />
            );
          })}
        </Box>
      ) : (
        <StoreOverviewTable storeWithMerchandisers={storeWithMerchandisers} />
      )}
    </>
  );
};

export default StoreOverviewWrapper;
