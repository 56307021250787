import React from 'react';
import AuthLayout, {
  AuthLayoutProps,
} from '../../../../../components/auth/AuthLayout/AuthLayout';
import SC_background from '../../../assets/scc_login_image.jpg';

function AuthLayoutFW(props: AuthLayoutProps) {
  return (
    <AuthLayout {...props} imageRight={SC_background}>
      {props.children}
    </AuthLayout>
  );
}

export default AuthLayoutFW;
