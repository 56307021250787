import React from 'react';
import { Merchandiser } from '../../../redux/composableDevice/model/Merchandiser.model';
import { useAppSelector } from '../../../../../redux/store.model';
import { getAlarmActiveBySelector } from '../../../../../redux/incident/selectors/getAlarmActiveBySelector';
import { ListItem } from '../../../../../theme/components';
import { DeviceSoloIcon } from '../../../../../theme/icons';
import MerchandiserActions from './MerchandiserActions';

interface MerchandiserSubheaderProps {
  merchandiserId?: string;
  merchandiser?: Merchandiser;
  loading?: boolean;
  moduleSelected: string | '';
}

export function MerchandiserSubheader(props: MerchandiserSubheaderProps) {
  const { merchandiser, moduleSelected, merchandiserId, loading } = props;

  return (
    <ListItem
      id={merchandiserId}
      name={merchandiser?.name ?? ''}
      loading={loading}
      iconURL={merchandiser?.iconURL}
      subtitle={`${merchandiser?.machineType?.labels?.businessFamily ?? ''} - ${
        merchandiser?.machineType?.labels?.businessGroup ?? ''
      }`}
      iconNode={!merchandiser?.iconURL && <DeviceSoloIcon />}
      buttons={<MerchandiserActions moduleSelected={moduleSelected} />}
      menuButtons={<MerchandiserActions moduleSelected={moduleSelected} />}
      ItemContainerProps={{
        sx: {
          px: 3,
          backgroundColor: 'background.container',
        },
      }}
    />
  );
}

export default MerchandiserSubheader;
