import React from 'react';
import { CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import { getPolyglot } from '../../../../../i18n';
import {
  DialogModal,
  DialogModalProps,
  ModalContent,
} from '../../../../../theme/components';
import StyledButton from '../StyledButton/StyledButton';
import { ErrorIcon, SuccessIcon } from '../../icons';

interface InformationModalProps extends DialogModalProps {
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  successMessage?: string;
  successTitle?: string;
  errorMessage?: string;
  errorTitle?: string;
}
const InformationModal = ({
  open,
  onClose,
  isError,
  isLoading,
  isSuccess,
  successMessage = 'reports.success_description',
  successTitle = 'reports.success_title',
  errorMessage = 'reports.error_description',
  errorTitle = 'reports.error_title',
}: InformationModalProps) => {
  const polyglot = getPolyglot();
  const theme = useTheme();
  let resultInformation = null;
  let resultIcon = null;

  if (isLoading) {
    resultInformation = (
      <Typography variant="body1">{polyglot.t('general.loading')}</Typography>
    );
    resultIcon = <CircularProgress size={20} sx={{ color: 'primary.main' }} />;
  }
  if (isError) {
    resultIcon = <ErrorIcon fontSize="2rem" color={theme.palette.error.main} />;
    resultInformation = (
      <>
        <Typography variant="body1" color="common.black">
          {polyglot.t(errorTitle)}
        </Typography>
        <Typography variant="body2">{polyglot.t(errorMessage)}</Typography>
      </>
    );
  }
  if (isSuccess) {
    resultIcon = (
      <SuccessIcon fontSize="2rem" color={theme.palette.success.main} />
    );
    resultInformation = (
      <>
        <Typography variant="body1" color="common.black">
          {polyglot.t(successTitle)}
        </Typography>
        <Typography variant="body2">{polyglot.t(successMessage)}</Typography>
      </>
    );
  }

  return (
    <DialogModal
      onClose={onClose}
      open={open}
      fullWidth={true}
      PaperProps={{ sx: { borderRadius: '1.5rem' } }}
      buttonActions={
        <StyledButton
          variant="contained"
          color="primary"
          onClick={onClose}
          sx={{ paddingX: '3rem', gridColumn: 'span 2' }}
        >
          {polyglot.t('button.ok')}
        </StyledButton>
      }
    >
      <ModalContent icon={resultIcon}>
        <Stack direction="column" alignItems={'center'}>
          {resultInformation}
        </Stack>
      </ModalContent>
    </DialogModal>
  );
};

export default InformationModal;
