import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getPolyglot } from '../../../../i18n';
import { useAppSelector } from '../../../../redux/store.model';
import {
  getAllChildrenGroups,
  getGroups,
} from '../../../../redux/devices/selectors';
import { getGroupByPropGroupIdSelector } from '../../../../redux/groups/selectors';
import { getDashboardRightsSelector } from '../../../../redux/groups/selectors/groupRightsSelectors';

/** basing in the last item on the path */
export function getModuleSelected(pathname: string) {
  const list = pathname.split('/');
  const moduleSelected = list[list.length - 1] ?? '';
  return moduleSelected;
}

const dicTitles: Record<string, string> = {
  detail: 'freshwatch.dashboard.detail.detaile_title',
  alarms: 'incident_manager.title',
  recipes: 'recipe_manager.title',
  history: 'device.monitoring.title',
  settings: 'group.tooltip.open_window_edit',
};
/**
 * Navigation path
 * Build the navigation path used in the breadcrumb
 * @return array sectionsNavigation
 */
export function useNavigationPathMerchandiser(): {
  content: React.ReactNode;
  onClick: () => void;
  href: string;
  key: string;
}[] {
  const params = useParams<{ merchandiserId: string }>();
  const { merchandiserId } = params;
  const polyglot = getPolyglot();
  const navigate = useNavigate();
  const { canViewRegionalManagerDashboard } = useAppSelector(
    getDashboardRightsSelector
  );
  const location = useLocation();
  const groupId = merchandiserId;

  const groups = useAppSelector(getGroups);
  const group = useAppSelector((state) =>
    getGroupByPropGroupIdSelector(state, { groupId })
  );
  const moduleSelected = getModuleSelected(location.pathname);
  const path = useLocation().pathname;

  const sectionsNavigation = [
    {
      content: polyglot.t('nav.menu.dashboard'),
      onClick: () => navigate('/dashboard'),
      href: '/dashboard',
      key: 'dashboard',
    },
  ];

  /* Is user is a regional manager */
  if (canViewRegionalManagerDashboard) {
    const pathArray = path.split('/');
    const store = pathArray.findIndex((element) => element === 'store');
    if (store !== -1) {
      const storeId = pathArray[store + 1];
      const name =
        getAllChildrenGroups(groups, []).find((group) => group.id === storeId)
          ?.name || polyglot.t('freshwatch.dashboard.stores');
      sectionsNavigation.push({
        content: name,
        onClick: () => navigate(`/dashboard/store/${storeId}`),
        href: `/dashboard/store/${storeId}`,
        key: 'store',
      });
    }
  }

  /* build path */
  if (groupId && groups.length > 0 && group !== undefined) {
    const navigation = path.slice(0, path.lastIndexOf('/'));
    sectionsNavigation.push({
      content: group.name,
      onClick: () => navigate(navigation),
      href: navigation,
      key: 'merchandiser',
    });
  }

  /** module selected */
  if (moduleSelected && Object.keys(dicTitles).includes(moduleSelected ?? '')) {
    sectionsNavigation.push({
      content: polyglot.t(dicTitles[moduleSelected] ?? ''),
      onClick: () => navigate(`/merchandiser/${groupId}/${moduleSelected}`),
      href: `/merchandiser/${groupId}/${moduleSelected}`,
      key: moduleSelected,
    });
  }
  return sectionsNavigation;
}
