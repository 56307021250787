import React from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { getPolyglot } from '../../../i18n';
import { FormikProps } from 'formik';
import { ErrorText } from '../../../theme/components/Forms';
import TextFieldFile from '../../commons/TextFieldFile';
import MultipleSelect from '../../DeviceManager/Group/GroupSettingsPage/Incidents/MultipleSelect';
import { useAppSelector } from '../../../redux/store.model';

import { getAllAvailableDevicesToAddByGroupIdSelector } from '../../../redux/groups/selectors';

import { useShowErrorMessage } from '../../../util/hooks';

import { Base64 } from '../../../util/Base64';
import { IconUpload } from '../../../redux/groups/groups.model';
import { ComposableDeviceValues } from '../ComposableDeviceAdd';

interface ComposableDeviceFormProps {
  groupNameParent?: string;
  formik: FormikProps<ComposableDeviceValues>;
  setImage: React.Dispatch<React.SetStateAction<IconUpload | null>>;
  setLogo: React.Dispatch<React.SetStateAction<IconUpload | null>>;
  image: IconUpload | null;
  logo: IconUpload | null;
}

function ComposableDeviceFormGeneric({
  formik,
  image,
  setImage,
  logo,
  setLogo,
}: ComposableDeviceFormProps) {
  const MAX_SIZE_IMAGE_KBYTE = 8192;
  const KBYTE = 1024;

  const showError = useShowErrorMessage();

  const polyglot = getPolyglot();

  const devices = useAppSelector((state) =>
    getAllAvailableDevicesToAddByGroupIdSelector(state, {
      groupId: undefined,
    })
  );

  const handleImageChange = (
    file: React.ChangeEvent<HTMLInputElement>
  ): Promise<IconUpload | null> => {
    return new Promise((resolve) => {
      const iconFile = file.target.files && file.target.files[0];
      if (iconFile) {
        if (iconFile.type.indexOf('image') === -1) {
          /* Snackbar message error */
          const message = {
            header: '',
            text: polyglot.t('error.only_format_images'),
            type: 'error',
          };
          showError(message.text);
          resolve(null);
        }
        if (iconFile.size > MAX_SIZE_IMAGE_KBYTE * KBYTE) {
          const message = {
            header: '',
            text: `${polyglot.t(
              'error.images_size_restriction'
            )} ${MAX_SIZE_IMAGE_KBYTE} ${polyglot.t('general.KB')}`,
            type: 'error',
          };
          showError(message.text);
          resolve(null);
        }
        const fileReader = new FileReader();
        fileReader.onloadend = () => {
          const imageString = fileReader.result as string;
          resolve({
            fileName: iconFile.name,
            toolTip: `upload file ${iconFile.name}`,
            file: iconFile,
            imagePreviewUrl: imageString,
          });
        };
        fileReader.readAsDataURL(iconFile);
      } else {
        showError(polyglot.t('composable_device.failed_to_load'));
        resolve(null);
      }
    });
  };

  return (
    <>
      <TextField
        name="name"
        type="name"
        label={polyglot.t('composable_device.name')}
        onChange={formik.handleChange}
        value={formik.values.name}
        defaultValue={formik.initialValues.name}
        required
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={
          formik.touched.name &&
          formik.errors.name && (
            <ErrorText>{polyglot.t(formik.errors.name)}</ErrorText>
          )
        }
      />
      <TextField
        name="composableDevice.labels.model"
        label={polyglot.t('composable_device.model')}
        onChange={formik.handleChange}
        value={formik.values.composableDevice?.labels?.model ?? null}
        defaultValue={formik.initialValues.composableDevice?.labels?.model}
      />
      <TextField
        name="composableDevice.labels.description"
        type="description"
        multiline
        rows={2}
        label={polyglot.t('composable_device.description')}
        onChange={formik.handleChange}
        value={formik.values.composableDevice?.labels?.description ?? null}
        defaultValue={
          formik.initialValues.composableDevice?.labels?.description
        }
      />

      <MultipleSelect
        selectedOptions={formik.values.devices}
        setSelectedOptions={formik.handleChange}
        label={polyglot.t('composable_device.devices')}
        displayOptions={devices.map((device: any, index: number) => {
          return {
            id: index,
            value: Base64.decode(device.uuid),
            content: <Typography component={'span'}>{device.name}</Typography>,
          };
        })}
        SelectProps={{
          id: 'devices',
          name: 'devices',
        }}
      />
      {formik.errors.devices && <ErrorText>{formik.errors.devices}</ErrorText>}
      <Box display={'flex'} flexDirection={'row-reverse'} alignItems={'center'}>
        <TextFieldFile
          sx={{ width: '80%' }}
          id="imageURL"
          name="imageURL"
          label={polyglot.t('composable_device.image_url')}
          onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
            const loadedImage = await handleImageChange(e);
            formik.setFieldValue('imageURL', loadedImage?.imagePreviewUrl);
            setImage(loadedImage);
          }}
          value={formik.values.imageURL}
          defaultValue={formik.initialValues.imageURL}
          error={Boolean(formik.errors.imageURL)}
          helperText={
            formik.errors.imageURL
              ? null //imageError
              : `${polyglot.t(
                  'group.max_size'
                )}: ${MAX_SIZE_IMAGE_KBYTE} ${polyglot.t('general.KB')}`
          }
        />
        {image && image?.imagePreviewUrl ? (
          <Box
            component="img"
            sx={{
              mr: 1,
              verticalAlign: 'middle',
              width: '20%',
            }}
            src={image.imagePreviewUrl}
            alt="added logo"
          ></Box>
        ) : null}
      </Box>
      <Box display={'flex'} flexDirection={'row-reverse'} alignItems={'center'}>
        <TextFieldFile
          sx={{ width: '80%' }}
          id="logoURL"
          name="logoURL"
          label={polyglot.t('composable_device.logo_url')}
          onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
            const loadedLogo = await handleImageChange(e);
            formik.setFieldValue('logoURL', loadedLogo?.imagePreviewUrl);
            setLogo(loadedLogo);
          }}
          value={formik.values.logoURL}
          defaultValue={formik.initialValues.logoURL}
          error={Boolean(formik.errors.logoURL)}
          helperText={
            formik.errors.logoURL
              ? null //imageError
              : `${polyglot.t(
                  'group.max_size'
                )}: ${MAX_SIZE_IMAGE_KBYTE} ${polyglot.t('general.KB')}`
          }
        />
        {logo && logo.imagePreviewUrl ? (
          <Box
            component="img"
            sx={{ mr: 1, verticalAlign: 'middle', width: '20%' }}
            src={logo.imagePreviewUrl}
            alt="added model"
          ></Box>
        ) : null}
      </Box>
    </>
  );
}

export default ComposableDeviceFormGeneric;
