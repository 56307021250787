import React, { useState } from 'react';
import OverviewPanel from './Panels/OverviewPanel';
import { AccordionSummaryLight } from '../../../../../theme/components';
import {
  Accordion,
  accordionSummaryClasses,
  Box,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import RegionDetail from './RegionDetail/RegionDetail';
import { ArrowDownIcon, PinIcon, ReportIcon } from '../../../theme/icons';
import {
  useAlarmsByRegion,
  useDashboardInformation,
  useFilteredGroups,
} from '../../../redux/dashboardFilters/hooks/useDashboardFilters';
import AppearanceSelect from '../../../theme/components/AppearanceSelect/AppearanceSelect';
import { useNavigate } from 'react-router-dom';
import FilterPanel from './Panels/FilterPanel';
import { getPolyglot } from '../../../../../i18n';
import { Group } from '../../../../../redux/groups/api/group.model';
import { StyledActionButton } from '../../../theme/components/StyledActionButton/StyledActionButton';

const RegionalManagerDashboard = () => {
  const { regions, isLoading, merchandisers, stores } =
    useDashboardInformation();
  const [gridDisplay, setGridDisplay] = useState(true);

  const theme = useTheme();

  const filterState = useFilteredGroups(regions, stores, merchandisers);

  const navigate = useNavigate();
  const {
    alarmsByRegion,
    totalAlarms,
    isLoadingIncidents,
    isFetchingIncidents,
  } = useAlarmsByRegion();

  const largerScreens = useMediaQuery(theme.breakpoints.up('sm'));
  const media = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
  const polyglot = getPolyglot();

  return (
    <>
      <Grid sx={{ backgroundColor: 'common.white' }}>
        <FilterPanel
          regions={regions}
          stores={stores}
          merchandisers={merchandisers}
          displayTitle={true}
        />
      </Grid>
      <Grid
        container
        sx={{
          backgroundColor: 'neutral.light',
          padding: '1em',
          flexWrap: largerScreens ? 'nowrap' : 'wrap',
        }}
      >
        <Grid item flexGrow={3}>
          <OverviewPanel
            totalRegions={filterState.regions.length}
            totalStores={filterState.stores.length}
            countAlarmsActive={totalAlarms.length ?? 0}
            isLoading={isLoading}
            merchandisers={filterState.merchandisers}
            isLoadingAlarmsActive={isLoadingIncidents || isFetchingIncidents}
            sx={{ boxShadow: 0 }}
          />
        </Grid>
        <Grid item flexGrow={2} display={'flex'}>
          <Box
            display={'flex'}
            flexDirection={media ? 'column' : 'row'}
            flexGrow={1}
            justifyContent={largerScreens ? 'center' : 'flex-start'}
          >
            <AppearanceSelect
              sx={{ padding: '0 1rem' }}
              gridDisplay={gridDisplay}
              onChangeAppearance={(appearance) => setGridDisplay(appearance)}
            />
            <Box
              display={'flex'}
              flexDirection="column"
              justifyContent={'start'}
              padding={'0.8rem 1rem 1rem 1rem'}
            >
              <Typography
                color="common.black"
                fontSize={'1rem'}
                lineHeight="2rem"
                paddingLeft={'0.5rem'}
              >
                {polyglot.t('reports.reports')}
              </Typography>
              <StyledActionButton
                onClick={() => {
                  navigate('reports');
                }}
                sx={{ marginTop: '0.6rem' }}
              >
                <ReportIcon color={theme.palette.primary.main} />
              </StyledActionButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box p={'0 1rem 1rem 1rem'}>
        <Grid item xs={12} sm={12}>
          {filterState.regions.length > 0
            ? filterState.regions.map((region: Group) => (
                <Accordion
                  key={region.id}
                  sx={{
                    background: 'transparent',
                    border: 'none',
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    padding: '0',
                  }}
                  defaultExpanded={true}
                >
                  <AccordionSummaryLight
                    id="regions-title"
                    aria-controls="accordion-regions-title"
                    expandIcon={
                      <IconButton>
                        <ArrowDownIcon
                          color={theme.palette.common.black}
                          height={'0.8rem'}
                        />
                      </IconButton>
                    }
                    sx={{
                      [`&.${accordionSummaryClasses.root}`]: {
                        padding: '0',
                        justifyContent: 'flex-start',
                      },
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      display={'flex'}
                      alignItems={'center'}
                      gap="1rem"
                      padding={'0 1rem'}
                    >
                      <PinIcon
                        fontSize="1.5rem"
                        width={'1.5rem'}
                        color={theme.palette.common.black}
                      />
                      <Typography color="common.black" fontWeight={'600'}>
                        {region.name}
                      </Typography>
                    </Grid>
                  </AccordionSummaryLight>
                  <RegionDetail
                    region={region}
                    merchandisers={filterState.merchandisers}
                    stores={filterState.stores}
                    alarms={alarmsByRegion.get(region.id) ?? []}
                    gridDisplay={gridDisplay}
                  />
                </Accordion>
              ))
            : null}
        </Grid>
      </Box>
    </>
  );
};

export default RegionalManagerDashboard;
