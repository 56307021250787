import { ComponentType } from 'react';
import { DashboardLayout } from '../../../customized/fresh-watch/components/Dashboard/DashboardLayout';
import { ToggleComponent } from '../../../featureToggle';
import GroupManagerLayout from './GroupManagerLayout';

export const GroupManagerLayoutItem = ToggleComponent(
  GroupManagerLayout as ComponentType<any>,
  {
    styledComponentsVariants: {
      'fresh-watch': DashboardLayout as ComponentType<any>,
    },
  }
);
